import React, { Component } from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

class ModalBT extends Component{

 
    render(){
        return(
            <div>
                <Modal id="ModalBT" isOpen={this.props.modal} toggle={this.props.switchModal} size="xl" >
                    <ModalHeader toggle={this.props.switchModal} style={{color:'#D5060F'}}><b>{this.props.titulo}</b></ModalHeader>
                    <ModalBody className='movilscroll'>
                        <p>{this.props.terminos}</p>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default ModalBT