import React, { Component } from 'react';
import './desktop.css';
import { Card,Button} from 'reactstrap';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


class Secc_3 extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            sw_auto:true,
            detalle:false,
            btntexto:'',
            swArrows:true,
            codigo:'',
            url_click:''
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 3,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    stopAuto=()=>{
        this.setState({sw_auto:false});
    }

    resumeAuto=()=>{
        if(this.state.detalle ===false){
            this.setState({sw_auto:true});
        }
    }

    showDetalle=(cual, objeto)=>{
        if(cual==='1'){
            this.setState({detimagen: 'half'+objeto.nombre + objeto.extension});
            this.setState({codigo: objeto.codigo});
            this.setState({url_click: objeto.url_click});
            this.setState({btntexto: objeto.texto_boton});
        }else{
            this.setState({detimagen: 'half'+objeto.nombre2 + objeto.extension});
            this.setState({codigo: objeto.codigo2});
            this.setState({url_click: objeto.url_click2});
            this.setState({btntexto: objeto.texto_boton});
        }
        this.setState({swArrows:false});
        this.setState({sw_auto:false});
        this.setState({detalle:true});
    }

    cierraDetalle = ()=>{
        this.setState({detalle:false});
        this.setState({swArrows:true});
        this.setState({sw_auto:true});
    }

    componentDidMount(){
        this.getObjetos();
     } 

    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1
            },
            tablet: {
              breakpoint: { max: 1023, min: 464 },
              items: 1,
              slidesToSlide: 1
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };

        return(
            <div>
                <div id="promociones" className="section-3">  
                    <div className="background-rectangle">
                        <div className="backgroun-rectangle-color-rectangle"></div>
                    </div>
                    <div className="font-pop top-msg">
                        <div className="top-msg__container">
                            <h2>
                                ¡Diversión <br/>
                                en serio!
                            </h2>
                            <h3>Promociones</h3>
                        </div>
                    </div>
                    <div style={{ height:'100%', position:'relative', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div className="sec3CardDiv" style={{ marginTop:150,width:'80%'}}>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={4000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                containerClass="carousel-with-custom-dots"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={this.state.swArrows}
                                deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                    <Card onMouseOver={this.stopAuto} onMouseLeave={this.resumeAuto} key={index} style={{ backgroundColor:'transparent',margin:0, border:'none'}}>
                                        {this.state.detalle===false?
                                            <div>
                                                <img onClick={this.showDetalle.bind(this,'1',objetos)} className="sec3CardImg" src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>              
                                            </div>:
                                            <div style={{ textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                <img onClick={this.cierraDetalle} className="sec3CardImgB" src={this.props.servurlassets+this.state.detimagen} alt={'img'+objetos.index}/>
                                                <Button onClick={this.cierraDetalle} size='sm' outline className="sec3CierraBoton">Cerrar</Button>
                                                <Button className="sec3boton" onClick={this.props.cambiaSeccion.bind(this,this.state.url_click,this.state.codigo,'')} size='sm' outline style={{zIndex:50, bottom:'2%' }}>{this.state.btntexto}</Button>
                                            </div>
                                        } 
                                    </Card>
                                    )):[]
                                }
                            </Carousel>
                        </div>
                    </div>
                    <div className="font-pop terms show-at-xs show-at-sm" onClick={this.props.cambiaSeccion.bind(this,'terminosycondiciones', '')} style={{padding:'0 1.5rem', fontSize:'18px', textAlign:'center', backgroundColor:'white',marginTop:'15px'}}>
                        <h1 style={{cursor:'pointer'}}>Consulta términos y condiciones de cada promoción</h1>
                    </div>
                </div>
            </div>
        )
    }

}
export default Secc_3