import React, { Component } from 'react';
import {Row, Col, Label, Input, Button} from 'reactstrap';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import Swal from 'sweetalert2';
class Secc_Evtos_Soc extends Component{
    constructor() {
        super();

        this.state ={
            swimg:'',
            sucursalesLs:[],
            info:false,
            infodet:false
        };
    }

    envioCorreo =()=>{
        let data = {
            cuerpo:"Nombre: "+document.getElementById("txtNombre").value+" <br/>Teléfono:"+document.getElementById("txtTelefono").value+" <br/>Invitados:"+document.getElementById("txtCantidad").value+" <br/>Email:"+document.getElementById("txtEmail").value,
            email:"aosorio@grupodiniz.com.mx",
            asunto:"- Eventos Sociales",
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(URL_CORESERVICE + '/sendCorreoContacto.php', requestInfo)
        .then(response => response.json())
        .catch(e=>console.log(e)); 
        Swal.fire(
            'Enviado',
            'Se han enviado tus datos',
            'success'
        )
    }

    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
        this.getSucursales();
     } 

    render(){
        return(
            <div>
                <div  className="movilevtosemp" style={{backgroundImage:"url('"+this.props.servurlassets+"secc_fiestas_back.png')"}}>  
                    <div className="movilroyalevtosemp" >
                        <div>
                            <Row>
                                <Col sm={6}>
                                    <div style={{position:'absolute'}} >
                                        <div>
                                            <h2 className="movilfont-pop" style={{color:'white', fontSize:70, textAlign:'left'}}>
                                                Eventos <br/>sociales
                                            </h2>
                                        </div>
                                        <br/>
                                        <div>
                                            <h2  style={{color:'white', fontSize:18, textAlign:'left'}}>
                                            El lugar mas divertido para reunirte con amigos,
                                            <br/>
                                            celebrar ascensos laborales o graduaciones.

                                            </h2>
                                            <br/>
                                            <h4 style={{color:'white',fontSize:"20px"}}>
                                                Da click aquí: 
                                                <a href='https://wa.me/+525559173835?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales'
                                        className="a-link" style={{ display: 'contents' }}>
                                        <img src={URL_ASSETS+'/imagenes-r/wacon.png'} alt="Alternate Text" style={{ height: '20px' }} /></a>
                                    <br />
                                                <p>
                                                    eventosempresariales@recorcholis.com.mx
                                                </p>
                                            </h4>
                                        </div>
                                        <br/>
                                        <Row style={{color:'white'}}>
                                            <Col sm={4} xs={4}>
                                                <Label>Nombre completo</Label>
                                            </Col>
                                            <Col sm={6} xs={6}>
                                                <Input type="text" id="txtNombre" />
                                                
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={4} xs={4}>
                                                <Label>Teléfono</Label>
                                            </Col>
                                            <Col sm={6} xs={6}>
                                                <Input type="text" id="txtTelefono" />
                                            </Col>
                                        </Row><br/>
                                        <Row style={{color:'white'}}>
                                            <Col sm={4} xs={4}>
                                                <Label>Correo electrónico</Label>
                                            </Col>
                                            <Col sm={6} xs={6}>
                                                <Input type="text" id="txtEmail" />
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={4} xs={4}>
                                                <Label>Cantidad de Invitados</Label>
                                            </Col>
                                            <Col sm={6} xs={6}>
                                                <Input type="Number" id="txtCantidad" />
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={6} xs={10}>
                                                <Label>Sucursal de preferencia</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={6} xs={6}>
                                                <Input  type="select" id='sucursalSelect' >
                                                <option key={0}>
                                                    Seleccione una Sucursal
                                                </option>
                                                {this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(   
                                                    <option key={index} value={sucursal.id} 
                                                        disabled={sucursal.sucursal===''?true:false}
                                                        style={{color:"black",
                                                        fontWeight:sucursal.sucursal===''?"bold":''}}>
                                                        {sucursal.estado}
                                                        {sucursal.sucursal}
                                                    </option>
                                                )):[]}    
                                                </Input>
                                            </Col>
                                            <Col sm={3}>
                                                <br/><Button onClick={this.envioCorreo.bind()} class="btn-recorcholis">  Enviar  </Button>
                                            </Col>
                                        </Row>
                                        <br/>
                                        <img style={{zIndex:18, height:320, bottom:0, left:'45%'}} className="moviltatisevtosemp" src={this.props.servurlassets+"secc_evtos_soc_img2.png"} alt="alternate text"/> 
                                    </div>
                                </Col>
                                
                            </Row>
                        </div>
                    </div>   
                </div>   
            </div>
        )
    }
}
export default Secc_Evtos_Soc