import React, { Component } from 'react';
import './desktop.css';
import { Row, Col, Label, Input ,Button} from 'reactstrap';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import Swal from 'sweetalert2';

class Secc_Evtos_Bol extends Component {
    constructor() {
        super();

        this.state = {
            swimg: '',
            info: false,
            infodet: false,
            sucursalesLs:[]
        };
    }

    enviar = () => {
        let data = {
            cuerpo: "Nombre: " + document.getElementById("txtNombre").value + " <br/>Teléfono:" + document.getElementById("txtTelefono").value + " <br/>Invitados:" + document.getElementById("txtCantidad").value + " <br/>Email:" + document.getElementById("txtEmail").value,
            email: "aosorio@grupodiniz.com.mx",
            asunto: "- Eventos Boliche",
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase+'/sendCorreoContacto.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                Swal.fire(
                    'Enviado',
                    'Se han enviado tus datos',
                    'success'
                )
            })
            .catch(e => console.log(e));
            Swal.fire(
                'Enviado',
                'Se han enviado tus datos',
                'success'
            )
    }

    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
       this.getSucursales();
    } 

    render() {
        return (
            <div>
                <div className="evtosbol" style={{ backgroundImage: "url('" + this.props.servurlassets + "secc_fiestas_back.png')" }}>
                    <div className="ebol_royal" >


                        <div style={{ width: '100%' }}>
                            <Row>
                                <Col sm={6}>
                                    <div style={{ margin: 0, position: 'absolute', top: '7%' }} >
                                        <div className="ebol_title">
                                            <h1 className="font-pop" style={{ color: 'white', fontSize: 50, textAlign: 'left', paddingLeft: '30px' }}>
                                                Torneo de <br />boliche
                                            </h1>
                                        </div>
                                        <div className="ebol_subtitle">
                                            <h2 style={{ color: 'white', fontSize: 16, textAlign: 'left', paddingLeft: '30px' }}>
                                                Con amigos,con familia, con tu equipo de trabajo...
                                                <br />
                                                ¡O con quién quieras! Arma un torneo de boliche a lo grande.

                                            </h2>
                                            <br />
                                            <h5 style={{ color: 'white', paddingLeft: '30px' }}>
                                                Da click aquí:
                                                <a href='https://wa.me/+525559173835?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales'
                                        className="a-link" style={{ display: 'contents' }}>
                                        <img src={URL_ASSETS+'/imagenes-r/wacon.png'} alt="Alternate Text" style={{ height: '20px' }} /></a>
                                    <br />
                                                eventosempresariales@recorcholis.com.mx

                                            </h5>

                                        </div>
                                        <br />
                                        <Row style={{ color: 'white', paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Label>Nombre completo</Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Label>Teléfono</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Input bsSize="sm" type="text" id="txtNombre" />
                                            </Col>
                                            <Col sm={6}>
                                                <Input bsSize="sm" type="text" id="txtTelefono" />
                                            </Col>
                                        </Row>

                                        <Row style={{ color: 'white', paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Label>Correo electrónico</Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Label>Cantidad de Invitados</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Input bsSize="sm" type="text" id="txtEmail" />
                                            </Col>
                                            <Col sm={6}>
                                                <Input bsSize="sm" type="Number" id="txtCantidad" />
                                            </Col>
                                        </Row>
                                        <Row style={{ color: 'white', paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Label>Sucursal de preferencia</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{ color: 'white', paddingLeft: '30px' }}>
                                            <Col sm={6}>
                                                <Input  type="select" id='sucursalSelect' >
                                                    <option key={0}>
                                                        Seleccione una Sucursal
                                                    </option>
                                                    {this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(   
                                                        <option key={index} value={sucursal.id} 
                                                            disabled={sucursal.sucursal===''?true:false}
                                                            style={{color:"black",
                                                            fontWeight:sucursal.sucursal===''?"bold":''}}>
                                                            {sucursal.estado}
                                                            {sucursal.sucursal}
                                                        </option>
                                                    )):[]}    
                                                </Input>
                                            </Col>
                                            <Col sm={2}>
                                            </Col>
                                            <Col sm={3}>
                                                <Button className="ee_myButton" onClick={this.enviar.bind()}>Enviar</Button>
                                            </Col>
                                        </Row>
                                        <br />
                                    </div>
                                </Col>
                                <Col sm={6}>


                                    <img style={{ zIndex: 20, }} className="ebol_rimg" src={this.props.servurlassets + "secc_evtos_bol_img1.png"} alt="alternate text" />

                                    <img style={{ zIndex: 20 }} className="ebol_tatis" src={this.props.servurlassets + "secc_evtos_bol_img2.png"} alt="alternate text" />
                                </Col>
                            </Row>

                        </div>
                        {/*  <div style={{  margin:0, position:'absolute',  top:'12%'}}>
                        <a href="#" className="myButton">Reserva aquí</a>
                        </div> */}



                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>
            </div >
        )
    }

}
export default Secc_Evtos_Bol