import React, { Component } from 'react';
import {Button, Modal} from 'reactstrap';

class ModalPromo extends Component{   
     render(){
        return(
            <div>{this.props.datos[0]?    
            <Modal id="Modal"  style={{textAlign:"center", paddingTop:"25%",paddingLeft:"5%",width:"90%"}} className="movilfont-pop moviltop-msg small" isOpen={this.props.modal} toggle={this.props.switchModal} size="lg"  >
                <img style={{height:"auto",width:"100%"}} src={this.props.servurlassets+'movil'+this.props.datos.nombre+this.props.datos.extension} alt="Promociones"/>
                <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                    <div style={{paddingBottom:"20%",textAlign:"center"}}>
                        <Button style={{backgroundColor:" #d5060f",borderRadius:"8px",fontFamily:"Poppins"}} onClick={this.props.cursecc.bind(this,this.props.datos.url_click,this.props.datos)} size='sm'>{this.props.datos.texto_boton}</Button>
                    </div>
                </div>
            </Modal>    
            :''}
            </div>
        );
    }    
}

export default ModalPromo