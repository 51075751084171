import React, { Component } from 'react';
import Swal from 'sweetalert2';
import md5 from 'md5';

class Secc_nueva_contra extends Component{
    
    enviarContra=()=>{
        if(document.getElementById("contra").value===document.getElementById("newContra").value){
            let data ={
                token : document.getElementById("token").value,
                contra : md5(document.getElementById("contra").value)
            }
            const requestInfo={
                method:'POST',
                body:JSON.stringify(data),
                header: new Headers({
                    'Content-Type': 'application/json',
                    'Accept' : 'application/json'
                })
            };
            fetch(this.props.servurlbase+'recuperarContra.php', requestInfo)
            .then(response => response.json())
            .then(response => {
                if(response===0){
                    Swal.fire('Error','Token Invalido','warning');
                }else{
                    Swal.fire('Contraseña','Asignada correctamente','success');
                }
            })
            .catch(e=>console.log(e)); 
        }else{
            Swal.fire('Error','Las contraseñas no coinciden','warning');
        }
        
    }

    render(){
        return(
            <div  className={"movilsection-1can"}>  
              <div class="container text-center color_bckg" style={{  height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                <div class="row">
                    <div className="col-12 _pading">
                    <h1 className="login__title hide-at-xs">Recuperar contraseña</h1>
                             <label className="control-label">Token *</label>
                             <input type="text" id='token' className="form-control" />

                             <label className="control-label">Nueva Contraseña *</label>
                             <input type="text" id='contra' className="form-control" />

                             <label className="control-label">Confirma Contraseña *</label>
                             <input type="text" id='newContra' className="form-control" />
                             <a className="ee_myButton" onClick={this.enviarContra.bind()}>Guarda Contraseña</a>
                             <a className='click_aqui' onClick={this.props.cambiaSeccion.bind(this, 'iniciar_club')} href='#'><p>Regresar</p></a>
                    </div>
                </div>
              </div>
            </div>
        )
    }


}
export default Secc_nueva_contra