import React, { Component } from 'react';
import {Col, Card, Row} from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Secc_1 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 8,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 
     
     
    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
       

        return(
            <div>
                <div className="moviltwo-tone-background" >
                    <div className="moviltone-1" />
                    <div className="moviltone-2" />
                </div>
                <Row style={{paddingTop:'10%',paddingBottom:'10%' }}>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <Carousel
                            swipeable={false}
                            draggable={false}
                            responsive={responsive}
                            showDots={false}
                            ssr={true} // means to render carousel on server-side.
                            infinite={true}
                            autoPlay={this.props.deviceType !== "mobile"? true : false}
                            autoPlaySpeed={4000}
                            keyBoardControl={true}
                            customTransition="all 1s linear"
                            transitionDuration={500}
                            renderDotsOutside={false}
                            arrows={this.props.display==="none"?true:false}
                            deviceType={this.props.deviceType}
                            >
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                <Card key={index} style={{backgroundColor:'transparent',margin:0, border:'none'}} >
                                    {<div >
                                        <img width='100%' src={this.props.servurlassets+objetos.nombre+objetos.extension}  onClick={objetos.url_click === null || objetos.url_click === ''? this.props.cursecc.bind(this,"main"):this.props.cursecc.bind(this,objetos.url_click)} alt={'img'+objetos.index}/>
                                    </div>} 
                                </Card>
                            )):[]}
                        </Carousel>
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </div>
        )
    }

}
export default Secc_1