import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col, Button} from 'reactstrap';


class Secc_Atrac_Pista extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="movilatractionsK">  
                   <div className="movilroyalg">
                          <Row style={{paddingTop:"5%", width:'90%'}}>
                              <Col sm={4} >
                                  <div style={{textAlign:'justify'}}>
                                    <div className="moviltitle">
                                        <h1>Pista de hielo</h1>
                                    </div>
                                    <div className="movilsubtitlesa">
                                        <h2>
                                            La atracción
                                            más cool de
                                            la Ciudad de México
                                        </h2>
                                        <br/>
                                        <h2>
                                            Encuéntralas en Parque Vía Vallejo, CDMX y Mundo E, Edomex.
                                        </h2>
                                    </div>
                                    <div className="moviltextsa">
                                        <p className="movilhide-at-sm hide-at-xs">
                                            Aquí te quedarás frío de la emoción, ven y patina con tu familia 
                                            y amigos en nuestras pistas de hielo;
                                            tendrás un momento lleno de tanta diversión que quitarte los 
                                            patines será lo último que querrás hacer.
                                            <br/><br/>
                                            Para ti que siempre has soñado con aprender patinaje artístico 
                                            Tenemos clases con los mejores profesores. 
                                            Consulta los detalles aquí
                                        </p>
                                        
                                       
                                    </div>
                                    </div>
                                    <br/>
                                    <Button onClick={this.props.cambiaSeccion.bind(this,"clasesPH","","")}  class="btn-recorcholis">Clases de patinaje aquí</Button>
                              </Col>
                              <Col sm={8} style={{textAlign:'center',paddingTop:"10%"}}>
                                    <div style={{position:'relative'}}>
                                        <YouTube videoId="iAezXwkYp88" opts={opts} onReady={this._onReady} />  
                                    </div> 
                                </Col>
                          </Row>
                            
                            
                
                      
                    </div>
                    
                   
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Pista