import React, { Component } from 'react';
import Swal from 'sweetalert2';

class Pasarela_Kueski extends Component {
    getPayURL=()=>{
        let data = {
            id: this.props.id,
            producto: this.props.producto,
            monto: this.props.precio,
            carrito: this.props.carrito,
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'getPayUrlKueski.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if(response.id_pago===''){
                Swal.fire(
                    'Error',
                    'Tu compra no puede ser procesada porque el monto es menor al aceptado por Kueski',
                    'warning'
                )
            }else{
                window.open(response.urlPay,"_self");
            }
            
        }).catch(err => {
            console.error(err)
        });
    }

    componentDidMount() {
        this.getPayURL();
    }

    render() {
        return (
            <div>
              
            </div>
        );
    }
}

export default Pasarela_Kueski