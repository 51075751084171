import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ModalComunidad from './modalComunidad';
import { Row,Col} from 'reactstrap';


class Secc_comunidad extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            modpro:false,
            sw_auto:true,
            textbold:'',
            codigo:'',
            valor:0,
            proimagen:'',
           protipo:''
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 12
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
                if(this.props.codigo!==''){
                    for(let i=0;i<objetosLs.length;i++){
                        if(objetosLs[i].codigo===this.props.codigo){
                            this.showModal();
                            break;
                        }
                    }
                }
            }else{
                this.setState({ objetosLs:[] });
            }
        })
        .catch(e=>console.log(e)); 
    }

    showModal=()=>{
        this.setState({modpro:true});
        this.setState({sw_auto:false});
    }

    cierra= ()=>{
        this.setState({modpro:false});
        this.setState({sw_auto:true});
    }
    
    componentDidMount(){
        this.getObjetos();
    } 
      
    render(){
        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div  className="movilcomunidad">  
                    <Row style={{ width:"100%"}}>
                     <Col className="col-12" style={{padding:'5%', width:'100%'}}>
                        <div className='nos_text' >
                          <h6><strong>¡Recórcholis! en la Comunidad y ¡Kataplum! en la Comunidad.</strong></h6>
                          <br/>
                          <p>Este programa fue creado para que mes a mes recibamos a grupos de niños y niñas que se encuentran en situaciones vulnerables 
                             en cada una de nuestras sucursales con la finalidad de que disfruten de nuestras atracciones de forma <strong>GRATUITA</strong>.</p>
                          <p>Estamos convencidos de la importancia que tienen las actividades recreativas y de esparcimiento en el sano desarrollo 
                             de las niñas y niños y queremos contribuir a que <strong>TODOS</strong> tengan acceso a él.</p>
                        <h6><strong>¿Cómo lo hacemos?</strong></h6>
                         <p>Colaboramos con diferentes instancias gubernamentales y fundaciones de iniciativa privada que se dedican a la protección de niños y niñas en México.
                            Además de aliados clave, hemos logrado llegar a escuelas rurales, casas hogar y comunidades para las que verdaderamente sería muy difícil visitarnos por sus propios medios.
                            Si representas una institución, organización o fundación que atiende grupos de niños de entre 3 y 16 años y 
                            quisieras participar en un evento de ¡Recórcholis! en la Comunidad o ¡Kataplum! en la comunidad, llena el formulario de esta sección para que podamos coordinarlo.</p>
                        </div>
                     </Col>
                     <Col className="col-12" style={{padding:'5%', width:'100%'}}>
                            <Carousel
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                renderDotsOutside={false}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">

                                {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(
                                    <Row style={{padding:'5%', width:'100%', textAlign:'center'}}  >
                                      <Col ><img style={{width:'100%'}} stclassName="moviliconosotros" src={this.props.servurlassets+objetos.nombre+objetos.extension} alt="alternate text"/></Col>
                                    </Row>   
                                    )):[]
                                }
                            </Carousel>
                     </Col>
                     <Col className="col-12" style={{ textAlign: "center" }}>
                      <button className='btn-recorcholis' type='button' onClick={this.showModal.bind()}>Registrarse</button>
                     </Col>
                    </Row>
                    
                    {
                        this.state.modpro === true?
                            <ModalComunidad servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierra={this.cierra} nombreimagen={this.state.proimagen}/>
                        :''
                    }
                </div>                
        )
    }

}
export default Secc_comunidad