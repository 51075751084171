import React, { Component } from 'react';
import {Label, Input, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import NumberFormat from 'react-number-format';
import ModalTyC from './modalTyC';
import Swal from 'sweetalert2';
class ModalPaqueteMovil extends Component{
    constructor() {
        super();
        this.state ={
            total :0,
            datosLS:[],
            tipo:'',
            oculta:'none',
            terminos:'',
            prototipo:'',
            descripcion:'',
            precioR:0,
            monto:0,
            modalTyC:false
        }
    }

    add_ = ()=>{
        if(this.state.precioR<=0 || document.getElementById("txtMonto").value <=0){
            this.setSelect();
        }
        let reco='';        
        let montox = this.state.tipo==='nueva' ? Number(this.state.precioR) + Number(document.getElementById("txtMonto").value) : Number(document.getElementById("txtMonto").value);
        
        if(this.state.tipo==='preexistente'){
            reco=document.getElementById("txtRecorcard").value;
            this.consulta_detalle(reco);
            setTimeout(() => {
                if(this.state.response_card === false){
                    return Swal.fire('Error','Tarjeta Invalida','warning');
                }
                else{

                    let data ={
                        token : this.props.token,
                        monto : montox,
                        codigo : this.props.codigo,
                        recorcard : reco,
                        tipo:this.state.tipo,
                        regalo_email:"",
                        regalo_name:""
                    }
                    this.add_recarga(data);
                }
            }, 1500);            
        }
        else{

            let data ={
                token : this.props.token,
                monto : montox,
                codigo : this.props.codigo,
                recorcard : reco,
                tipo:this.state.tipo,
                regalo_email:"",
                regalo_name:""
            }
            this.add_recarga(data);

        }
   
    }

    enviarCompra = () => {
        if(this.props.protipo ==='promocional'){
            if(this.props.codigoPromocional === document.getElementById("codigoPromocional").value){
                this.add_();
            }else{
                Swal.fire(
                    'Error',
                    'Codigo incorrecto',
                    'warning'
                )
            }
        }else{this.add_();}
        
    }

    add_recarga = (data) => {
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'addRecarga.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            if(respuesta !==null){
                this.props.getCarrito(this.props.token);
                Swal.fire('Artículo agregado a carrito','','success') 
            }else{
                Swal.fire('Error','Upss, algo ocurrió, intente mas tarde','warning')
            } 
        })
        .catch(e=>console.log(e)); 
    }

    consulta_detalle = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    } 

    mostrarTyC = ()=>{
        this.setState({modalTyC:true});
    }

    cierraTyC = () =>{
        this.setState({modalTyC:false});
    }

    getTerminos = ()=>{
        let data ={
            codigo : this.props.codigo,
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getTYC.php', requestInfo)
        .then(response => response.json())
        .then(datosLS => {
            if(datosLS !==null){
                this.setState({datosLS});
                this.setState({monto:datosLS[0].valor});
                this.setState({descripcion:datosLS[0].textbold});
                this.setState({prototipo:datosLS[0].protipo});
                this.setState({terminos:datosLS[0].tyc});
            }else{
                alert('Upss, algo ocurrió, intente mas tarde');
            } 
        })
        .catch(e=>console.log(e)); 
    }

    handleChange = e => {
        const {value } = e.target;
        this.setState({tipo:value});
    }
 
    setSelect = () =>{
        if(this.state.prototipo === 'seleccionable' ){
            var montSelect=document.getElementById("selectMonto").value;
            document.getElementById("txtMonto").value=montSelect;
        }
    }

    costoTarjetas = () =>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getCostoTarjeta.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ precioR:objetosLs[0].costo });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
        var x=window.location + "";
        var ruta = x.split('/');
        if(ruta.length>5){ this.registrarMedio(ruta[5],ruta[4],this.props.token)}
        this.getTerminos();
        this.costoTarjetas();
        if(this.props.titulo==="Tarjeta de regalo"){
            this.setState({oculta:"block"})
            this.setState({tipo:"nueva"})
        }else if(this.props.titulo==="Paquete"){
            this.props.getSesionCtato(this.props.token);
        }
    }

    registrarMedio = (medio,combo,token) => {
        let data ={
            medio,
            combo,
            token
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'guardarMedio.php', requestInfo)
    }

    

    render(){
        // Agregar el código de Google Tag Manager aquí, antes del return
        return(
            <div>
                <Modal id="ModalPaquete" isOpen={true} toggle={this.props.cierraPaquete} size="md" >
                    <ModalHeader toggle={this.props.cierraPaquete} style={{color:'#'+this.props.color, backgroundColor:'#F7DC6F'}}><b>{this.props.titulo}</b></ModalHeader>
                    <ModalBody style={{backgroundColor:'#'+this.props.backColor, color:'white'}}>
                        <div style={{margin:0, position:'relative', display:'flex', left:'25%', top:'5%'}} >
                            <div  >
                                <img className="movilpaqcarimg" src={this.props.servurlassets+this.props.nombreimagen} alt="alternate text"/>
                            </div>
                        </div><br/>
                        <div>
                            <div style={{margin:0, position:'relative', left:'3%',right:'5%', top:'25%',textAlign:"justify"}}>
                                <Label style={{fontSize:15, fontWeight:'bold'}}>
                                    <p style={{display:this.state.prototipo==="recargas"?"block":"none"}}>Deseas que el dinero <br/> y bonus sea cargado en:</p>
                                    <p style={{display:this.state.prototipo==="recargas"?"none":"block"}}>Venta de Cupon Electronico</p>
                                    <p>{this.state.descripcion}</p>
                                    
                                </Label>
                            </div>
                            <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'3%', top:'30%',display:this.state.prototipo==="recargas"?"block":"none" }} >
                                <Label style={{fontSize:15}}>
                                    <Input style={{fontSize:15}} onChange={this.handleChange} type="radio" name="radio1" value="preexistente" /> En tarjeta preexistente
                                </Label>
                                <br/>
                                <Label style={{fontSize:15}}>
                                    <Input style={{fontSize:15}} onChange={this.handleChange} type="radio" name="radio1" value="nueva" /> En tarjeta nueva + (${this.state.precioR})
                                </Label>
                                <br/>
                                <Label style={{fontSize:15}} >
                                    <Input style={{fontSize:15}} onChange={this.handleChange} type="radio"  defaultChecked={true} name="radio1" value="cupon" /> En cupón digital
                                </Label>
                            </FormGroup>
                            <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'8%', top:'30%',display:this.state.prototipo==="seleccionable"?"block":"none"}} >
                                <Row style={{paddingLeft:"0%"}} >
                                    <Input onChange={this.setSelect.bind()} id="selectMonto" type='select' style={{fontSize:12, width:"50%"}}>
                                        <option value="200">$200</option><option value="250">$250</option><option value="300">$300</option>
                                        <option value="350">$350</option><option value="400">$400</option><option value="450">$450</option>
                                        <option value="500">$500</option><option value="550">$550</option><option value="600">$600</option>
                                        <option value="650">$650</option><option value="700">$700</option><option value="750">$750</option>
                                        <option value="800">$800</option><option value="850">$850</option><option value="900">$900</option>
                                        <option value="950">$950</option><option value="1000">$1000</option>
                                    </Input>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{margin:0, position:'relative',  width:"80%", textAlign:'left',  left:'8%', top:'30%',display:this.state.prototipo==="promocional"?"block":"none"}} >
                                <Row style={{paddingLeft:"0%"}} >
                                    <Input id="codigoPromocional" placeholder='Código Promocional'/>
                                </Row>
                            </FormGroup>
                            <div style={{margin:0, position:'relative', display:this.state.tipo==='preexistente'?'block':"none"}} >
                                <Label style={{fontSize:15, fontWeight:'bold'}}>Tarjeta Recorcard (10 dígitos)</Label>
                                {this.props.titulo==="Paquete"?
                                    this.props.cards.length>0?
                                    <div style={{margin:0, position:'relative', display:'flex', marginLeft:'10%', top:'45%'}} >
                                        <Input style={{width:280}} id="txtRecorcard" type="select"> 
                                            { this.props.cards.map((card,index) => (
                                                <option key={index} value={card.NUMBER}>{card.NUMBER}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    :<NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:15, width:"80%"}} />
                                    :<NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:15, width:"80%"}} />
                                }
                            </div>
                            <br/>
                            <h3 style={{display:this.state.prototipo==="seleccionable"?"none":"block"}}>Precio: ${this.state.monto}</h3>
                                <Input id="txtCodigo" value={this.props.codigo} style={{display:"none"}}/>
                                <Input id="txtMonto" value={this.state.monto} style={{display:"none"}}/>
                            <div style={{display:this.state.oculta}}>
                                <label>Nombre</label> <Input id="txtName" />
                                <label>Email Regalo</label> <Input id="txtEmail" />
                                <br/>
                            </div>
                            <div style={{paddingLeft:"30%", display:'flex'}} >
                                <Button className="movilbtn-recorcholis" onClick={this.enviarCompra.bind(this)} >Agrega a carrito</Button>
                            </div>
                        </div>
                        <br/><br/>
                    </ModalBody>
                    <ModalFooter>
                        <b onClick={this.mostrarTyC.bind()}>
                            Ver Terminos y Condiciones
                        </b>
                    </ModalFooter>
                </Modal>
                {this.state.modalTyC === true?
                    <ModalTyC modalTyC={this.state.modalTyC} cierraTyC={this.cierraTyC} terminos={this.state.terminos}/>:""
                }
                
            </div>
        );
    }    
}

export default ModalPaqueteMovil