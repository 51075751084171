import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import { Card} from 'reactstrap';
import YouTube from 'react-youtube';

class Secc_bolera extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 9,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
    } 
     
     
    render(){
        const opts = {
            height: '100%',
            width: 'auto',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
            },
        };

        return(
            <div style={{backgroundColor: '#D60812'}}>
                <div> 
                    <div className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#FFFFFF"}}>
                            <h2>
                                La bolera más grande de México.<br/><br/>
                            </h2>
                            <p style={{fontSize:"12px"}}>Esta área cuenta con líneas de boliche profesional donde podrán divertirse personas de todas las edades. 
                                En esta área se pueden realizar cumpleaños, fiestas con diferentes conceptos, torneos y eventos empresariales, 
                                así como dinámicas de integración de personal.<br/><br/>
                                La venta de la línea es por persona e incluye bolos, calzado de boliche y 10 tiros. 
                                Si ya cuentas con tu equipo hay lockers disponibles para guardar tus pertenencias.
                            </p>
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'center', alignItems:'center'}}>
                    <div style={{width:'100%'}}><br/><br/>
                        {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                            <Card  key={index} style={{ backgroundColor:'transparent',margin:0, border:'none'}}>
                                <div>
                                    <img height='auto' width='85%' src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>    
                                    <br/><br/>   
                                </div>
                            </Card>
                        )):[]}
                            <Card style={{ backgroundColor:'transparent',margin:0, border:'none'}}>
                                <div >
                                    <YouTube  videoId="rw3syy0g9OE" opts={opts}/>  
                                </div> <br/>
                            </Card>
                    </div>
                </div>
            </div>
        )
    }

}
export default Secc_bolera