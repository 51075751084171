import React, { Component } from 'react';
import {Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import './desktop.css';
import Swal from 'sweetalert2';


class ModalComunidad extends Component{

    constructor() {
        super();
        this.state ={
        sucursaless:[]
     }
    }

    sucursales = ()=>{
        let data ={
            todas:true
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursaless => {
            if(sucursaless !==null){
                this.setState({ sucursaless:sucursaless});
            }
            else{
                console.log("error",sucursaless);
            }
        })

        .catch(e=>console.log(e));
    }

    componentDidMount=()=>{
        this.sucursales();
    }

    enviar = () => {
        let data = {
            cuerpo: "Asunto:<strong>Comunidad ¡Recorcholis! y ¡Kataplum!</strong><br/><br/>Nombre: " + document.getElementById("nombre").value + " <br/>Organización: " + document.getElementById("organizacion").value + " <br/>Teléfono: " + document.getElementById("tel").value + " <br/>Correo: " + document.getElementById("correo").value + " <br/>Sucursal deseada: " + document.getElementById("sucursal").value,
            email: "wmarshall@grupodiniz.com.mx",
            asunto: "Comunidad ¡Recorcholis! y ¡Kataplum!"
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + '/sendCorreoContacto.php', requestInfo)
        .then((response) => {
            if(response){
                Swal.fire(
                    'Enviado',
                    'Se han enviado tus datos',
                    'success'
                )
                this.props.cierra();
            }
            else{
                Swal.fire("Error", "Error", "warning");
            }
        })
        .catch(e => console.log(e));
    }

    render(){
        
        return(
            <div>
                <Modal isOpen={true} toggle={this.props.cierra}>
                    <ModalHeader toggle={this.props.cierra} style={{color:'#D5060F', backgroundColor:'#F7DC6F'}}><b>¡Recórcholis! y ¡Kataplum! en la Comunidad</b></ModalHeader>
                    <ModalBody style={{backgroundColor:'#FF8B28', color:'white', height:400}}>
                        <Row>
                         <Col sm={12}>
                            <label className="control-label">Nombre completo *</label>
                            <input type="text" id="nombre" className="form-control" style={{textAlign:'center'}}/>
                         </Col>
                         <Col sm={12}>
                            <label className="control-label">Nombre de organización *</label>
                            <input type="text" id="organizacion" className="form-control" style={{textAlign:'center'}}/>
                         </Col>
                         <Col sm={12}>
                            <label className="control-label">Teléfono *</label>
                            <input type="number" id="tel" className="form-control" style={{textAlign:'center'}}/>
                         </Col>
                         <Col sm={12}>
                            <label className="control-label">Correo electrónico *</label>
                            <input type="text" id="correo" className="form-control" style={{textAlign:'center'}}/>
                         </Col>
                         <Col sm={12}>
                            <label className="control-label">Sucursal deseada*</label>
                            <select className='form-select' id='sucursal'>
                                <option value="0" disabled selected>Seleccione Sucursal</option>
                                {this.state.sucursaless?this.state.sucursaless.map((objetos) =>(   
                                    <option value={objetos}>{objetos}</option>
                                )):''
                                }
                            </select>
                         </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{justifyContent:'center', backgroundColor:'#FF8B28', color:'white'}}>
                      <Button className="btn-recorcholis" onClick={this.enviar.bind(this)} >Enviar datos</Button>
                    </ModalFooter>
                </Modal>    
            </div>
        );
    }    
}

export default ModalComunidad