import React, { Component } from 'react';
import { Card, Row,Col, Button} from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Secc_2 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 2,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 

    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div className='movilsection-2' id='recarga'>
                <div className="movilimg-background" >
                    <div className="moviltone-3s2" style={{border:'none'}}>
                        <img  width="100%" height="100%" src={this.props.servurlassets+"secc2_back.png"} alt='val'/>
                    </div>
                </div>
                <div className="movilmsg movilfont-pop" style={{position:'absolute',top:"5%",left:"2%"}} >
                    <p className="moviltitle" style={{fontSize:"25px"}}> Recarga tu Recorcard en línea </p>
                    <p className="moviltext" style={{fontSize:"15px"}}>y llega directo a jugar sin hacer fila <br/> O adquiere fácil y rápido cupones digitales.<br/></p>
                </div>
                <div style={{ paddingTop:"50%", alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <div style={{width:'100%'}} >
                        <Carousel
                            showDots={false}
                            responsive={responsive}
                            infinite={true}
                            autoPlay={this.props.deviceType !== "mobile"? true : false}
                            autoPlaySpeed={8000}
                            keyBoardControl={true}
                            customTransition="all 1s linear"
                            transitionDuration={500}
                            renderDotsOutside={false}
                            arrows={this.props.display==="none"?true:false}
                            deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(
                                <Row key={index}>
                                    <Col xs={1} xl={2} md={2}></Col>
                                    <Col xs={10} xl={8} md={8}>
                                        <Card  key={index}  className="movilfont-pop moviltop-msg" style={{backgroundColor:objetos.backColor,margin:0, border:'none',alignItems:'center',height:"auto" }} >
                                            {<div>
                                                <div className="moviltop-msg__container"  >
                                                    <img style={{width:"100%",height:"auto"}} src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                                </div>
                                                <div style={{paddingBottom:"10%",textAlign:"center"}}>
                                                    <Button onClick={this.props.cursecc.bind(this,objetos.url_click)} className='movilmyButton'  size='sm'>Compra Aquí</Button>
                                                </div>
                                            </div>} 
                                        </Card>
                                    </Col>
                                    <Col xs={1} xl={2} md={2}></Col>
                                </Row>
                            )):[]}
                        </Carousel>
                    </div>
                </div>
            </div>


        )
    }

}
export default Secc_2