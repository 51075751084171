import React, { Component } from 'react';
import './desktop.css';
import {  Row, Col, Label } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import GoogleMapReact from 'google-map-react';

class Secc_5 extends Component{
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        
        this.state ={
            showdetalle:false,
            cursuc:'',
            sucursalesLs:[],
            dropdownOpen: false,
            
            lat:0,
            long:0,
            sucursal:"",
        };
    }

    toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
      }


    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    showDetalle = (suc)=>{
        this.setState({cursuc:suc});
        this.setState({showdetalle:true});
        this.setState({ lat:parseFloat(suc.latitud) });
        this.setState({ long:parseFloat(suc.longitud) });
    }
    
    componentDidMount(){
        this.getSucursales();
     } 

     

    render(){
        const Marker = props => {
            return <div><img alt='ok' style={{height:"30px",width:"18px"}} src='https://ecommercegd.blob.core.windows.net/imagenes-r/marker.png' /></div>
        }
        const defaultProps = {
            center: {
                lat:this.state.lat,
                lng:this.state.long
            },
            zoom: 15
        };
        
        return(
            <div>
                <div id="encuentraSucursal" className="section-5">  
                    <div className="font-pop top-msg">
                        <div className="top-msg__container">
                            <h2 style={{paddingTop:15, paddingBottom:10}}>
                                        Sucursales <br/>
                            </h2>
                        </div>
                    </div>
                    <div className="hide-at-xs hide-at-sm main-container-section5" style={{height:'100%'}}>
                        <div className="img-backgrounds5">
                            <img  src={this.props.servurlassets+"secc5_back.png"} alt="alternate text"/> 
                        </div>
                        <div style={{width:'100%', height:'100%',flex: '1 1 auto',position:'relative'}}>
                            <div style={{display:'flex',height:'90%', marginTop:'70px'}}>
                                <div style={{ paddingLeft:20, width:'100%'}}>
                                    <Row style={{height:'100%', paddingTop:'50px'}}>
                                        <Col sm={6}>
                                            <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', height:'650px'}}>
                                            {
                                                this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(  
                                                    <Label onClick={this.showDetalle.bind(this,sucursal)} key={index} style={{ cursor:sucursal.sucursal===''?'':'pointer',color:sucursal.sucursal===''?'cyan':'white', fontSize:10, width:'180px'}}>{sucursal.sucursal===''?sucursal.estado:sucursal.sucursal}</Label>
                                                )):''
                                            }  
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            {this.state.showdetalle === true?
                                                <div>
                                                    <Row>
                                                        <Col sm={1}></Col>
                                                        <Col sm={11}>
                                                            <Row style={{color:'white',fontSize:'20px', marginTop:'20px', fontWeight:'bold'}}>
                                                                {this.state.cursuc.sucursal}
                                                            </Row>
                                                            <Row style={{color:'white',fontSize:'12px', fontWeight:'normal'}}>
                                                                {this.state.cursuc.direccion}
                                                            </Row>
                                                            <br/>
                                                            <Row style={{color:'white',fontSize:'18px', fontWeight:'bold'}}>
                                                                Horarios
                                                            </Row>
                                                            <Row style={{color:'white',fontSize:'12px', fontWeight:'normal'}}>
                                                                {this.state.cursuc.horario}
                                                            </Row>
                                                            <br/>
                                                            <Row style={{color:'white',fontSize:'18px', fontWeight:'bold'}}>
                                                                Servicios
                                                            </Row>
                                                            <Row style={{color:'white',fontSize:'12px', margin:0,fontWeight:'normal'}}>
                                                                {this.state.cursuc.juegos ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_games.png"} alt="Alternate Text" />:''
                                                                }
                                                                {this.state.cursuc.boliche ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Boliche.png"} alt="Alternate Text" />:''
                                                                }
                                                                {this.state.cursuc.snacks ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Snacks.png"} alt="Alternate Text" />:''
                                                                }
                                                                {this.state.cursuc.fiestas ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_fiestas.png"} alt="Alternate Text" />:''
                                                                }
                                                                {this.state.cursuc.kids ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Kids.png"} alt="Alternate Text" />:''
                                                                }
                                                                {this.state.cursuc.pista_hielo ==='true'?
                                                                <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_PistaHielo.png"} alt="Alternate Text" />:''
                                                                }
                                                            </Row>
                                                            <br/>
                                                            <Row style={{color:'white',fontSize:'12px', fontWeight:'normal'}}>
                                                                {this.state.cursuc.telefono}
                                                            </Row>
                                                            <br/>
                                                            <Row style={{color:'white',fontSize:'12px', fontWeight:'normal'}}>
                                                                {this.state.cursuc.correo}
                                                            </Row>
                                                            <br/>
                                                            <Row style={{zIndex:60,color:'white',fontSize:'12px', fontWeight:'normal'}}>
                                                                <div style={{ height: '200px', width: '370px' }}>
                                                                    <GoogleMapReact
                                                                        bootstrapURLKeys={{ key: "AIzaSyC-6-ekQ4Y7yRLTceu2jNGBAE37g0n-FW0" }}
                                                                        center={defaultProps.center}
                                                                        defaultZoom={defaultProps.zoom}>
                                                                        <Marker lat={this.state.lat} lng={this.state.long}/>
                                                                    </GoogleMapReact>
                                                                </div>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                :
                                                <div style={{ height:'100%',margin:0, justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                    <div className="sec5_tatodiv">
                                                        <img className="sec5_tato" src={this.props.servurlassets+"secc5_img1.png"} alt="Alternate Text" />
                                                    </div>
                                                </div>
                                            }
                                        </Col>
                                    </Row> 
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Secc_5