import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./desktop.css";
import ModalRegalo from './modalRegalo';

class Secc_regalos extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            modreg:false,
            regimagen:'',
            mesessi:''
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 11,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
    } 
     
    showModalRegalo=(objeto)=>{
        this.setState({regimagen: objeto.nombre + objeto.extension});
        this.setState({mesessi: objeto.mesessi});
        this.setState({codigo: objeto.codigo});
        this.setState({valor: objeto.valor});
        this.setState({modreg:true});
    }

    cierraRegalo = () =>{
        this.setState({modreg:false});
    }

    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1023, min: 464 },
              items: 2,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div>
                <div  className="rsection-regalo" >
                    
                    <div className="regalo_title">
                            <h1>Para esa persona especial</h1>
                            <h2>Selecciona la tarjeta de regalo</h2>
                    </div>
                    <div style={{  margin:0, position:'relative', paddingleft:50, marginTop:0}} >
                            <img className="regtatis" src={this.props.servurlassets+"CajaRegalo_R.png"} alt="alternate text"/> 
                        </div>
                        <div style={{ paddingLeft:300,position:'relative',height:'65%', textAlign:'center', alignItems:'flex-start', justifyContent:'center', display:'flex'}}>
                    <div className="regcarddiv" >
                        <Carousel
                            arrows={false}
                            responsive={responsive}
                            infinite={false}
                            autoPlaySpeed={1000}
                            transitionDuration={500}
                            renderDotsOutside={false}>
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                
                                <div className="regcardestilo" onClick={this.showModalRegalo.bind(this,objetos)} key={index} >
                                            <img className="regcarimg" src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                            {
                                                objetos.mesessi==='3'?
                                                <img className='rpaqcarimgmeses' style={{position:"absolute",bottom:"-5%",right:"5%"}} src={this.props.servurlassets+"tres_meses_sin_interes.png"} alt="alternate text"/> 
                                                :
                                                objetos.mesessi==='6'?
                                                <img className='rpaqcarimgmeses' style={{position:"absolute",bottom:"-5%",right:"5%"}} src={this.props.servurlassets+"seis_meses_sin_interes.png"} alt="alternate text"/> 
                                                :''
                                            }
 
                                            </div>
                                    
                            )):[]}    
                        </Carousel>
                    </div>   
                    </div>
                    <p style={{color:"white"}}>Las tarjetas participantes en MSI son: AFIRME, BanBajío, Banco Famsa, Banjercito, BANORTE, Banregio, HSBC, INBURSA, Invex, Liverpool VISA, Banca Mifel, Santander, Scotiabank, Falabella Soriana, Banco Azteca.</p> 
                </div>   
                {
                    this.state.modreg === true?
                        <div>
                            <ModalRegalo activaBoton={this.props.activaBoton} activoBoton={this.props.activoBoton} valor={this.state.valor} token={this.props.token} agregaCarrito={this.props.agregaCarrito} costotarjeta={this.props.costotarjeta} textbold={this.state.textbold} codigo={this.state.codigo} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierraRegalo={this.cierraRegalo} nombreimagen={this.state.regimagen}/>
                        </div>
                    :''
                }
            </div>
            </div>
        )
    }
}
export default Secc_regalos