import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Row,Col} from 'reactstrap';
import ModalPaquete from './modalPaqueteMovil';
import Swal from 'sweetalert2';

class Secc_regalos extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            modpaq:false,
            paqimagen:'',
            mesessi:'',
            monto:'',
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 11,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    addRecarga = ()=>{
        let data ={
            token : this.props.token,
            codigo : '1',
            monto : document.getElementById("txtMonto").value,
            recorcard : document.getElementById("txtRecorcard").value,
            regalo_email : document.getElementById("txtEmail").value,
            regalo_name : document.getElementById("txtName").value
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'addRecarga.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            if(respuesta !==null){
                this.props.getCarrito(this.props.token);
                Swal.fire(
                    'Artículo agregado a carrito',
                    '',
                    'success'
                ) 
            }else{
                Swal.fire(
                    'Error',
                    'Upss, algo ocurrió, intente mas tarde',
                    'warning'
                )
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 
     
    showModalPaquete=(objeto)=>{
        this.setState({codigo: objeto.codigo});
        this.setState({paqimagen: objeto.nombre + objeto.extension});
        this.setState({mesessi: objeto.mesessi});
        this.setState({modpaq:true});
    }

    cierraPaquete = () =>{
        this.setState({modpaq:false});
    }

    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 2,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div>
                <div  className="movilsection-regalo" >
                    <div className="moviltwo-tone-backgroundregalo" >
                        <div className="moviltone-regalo" />
                    </div>
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Tarjeta de Regalo</h2>
                        </div>
                    </div>
                    <div style={{paddingTop:"20%",textAlign:"left",paddingLeft:"5%"}}>
                        <Row>
                            <Col xs={7} sm={8} md={8} className="movilfont-pop" style={{color:"#FFF"}}><h2>Selecciona Tarjeta de Regalo</h2></Col>
                        </Row>
                    </div>
                    <div style={{paddingTop:"20%"}}>
                        <Carousel
                            arrows={this.props.display==="none"?true:false}
                            responsive={responsive}
                            infinite={true}
                            autoPlaySpeed={1000}
                            transitionDuration={500}
                            renderDotsOutside={false}>
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                <Row key={index}  >
                                    <Col xs={1} xl={2} md={2}></Col>
                                    <Col xs={10} xl={8} md={8}>
                                        <Card key={index} style={{ backgroundColor:'transparent',marginLeft:"12%", border:'none'}} className="movilzoom">
                                            <div onClick={this.showModalPaquete.bind(this,objetos)}>
                                                <img src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                            </div>
                                            {
                                                objetos.mesessi==='3'?
                                                <img style={{position:"absolute",bottom:"-5%",right:"5%"}} src={this.props.servurlassets+"tres_meses_sin_interes.png"} alt="alternate text"/> 
                                                :
                                                objetos.mesessi==='6'?
                                                <img style={{position:"absolute",bottom:"-5%",right:"5%"}} src={this.props.servurlassets+"seis_meses_sin_interes.png"} alt="alternate text"/> 
                                                :''
                                            }
                                        </Card>
                                    </Col>
                                    <Col xs={1} xl={2} md={2}></Col>
                                </Row>
                            )):[]}    
                        </Carousel>
                    </div>   
                    <p style={{color:"white",paddingLeft:"5%"}}>Las tarjetas participantes en MSI son: AFIRME, BanBajío, Banco Famsa, Banjercito, BANORTE, Banregio, HSBC, INBURSA, Invex, Liverpool VISA, Banca Mifel, Santander, Scotiabank, Falabella Soriana, Banco Azteca.</p>
                    <p style={{color:"white",paddingLeft:"5%"}}>La diferencia entre carga y lo que recibes, es en bonus. Bonus = dinero electrónico/ 1 bonus = $1.00 m.n.</p>
                </div>   
                {
                    this.state.modpaq === true?
                        <ModalPaquete codigo={this.state.codigo} monto={this.state.monto} titulo="Tarjeta de regalo" getCarrito={this.props.getCarrito}token = {this.props.token} color={"2872FF"} backColor={"2872FF"} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierraPaquete={this.cierraPaquete} mesessi={this.state.mesessi} nombreimagen={this.state.paqimagen}/>
                    :''
                }
            </div>
            </div>
        )
    }
}
export default Secc_regalos