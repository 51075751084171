import React, { Component } from 'react';
import { Card} from 'reactstrap';
import ModalPromo from './modalPromo';

class Secc_3 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            datos:[],
            modal:false
        };
    }

    terminos = (datos)=>{
        this.setState({datos});
        this.switchModal();
    }
    
    switchModal = () =>{
        this.setState({modal:!this.state.modal});
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 7,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 

    render(){
        return(
            <div style={{backgroundColor: '#FF8B28'}} id='promociones' >
                <ModalPromo servurlassets={this.props.servurlassets} datos={this.state.datos} cursecc={this.props.cursecc} switchModal={this.switchModal} modal={this.state.modal} />
                <div> 
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2>
                                ¡Diversión <br/>
                                en serio!
                            </h2>
                            <h3>Promociones</h3>
                        </div>
                    </div>
                </div>
                <div style={{textAlign:'center', alignItems:'center'}}>
                    <div style={{width:'100%'}}><br/><br/><br/><br/><br/>
                        {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                            <Card  key={index} style={{ backgroundColor:'transparent',margin:0, border:'none'}}>
                                <div>
                                    <img onClick={this.terminos.bind(this,objetos)} height='auto' width='85%' src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>    
                                    <br/><br/>   
                                </div>
                            </Card>
                        )):[]}
                    </div>
                </div>
            </div>
                
        )
    }

}
export default Secc_3