import React, { Component } from 'react';

class Secc_clases_PH extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }

    render(){
        return(
            <div style={{backgroundColor: 'white',paddingTop: '8%',paddingBottom:''}} >
                <img width={'100%'} src={this.props.servurlassets+'PH_Clases_R.jpg'} />
            </div>
        )
    }

}
export default Secc_clases_PH