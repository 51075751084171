import React, { Component } from 'react';
import {Label, Row, Col, Input,  Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import './desktop.css';
import ModalTYC from './modalTYC';

class ModalRegalo extends Component{

    constructor() {
        super();

        this.state ={
        total :0,
        tipo:'',
        swTYC:false
        }
    }

    addRecarga = ()=>{
        let reco='';
        let montox = Number(this.props.valor) + Number(this.props.costotarjeta)
        let data ={
            token : this.props.token,
            codigo : this.props.codigo,
            monto : montox,
            recorcard : reco,
            tipo:this.state.tipo,
            regalo_name:document.getElementById("txtRegaloName").value,
            regalo_email: document.getElementById("txtRegaloEmail").value
        }
       this.props.agregaCarrito(data);
       this.props.cierraRegalo();
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({tipo:value});
    }

    abreTYC=()=>{
        this.setState({swTYC:true});
    }

    cierraTYC=()=>{
        this.setState({swTYC:false});
    }
 
    render(){
        
        return(
            <div >
                <Modal id="ModalRegalo" isOpen={true} toggle={this.props.cierraRegalo} size="lg" >
                    <ModalHeader toggle={this.props.cierraRegalo} style={{color:'#D5060F', backgroundColor:'#F7DC6F'}}><b>Tarjeta de regalo</b></ModalHeader>
                    <ModalBody style={{backgroundColor:'#2872FF', color:'white', height:400}}>
                        <div style={{margin:0, position:'absolute', top:'5%', width:'100%'}} >
                            <Row>
                                <Col sm={4}>
                                <div  style={{paddingLeft:40, paddingTop:20}}>
                                    <img className="regcarimg_modal" src={this.props.servurlassets+this.props.nombreimagen} alt="alternate text"/>
                                </div>
                                </Col>
                                <Col sm={2}></Col>
                                <Col sm={6} >
                                <br/>
                                    <Row>
                                        <Label style={{fontSize:20, fontWeight:'bold'}}>¿Quién recibirá la tarjeta ?</Label>
                                    </Row>
                                    <br/>
                                    <Row>
                                    <Label style={{fontSize:20,}}>Nombre</Label>
                                    </Row>
                                    <Row>
                                    <Input bsSize='sm' style={{width:250}} type="text"  id="txtRegaloName" />
                                    </Row>
                                    <br/>
                                    <Row>
                                        <Label style={{fontSize:20}}>Correo electrónico</Label>
                                    </Row>
                                    <Row>
                                        <Input bsSize='sm' style={{width:250}} type="text" id="txtRegaloEmail" />
                                    </Row>
                                    <br/><br/>
                                    <Row>
                                    <Button style={{width:200}} size='sm' className="reg_myButton" onClick={this.addRecarga.bind(this)} >Agrega a carrito</Button>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    <br/><br/>
                    </ModalBody>
                    <ModalFooter onClick={this.abreTYC} style={{justifyContent:'center', backgroundColor:'#2872FF', color:'white'}}>
                        <h4  className="proTYC"> Ver terminos y condiciones</h4>
                    </ModalFooter>
                </Modal>    
                {
                    this.state.swTYC===true?
                    <ModalTYC servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} codigo={this.props.codigo} cierraTYC={this.cierraTYC}/>:''
                }
            </div>
        );
    }    
}

export default ModalRegalo