import React, { Component } from 'react';
import './desktop.css';
import { Card, Button, Row, Col} from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";


class Secc_2 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            cursize:''
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 2,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    getDimensions = () =>{
       if( this.props.pvDim> 1439){
            this.setState({cursize:'desktopG'});
       }else{
        if( this.props.pvDim> 1023){
            this.setState({cursize:'desktopP'});
        }else{
            if( this.props.pvDim> 768){
                this.setState({cursize:'tablet'});
            }
        }
       }
    }
    
    componentDidMount(){
        this.getObjetos();
        this.getDimensions();
     } 

    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 769 },
              items: 3,
              slidesToSlide: 4 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 768, min: 464 },
              items: 3,
              slidesToSlide: 4 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div >
                
                    <div id="recarga" className="section-2" >  
                        <div className="two-tone-backgrounds2" >
                            <div className="tone-3s2" style={{zIndex:-10}}>
                                <img className="img-backgrounds2 img" src={this.props.servurlassets+"secc2_back.png"} alt='val'/>
                            </div>
                        </div>
                        <div className="msg font-pop" style={{zIndex:-5,position:'absolute',top:'5%',left:'5%'}}>
                            <h1 className="title"> Recarga tu Recorcard en línea </h1>
                            <h1 className="text">y llega directo a jugar sin hacer fila <br/> O adquiere fácil y rápido cupones digitales.</h1>
                        </div>
                        
                        <div style={{ paddingTop:'30%',position:'relative',height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <div style={{width:'90%'}} >
                        <Carousel
                            showDots={false}
                            responsive={responsive}
                            infinite={true}
                            autoPlay={ false}
                            autoPlaySpeed={2000}
                            keyBoardControl={true}
                            customTransition="all 1s linear"
                            transitionDuration={500}
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            renderDotsOutside={false}
                            arrows={false}
                            deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                            >
                                
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                <Row key={index} style={{paddingBottom:10}}>
                                  
                                    <Col xs={1} xl={1} md={2}></Col>
                                    <Col xs={10} xl={10} md={8}>
                                        <Card  key={index} className="cardEstilo" style={{backgroundColor:objetos.backColor,margin:0,alignItems:'center' }} >
                                            {<div>
                                                <div style={{ marginTop:20, marginLeft:0, marginBottom:0, marginRight:2}} className="top-msg__container"  >
                                                    <img className="cardEstiloImg"  src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                                </div>
                                                
                                                    <Button onClick={this.props.cambiaSeccion.bind(this,objetos.url_click,'','')} className="btn-recorcholis" style={{width:140,height:35,paddingTop:5, paddingLeft:15 ,textAlign:'center'}}>Compra Aquí</Button>
                                                
                                            </div>} 
                                        </Card>
                                    </Col>
                                    <Col xs={1} xl={1} md={2}></Col>
                                </Row>
                            )):[]}
                        </Carousel>
                    </div>
                </div>
                        {/* Fin del contenido de la seccion*/}
                </div>
            </div>
        )
    }

}
export default Secc_2