import React, { Component } from 'react';
import { Label, Row, Col, Button, Modal, ModalHeader, ModalBody, Input, Alert } from 'reactstrap';
import NumberFormat from 'react-number-format';
import ProductDisplay from '../components/pasarela/pasarela';
import ProductDisplayMP from '../components/pasarela/pasarela_mp';
import ProductDisplayAplazo from '../components/pasarela/pasarela_aplazo';
import ProductDisplayKueski from '../components/pasarela/pasarela_Kueski';
import Swal from 'sweetalert2';


class ModalCarrito extends Component {
    constructor() {
        super();
        this.state = {
            swpagar: false,
            aceptoTC: true,
            metodoPago: true,
            ocultaPas: false,
            cuponesLS:[]
        }
    }

    validarTC=()=>{
        if(!this.state.ocultaPas){
            if(document.getElementById("aceptoTC").checked && this.props.carritoLs.length > 0 && this.props.total > 0 
            && document.getElementById("Kueski").checked||
            document.getElementById("aceptoTC").checked && this.props.carritoLs.length > 0 && this.props.total > 0 
            && document.getElementById("Santander").checked ||
            document.getElementById("aceptoTC").checked && this.props.carritoLs.length > 0 && this.props.total > 0 
            && document.getElementById("Aplazo").checked||
            document.getElementById("aceptoTC").checked && this.props.carritoLs.length > 0 && this.props.total > 0 
            && document.getElementById("MercadoPago").checked ){
                if(document.getElementById("Aplazo").checked){
                    this.setState({metodoPago:'1'});
                }else if(document.getElementById("MercadoPago").checked){
                    this.setState({metodoPago:'2'});
                }else if(document.getElementById("Santander").checked){
                    this.setState({metodoPago:'3'});
                }else{
                    this.setState({metodoPago:'4'});
                }
                this.setState({aceptoTC:false})
            }else{
                this.setState({aceptoTC:true})
            }
        }else{
            if(document.getElementById("aceptoTC").checked && this.props.carritoLs.length > 0 && this.props.total > 0 
            && document.getElementById("Kueski").checked){
                this.setState({metodoPago:'4'});
                this.setState({aceptoTC:false})
            }else{
                this.setState({aceptoTC:true})
            }
        }
        
    }

    consultaCupones=(orden)=>{
        let data = {
            orden: orden
        }

        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'consultaCuponesR.php', requestInfo)
        .then(response => response.json())
        .then(cuponesLS => {
            if (cuponesLS !== null) {
                this.setState({ cuponesLS });
            } else {
                this.setState({ cuponesLS: [] });
            }
        }).catch(e => console.log(e));
    }

    respuestaProducto = (data) => {
        if(data.status==="approved"){
            this.props.remplazarToken();
            this.consultaCupones(data.idOrden);
        }
    }

    validaCompra = () => {
        
    }
    
    showCliente = () => {
        var nombre=document.getElementById('txtNombre').value;
        var email=document.getElementById('txtEmail').value;
        var cemail=document.getElementById('txtCEmail').value;
        var celular=document.getElementById('txtCelular').value;

        nombre  = nombre.trim();
        email   = email.trim();
        celular = celular.trim();//LIMPIA ESPACIOS BASURA

        if (nombre !== '' && email !== '' && celular !== '' && email === cemail) {
            this.setState({ swpagar: true });
            let data = {
                token: this.props.token,
                nombre,
                email,
                celular
            }
            const requestInfo = {
                method: 'POST',
                body: JSON.stringify(data),
                header: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                })
            };
            fetch(this.props.servurlbase + 'guardarCliente.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {

            })
            .catch(e => console.log(e));
        }else{
            Swal.fire(
                'Error',
                'No puede haber campos vacíos y el correo electrónico debe coincidir',
                'warning'
            )
        }
    }

    componentDidMount(){
        this.validaCompra();
    } 

    render() {
        const space20 = { margin: 20 };
        const space8 = { margin: 8 };

        return (
            <div>
                <Modal id="ModalCarrito" isOpen={true} toggle={this.props.cierraCarrito} size="lg" >
                    <ModalHeader toggle={this.props.cierraCarrito} style={{ color: '#D5060F', backgroundColor: '#F7DC6F' }}><b>Mi carrito</b></ModalHeader>
                    <ModalBody>
                        {this.state.swpagar === false ?
                            <>
                            <div key={0} style={{ fontWeight: 'bold' }}>
                                <Row>
                                    <Col sm={2}> </Col>
                                    <Col sm={2}>
                                    </Col>
                                    <Col sm={5}>
                                        <Label>Producto</Label>
                                    </Col>
                                    <Col sm={2} style={{ textAlign: 'right' }}>
                                        <Label>Monto</Label>
                                    </Col>
                                </Row>
                            </div>
                            {this.props.carritoLs.length > 0 ? this.props.carritoLs.map((producto, index) => (
                                <div key={index}>
                                    <Row>
                                        <Col sm={1}>
                                            <Button onClick={this.props.eliminaItem.bind(this, producto.id)} size='sm' style={{ textAlign: 'left' }} outline color='danger'><i className="fa fas fa-trash" style={{ fontSize: '16px' }}></i></Button>
                                        </Col>
                                        <Col sm={8}>
                                            <Label>{producto.tipo === 'nueva' || producto.protipo === 'regalo'? producto.descripcion + ' (+ Recorcard)'
                                                : producto.tipo === 'preexistente' ? <> {producto.descripcion} <span style={space8} /> <Label style={{ color: '#d5060f'}}> {producto.recorcard} </Label></> : producto.descripcion} </Label>
                                            {
                                                producto.protipo==='regalo'?
                                                <>
                                                    <br/>
                                                    <Label style={{color:'#d5060f'}}>{producto.regalo_name} ({producto.regalo_email})</Label>
                                                </>:''
                                            }
                                        </Col>
                                        <Col sm={2} style={{ textAlign: 'right' }}>
                                            <Label><NumberFormat value={producto.monto} fixedDecimalScale={true} decimalScale={2} displayType={'text'} thousandSeparator={true} /></Label>
                                        </Col>
                                    </Row>
                                </div>                                
                            )) : []
                            }
                            {this.props.carritoLs.length > 0 ? 
                                <div>
                                    <div style={{ textAlign: 'right' }}>
                                        <Label>__________________________</Label>
                                    </div>
                                    <div style={{ textAlign: 'right' }}>
                                        <Row>
                                            <Col sm={8}>
                                            </Col>
                                            <Col sm={3}>
                                                <Label style={{ fontWeight: 'bold' }}>Total : <span style={space20} /><NumberFormat style={{ fontWeight: 'bold' }} value={this.props.total} fixedDecimalScale={true} decimalScale={2} prefix={'$'} displayType={'text'} thousandSeparator={true} /> </Label>
                                            </Col>
                                        </Row>
                                    </div>
                                        <br /><br />
                                    <div>
                                        <div style={{ textAlign: 'center' }}>
                                            <Label style={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}>Registra tus datos para el envío de la confirmación <br/>de compra y entrega de tus cupones digitales</Label>
                                        </div>
                                        <Row>
                                            <Col sm={3}>
                                                <Label><b>Nombre</b></Label>
                                            </Col>
                                            <Col sm={3}>
                                                <Label><b>Email</b></Label>
                                            </Col>
                                            <Col sm={3}>
                                                <Label><b>Confirma Email</b></Label>
                                            </Col>
                                            <Col sm={3}>
                                                <Label><b>Celular (Whatsapp)</b></Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={3}>
                                                <Input id="txtNombre" type="text" />
                                            </Col>
                                            <Col sm={3}>
                                                <Input id="txtEmail" type="text" />
                                            </Col>
                                            <Col sm={3}>
                                                <Input id="txtCEmail" type="text" />
                                            </Col>
                                            <Col sm={3}>
                                                <Input id="txtCelular" type="number" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <br/>
                                    {this.state.ocultaPas===false?
                                    <div className='d-flex justify-content-center' >
                                        <div className='d-flex r align-items-center' > 
                                            <Input id="Santander"type='radio' defaultChecked={true} name='pago' style={{padding:10 , marginRight: 10}}/> 
                                            <label for="Santander">
                                                <img  style={{width: '80%'}} src={this.props.servurlassets + 'mitec.png'} />
                                            </label>
                                        </div>
                                        <div className='d-flex r align-items-center' > 
                                            <Input id="MercadoPago"type='radio' name='pago'  style={{padding:10 , marginRight: 10,display:'block'}}/> 
                                            <label for="MercadoPago" style={{display:'block'}}>
                                                <img  style={{width: '80%'}} src={this.props.servurlassets + 'mercadoPago.png'}/>
                                            </label>
                                        </div>
                                        <div className='d-flex r align-items-center' > 
                                            <Input id="Aplazo"type='radio' name='pago' style={{padding:10 , marginRight: 10}}/> 
                                            <label for="Aplazo">
                                                <img  style={{width: '80%'}} src={this.props.servurlassets + 'aplazo.png'}/>
                                            </label>
                                        </div>
                                        <div className='d-flex r align-items-center' > 
                                            <Input id="Kueski"type='radio' name='pago' style={{padding:10 , marginRight: 10}} /> 
                                            <label for="Kueski">
                                                <img  style={{width: '80%'}} src={this.props.servurlassets + 'kueski.png'}/>
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    <div className='d-flex justify-content-center'>
                                        <div className='d-flex r align-items-center' > 
                                            <Input id="Kueski"type='radio' name='pago' style={{marginLeft:'15%',padding:10 , marginRight: 10}} /> 
                                            <label for="Kueski">
                                                <img  style={{width: '80%'}} src={this.props.servurlassets + 'kueski.png'}/>
                                            </label>
                                        </div>
                                    </div>}
                                    <div style={{ zIndex:100,textAlign: 'center', width: '100%' }}>
                                        <Input type='checkbox' id='aceptoTC' onClick={this.validarTC.bind()}/>
                                        <div style={{textDecorationLine:'underline',color:'blue', cursor:'pointer'}} onClick={this.props.cambiaSeccion.bind(this,"terminosycondiciones","","")}>Acepto Términos y Condiciones</div>
                                        <br/>
                                        <br/>
                                        <Button onClick={this.showCliente.bind()} size='sm' disabled={this.state.aceptoTC} style={{ width: 100 }} color="success">
                                            Pagar
                                        </Button>
                                    </div>
                                </div>
                            : []
                            }
                            </>:
                                <div style={{ width: '100%' }}>
                                    {this.state.metodoPago==='1'?
                                        <ProductDisplayAplazo id={this.props.token} servurlbase={this.props.servurlbase} carrito={this.props.carritoLs} producto={"Producto RECORCHOLIS"} precio={this.props.total} respuestaProducto={this.respuestaProducto}></ProductDisplayAplazo>
                                    :this.state.metodoPago==='2'?
                                        <ProductDisplayMP id={this.props.token} servurlbase={this.props.servurlbase} carrito={this.props.carritoLs} producto={"Producto RECORCHOLIS"} precio={this.props.total} respuestaProducto={this.respuestaProducto}></ProductDisplayMP>
                                    :this.state.metodoPago==='3'?
                                        <ProductDisplay id={this.props.token} producto={"Producto RECORCHOLIS"} precio={this.props.total} respuestaProducto={this.respuestaProducto}></ProductDisplay>
                                    :
                                        <ProductDisplayKueski id={this.props.token} servurlbase={this.props.servurlbase} carrito={this.props.carritoLs} producto={"Producto RECORCHOLIS"} precio={this.props.total} respuestaProducto={this.respuestaProducto}></ProductDisplayKueski>
                                    }
                                </div>
                        }
                        <div style={{ width: '100%', textAlign:'center' }} >
                            {this.state.cuponesLS.length > 0 ? this.state.cuponesLS.map((cupones, index) => (
                                <div key={index}><br/>
                                    <img width={"80%"} src={"https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl="+cupones.codigoCupon+"&choe=UTF-8'"}/>
                                </div>
                                )) : []
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ModalCarrito