import React, { Component } from 'react';
import { Label, Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';

class ModalAgregarTarjeta extends Component {

    agrega_tarjeta = ()=>{
        var card = document.getElementById("ntarjeta").value;
        var cvv = document.getElementById("cvv").value;
        let data ={
            card,
            MGUID:this.props.MGUID,
            cvv
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase + '/AddCard.php', requestInfo)
        .then(response => response.json())
        .then(response => {  

            if(response.Code == 0){
                this.props.cierraAgregarT();
                Swal.fire('Agregada','Tarjeta agregada correctamente','success');
                this.props.consultar_usuario();
            }
            else{
                Swal.fire('Error',response.Message,'error')
            }
            
        })

        .catch(e=>console.log(e)); 
    }

    render() {

        return (
            <div>
                <Modal id="ModalAgregarTarjeta" isOpen={true}>
                    <ModalHeader toggle={this.props.cierraReserva} style={{ color: 'white', backgroundColor: 'black' }}><b>Agregar nueva tarjeta Recorcard</b></ModalHeader>
                        <ModalBody style={{ backgroundColor: 'white', color: 'black' }}>
                            <div style={{ textAlign:'center' }}>
                                <Row>
                                    <Label>Ingrese número de tarjeta:</Label>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <NumberFormat bsSize='sm' id="ntarjeta" format="###-######-#" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Label>CVV:</Label>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <NumberFormat bsSize='sm' id="cvv" format="####" />
                                    </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                         <Button className="btn btn-primary" size='sm' onClick={this.agrega_tarjeta.bind()} style={{ width: 100 }}>Guardar</Button>            
                         <Button className="btn btn-secondary" size='sm' onClick={this.props.cierraAgregarT.bind()} >Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalAgregarTarjeta