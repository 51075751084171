import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Kids extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="movilatractionsK">  
                    <div style={{justifyContent:"center"}}>
                        <Row style={{paddingLeft:'2%', width:'90%',paddingTop:"5%"}}>
                            <Col sm={4}>
                                <div className="moviltitlesa">
                                    <h1>KIDS</h1>
                                </div>
                                <div className="movilsubtitlesa">
                                    <h2>
                                    El lugar donde pueden jugar, correr, saltar, gritar y su imaginación es el límite.
                                    </h2>
                                </div>
                                <br/>
                                <div className="moviltextsa">
                                    <p>
                                        Área infantil creada para niños pequeños, en la que podrán desenvolverse 
                                        libremente y desarrollar sus habilidades sociales
                                        y psicomotrices mientras se divierten al máximo en un área que cuenta con 
                                        los más altos estándares de seguridad.
                                    <br/><br/>
                                        El acceso a esta área es de tiempo ilimitado. 
                                        Se requiere una estatura mínima de 80 cm y una estatura máxima de 120 cm 
                                        para poder ingresar al área.
                                        juegos por increíbles premios. 
                                    </p>
                                </div>
                                <a onClick={this.props.cursecc.bind(this,"recargas")} className="movilmyButton">Comprar ahora</a>
                            </Col>
                            <Col sm={8} style={{textAlign:'center',paddingTop:"10%"}}>
                            <div style={{position:'relative'}}>
                                <YouTube videoId="SKlyBpwE9-g" opts={opts} onReady={this._onReady} />  
                            </div> 
                            </Col>
                        </Row>
                            
                            
                
                      
                    </div>
                    
                   
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Kids