 import React, { Component } from 'react';
import './desktop.css';
import {  Row, Col,Label, Nav, NavItem,Modal, ModalHeader, ModalBody} from 'reactstrap';



class Secc_terminosycondiciones extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            preguntasLs:[],
            tyc:'',
            cierra: this.toggleModal
        };
    }

    getSecciones = () =>{
        fetch(this.props.servurlbase+'getSeccionesAgrupa.php')
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    buscarTerminos = (seccion) =>{
        let data ={
            seccion
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getTerminosycondiciones.php', requestInfo)
        .then(response => response.json())
        .then(preguntasLs => {
            if(preguntasLs !==null){
                this.setState({ preguntasLs });
                console.log(preguntasLs);
            }else{
                this.setState({ preguntasLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    toggleModal = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };
    
    showModal=(valor)=>{
        this.setState({modal:true});
        this.setState({sw_auto:false});
        this.setState({tyc:valor});
    }
    
    componentDidMount(){
        this.getSecciones();
    }

    render(){
        const space6={margin:6};
        return(
            <div>
                <div className="rtyc">  
                    <div className="rtyc_title" style={{display:'flex', alignContent:'center',alignItems:'baseline'}} > 
                        <h1 >Términos y condiciones </h1><span style={space6}/>
                    </div>
                    <div className="rtyc_royal" >
                        <Row className="rtycrow" >
                            <Col sm={11} >
                                <div style={{paddingLeft:20, display:'contents', width:'100%'}}>
                                    <Nav tabs style={{display:'flex'}}>
                                        {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos) =>(
                                            <NavItem  className="rtycNavLink" onClick={this.buscarTerminos.bind(this,objetos.seccion)}>
                                                <Label >
                                                    {objetos.seccionV}
                                                </Label>
                                            </NavItem>
                                            )):[]
                                        }
                                    </Nav>
                                </div>
                            </Col>
                            <Col sm={11} >
                            <div style={{ overflowY:'scroll', maxHeight:'45%', minHeight:'40%',backgroundColor:'#ff8b28'}}>
                                <ul>
                                    {this.state.preguntasLs.length>0? this.state.preguntasLs.map((pregunta) =>(    
                                        <div>
                                            <li className="list-group-item" onClick={this.showModal.bind(this,pregunta.tyc)}>
                                                <h5 className='fw-bold' style={{cursor:'pointer'}} >
                                                    {pregunta.promocion}
                                                </h5>
                                            </li>
                                        </div>
                                    )):[]}
                                </ul>
                            </div>
                            </Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
                <Modal className='modal-lg' isOpen={this.state.modal} toggle={this.state.cierra} >
                    <ModalHeader  className='text-white' style={{ background:'#D5060F' }} toggle={this.state.cierra}>
                        Términos y condiciones
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                         <Col className="col-sm-12">
                           <div style={{textAlign:'justify'}} >
                                <p className='fw-bold'>{this.state.tyc}</p>
                           </div>
                         </Col>
                        </Row>
                    </ModalBody>
                </Modal>    
            </div>
        )
    }

}
export default Secc_terminosycondiciones