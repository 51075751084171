import React, { Component } from 'react';
import { Row,Col,Table} from 'reactstrap';
import ModalAgregarTarjeta from './modalAgregarTarjeta';
import ModalDetalleTarjeta from './modalDetalleTarjeta';
import ModalEditarTarjeta from './modalEditarTarjeta';
import ModalRecargaTarjeta from './modalRecargaTarjeta';
import Swal from 'sweetalert2';

class Secc_iniciar_club extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            cardsLs:[],
            detalles:[],
            todo:[],
            modalAgregar:false,
            modalEditarT:false,
            number_tarjeta:""
     };
    }

    showDetailT = ()=>{
     this.setState({modalDetailT:true});
    }

    cierraDetailT = ()=>{
      this.setState({modalDetailT:false});
    }

    showRecargaT = (number_tarjeta)=>{
        this.setState({number_tarjeta});
        this.setState({modalRecargaT:true});
    }

    cierraRecargaT = ()=>{
        this.setState({modalRecargaT:false});
    }

    showAgregarT = ()=>{
      this.setState({modalAgregar:true});
    }

    cierraAgregarT = ()=>{
      this.setState({modalAgregar:false});
    }

    showEditarT = ()=>{
      this.setState({modalEditarT:true});
    }
  
    cierraEditarT = ()=>{
      this.setState({modalEditarT:false});
    }

    consulta_usuario = ()=>{
        let data ={
            correo:this.props.cliente
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'GetMember.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            this.setState({ objetosLs:objetosLs["Data"] });
            this.setState({ cardsLs:objetosLs["Data"]["cards"] });
            this.consulta_todo()
        })

        .catch(e=>console.log(e));
    }

    consulta_todo = () =>{
        let data ={
            correo:this.props.cliente
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'GetALLCard.php', requestInfo)
        .then(response => response.json())
        .then(todo => {
            this.setState({ todo:todo});
        })

        .catch(e=>console.log(e));

    }

    consulta_detalle = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {

            if(detalles.Code == 1){
                this.cierraDetailT()
                Swal.fire('Error',detalles.Message,'warning')
            }
            else{
                this.showDetailT()
                this.setState({ detalles:detalles["Data"] });
            }

        })

        .catch(e=>console.log(e));
    }

    bloquear_tarjeta = (CGUID)=>{

        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        Swal.fire({
            title: '¿Bloquear tarjeta?',
            showCancelButton: true,
            confirmButtonText: 'Bloquear',
            cancelButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
                fetch(this.props.servurlbase+'DenounceCard.php', requestInfo)
                .then(response => response.json())
                .then(response => {
                    if(response){
                        Swal.fire('Bloqueada!', '', 'success')
                        this.consulta_usuario();
                    }
                })
                .catch(e=>console.log(e));             
            }
          })
    }

    elimina_tarjeta = (card)=>{
        let data ={
            card
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

            Swal.fire({
                title: '¿Desasociar tarjeta de ésta cuenta?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Desasociar',
              }).then((result) => {
                if (result.isConfirmed) {

                    fetch(this.props.servurlbase+'RemoveCard.php', requestInfo)
                    .then(response => response.json())
                    .then(response => {

                        if(response)
                        Swal.fire('Hecho','Tarjeta desasociada correctamente','success')
                        this.consulta_usuario();

                    })

                    .catch(e=>console.log(e)); 

                } 
              })        
    }

    break_sesion = ()=>{
        let data ={
            token: this.props.token
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'breakSesion.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if(response){
                this.props.cambiaSeccion('iniciar_club')
            }
        })

        .catch(e=>console.log(e)); 
    }

    componentDidMount=()=>{
        this.consulta_usuario();
    }
          
    render(){
        return(
            <div className="container-fluid color_bckg">  
            {this.state.objetosLs?
            <>
                <Row style={{ width:"100%", padding: "10%"}}>
                    <Col sm={1}>
                    </Col>
                    <Col sm={10} style={{textAlign:'center'}}>
                        <Row>
                            <h1 className='main__msg'>¡Hola, Bienvenid@! {this.state.objetosLs.FIRSTNAME + " " + this.state.objetosLs.LASTNAME}</h1>
                            <div className="olvi_cont">
                                <h2>{this.state.objetosLs.EMAIL}</h2>
                            </div>
                            <Col sm={12}>
                                {/*<a className="click_aqui_small" onClick={this.showEditarT.bind()} style={{cursor:'pointer'}}>Editar</a>*/}
                            </Col>
                        <Table>
                                <tr>
                                    <th className='bg-warning'><h4 className='main_msg_table'>E - money</h4></th>
                                    <th className='bg-info'><h4 className='main_msg_table'>Bonus</h4></th>
                                    <th className='bg-success'><h4 className='main_msg_table'>E - tickets</h4></th>
                                </tr>
                            <tbody>
                                <tr>
                                    <td className='bg-warning'><h3 className='main_msg_table'>{this.state.todo.emoney}</h3></td>
                                    <td className='bg-info'><h3 className='main_msg_table'>{this.state.todo.bonus}</h3></td>
                                    <td className='bg-success'><h3 className='main_msg_table'>{this.state.todo.etickets}</h3></td>
                                </tr>
                            </tbody>
                        </Table>
                        </Row>
                        <Row style={{textAlign:'center'}}>
                            <Col sm={6}>
                            <a className="click_aqui_small" onClick={this.showAgregarT.bind()} style={{cursor:'pointer'}}>Asociar tarjeta <i className="fa fas fa-plus"></i></a>
                            </Col>
                            <Col sm={6}>
                            <a className="click_aqui_small" onClick={this.break_sesion.bind()} style={{cursor:'pointer'}}>Cerrar sesión <i className="fa fas fa-power-off"></i></a>
                            </Col>
                        </Row>
                        <Row style={{textAlign:'center'}}>
                            <Table className='table'>
                                <thead>
                                    <tr>
                                        <th className="table-light"><h1 className='msg_table'>Tarjeta asociada</h1></th>
                                        <th className="table-light"><h1 className='msg_table'>Desasociar tarjeta</h1></th>
                                        <th className="table-light"><h1 className='msg_table'>Bloquear tarjeta</h1></th>
                                        <th className="table-light"><h1 className='msg_table'>Recagar saldo</h1></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cardsLs?this.state.cardsLs.map((objetos) =>(   
                                        <tr>
                                            <td className="table-light" style={{cursor:'pointer'}} onClick={this.consulta_detalle.bind(this,objetos.CGUID)}><h4 className="text-primary">{objetos.NUMBER}</h4></td>
                                            <td className="table-light">
                                                <button className="btn btn-secondary rounded-3" onClick={this.elimina_tarjeta.bind(this,objetos.NUMBER)}><i className="fa fas fa-trash"></i></button>
                                            </td>
                                            <td className="table-light">
                                            {objetos.STATUS == 0?<button className="btn btn-secondary rounded-3" onClick={this.bloquear_tarjeta.bind(this,objetos.CGUID)}><i className="fa fas fa-lock"></i></button>:<p className="fw-light">No disponible.</p>}
                                            </td>
                                            <td className="table-light">
                                            {objetos.STATUS == 0?<button className="btn btn-secondary rounded-3" onClick={this.showRecargaT.bind(this,objetos.NUMBER)}><i className="fa fas fa-money-bill"></i></button>:<p className="fw-light">No disponible.</p>}  
                                            </td>
                                        </tr>
                                    )):
                                    <tr>
                                        <td className="table-light" colSpan={4}><p className="fw-light">Sin tarjetas asociadas.</p></td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </Row>
                    </Col>
                    <Col sm={1}>
                    </Col>
                </Row>
                {
                  this.state.modalAgregar === true?
                  <ModalAgregarTarjeta MGUID={this.state.objetosLs.MGUID} cierraAgregarT={this.cierraAgregarT} consultar_usuario={this.consulta_usuario} servurlbase={this.props.servurlbase}/>:''
                }
                {
                  this.state.modalDetailT === true?
                  <ModalDetalleTarjeta cierraDetailT={this.cierraDetailT} detalles_={this.state.detalles} />:''
                }
                {
                  this.state.modalEditarT === true?
                  <ModalEditarTarjeta cierraEditarT={this.cierraEditarT} detalles_={this.state.objetosLs} servurlbase={this.props.servurlbase}/>:''
                }
                {
                  this.state.modalRecargaT === true?
                  <ModalRecargaTarjeta cierraRecargaT={this.cierraRecargaT} servurlassets={this.props.servurlassets} number={this.state.number_tarjeta} token={this.props.token} getCarrito={this.props.getCarrito} agregaCarrito={this.props.agregaCarrito} servurlbase={this.props.servurlbase}/>:''
                }
            </>:<h1 style={{color:'white',textAlign:'center'}}><br/><br/><br/><br/><br/>
                    No se encontró la cuenta solicitada, acude a tu sucursal
                </h1>}
            </div>   
        )
    }
}
export default Secc_iniciar_club