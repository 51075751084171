import React, { Component } from 'react';
import './desktop.css';
import { Button } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";


class Secc_4 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 4,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                    this.setState({ objetosLs });
               // alert('Gracias por enviarnos sus comentarios.');
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 

    render(){
       
        
        return(
            <div >
                
                    <div  className="section-4">  
                        <div style={{height:'100%', position:'relative', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                            <div style={{ zIndex:-5,width:'100%', height:'100%', position:'absolute', top:'15%'}}>
                            <img width='50%' height='90%' src={this.props.servurlassets+"secc4_img1.png"} alt="secc41"/>
                            <img width='50%' height='90%' src={this.props.servurlassets+"secc4_img2.png"} alt="secc42"/>
                               
                            </div>
                            <div style={{zIndex:-2,width:'100%', height:'100%',textAlign:'left', position:'absolute', paddingTop:'5%',top:'10%'}}>
                                <h1 className="sec4festeja" style={{color:'white'}}>
                                    Festeja tu cumpleaños
                                </h1>
                                <div  style={{paddingLeft:'7%', color:'#01FEE4', position:'absolute', bottom:'25%'}}>
                                <h3 className="sec4festeja2" style={{textAlign:'center', fontWeight:600}}>
                                    Fechas disponibles limitadas<br/>
                                    ¡RESERVA YA TU FIESTA DE REGALO!
                                </h3>
                            </div>
                            </div>
                            
                            <div style={{zIndex:-1, textAlign:'left', paddingTop:'8%', paddingLeft:'12.5%', width:'100%', height:'100%', position:'absolute', top:'15%'}}>
                            <img width='30%' height='50%' src={this.props.servurlassets+"secc4_img3.png"} alt="sec43"/>
                            </div>
                            <div style={{margin:0, position:'absolute', display:'flex', left:'20%', bottom:'5%'}} >
                                    <Button onClick={this.props.cambiaSeccion.bind(this,'fiestas','')} className="sec4boton"  >Ver mas</Button>
                                </div>
                        </div>
                            
                        {/* Fin del contenido de la seccion*/}
                </div>
                
            </div>
        )
    }

}
export default Secc_4