import React, { Component } from 'react';
import { Modal,ModalHeader,ModalBody} from 'reactstrap';

class ModalTyC extends Component{
    
     render(){
        return(
            <div>
                <Modal id="Modal" style={{textAlign:"center", paddingTop:"25%"}} toggle={this.props.cierraTyC} className="movilfont-pop moviltop-msg small" isOpen={this.props.modalTyC} size="lg"  >
                    <ModalHeader toggle={this.props.cierraTyC} ><b>Terminos y Condiciones</b></ModalHeader>
                    <ModalBody >
                        <div style={{overflow:"scroll" ,height:"400px"}}>
                            <p>{this.props.terminos}</p>
                        </div>
                    </ModalBody>
                </Modal>    
                
            </div>
        );
    }    
}

export default ModalTyC