import React, { Component } from "react";
import md5 from "md5";
import Swal from "sweetalert2";

class Secc_iniciar_club extends Component {
    constructor() {
        super();

        this.state = {
            datosUsuario: [],
            tipo: "",
        };
    }

    loginUser = () => {
        if (document.getElementById("correo").value === "" || document.getElementById("pass").value === "") {
            Swal.fire("Error", "Usuario o Contraseña Incorrecta", "warning");
        } else {
            let data = {
                user: document.getElementById("correo").value,
                pass: md5(document.getElementById("pass").value),
                token: this.props.token,
            };
            const requestInfo = {
                method: "POST",
                body: JSON.stringify(data),
                header: new Headers({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }),
            };
            fetch(this.props.servurlbase + "getUser.php", requestInfo)
                .then((response) => response.json())
                .then((response) => {
                    if (response === 0) {
                        Swal.fire("Error", "Usuario o Contraseña Incorrecta", "warning");
                    } else {
                        this.setState({ datosUsuario: response });
                        this.props.cambiaSeccion("sesion_club", document.getElementById("correo").value);
                    }
                })
                .catch((e) => console.log(e));
        }
    };

    componentDidMount = () => {
        this.props.getSesionCtato(this.props.token);
        if (this.props.sesionCTato !== "") {
            this.props.cambiaSeccion("sesion_club", this.props.sesionCTato);
        }
    };

    render() {
        return (
            <div className="containerr-fluid text-center color_bckg">
                <div className="row">
                    <div className="col-12" style={{ padding: "10%" }}>
                        <div className="main_title">
                            <h1>Iniciar sesión</h1>
                        </div>
                        <br />
                        <h1 className="main__msg">¿No tienes cuenta?</h1>
                        <div style={{ textAlign: "center" }}>
                            <a className="ee_myButton" onClick={this.props.cambiaSeccion.bind(this, "registrar_club", "", "")}>
                                Regístrate
                            </a>
                        </div>
                        <br />
                        <div className="control-label">
                            <label>Correo electrónico:</label>
                            <input type="text" id="correo" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="control-label">
                            <label>Contraseña:</label>
                            <input type="password" id="pass" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <br />
                        <div style={{ textAlign: "center" }}>
                            <a className="ee_myButton" onClick={this.loginUser.bind()}>
                                Iniciar
                            </a>
                        </div>
                        <div className="olvi_cont">
                            <h2>¿Olvidaste tu contraseña?</h2>
                        </div>
                        <a className="click_aqui" onClick={this.props.cambiaSeccion.bind(this, "recupera_club", "", "")} href="#">
                            <p>Recupérala aquí</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Secc_iniciar_club;
