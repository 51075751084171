import React, { Component } from 'react';
import MainDesktop from './comp_desktop/main';
 import MainMovil from './comp_movil/main'; 

class Main extends Component {
    
    constructor() {
        super();

        this.state ={
            tipo:[]
        };
    }

    componentDidMount() {
        window.innerWidth<1024?
        this.setState({tipo:'movil'})
        : 
        this.setState({tipo:'desktop'})
    }

    render() {

        return (
            <div style={{margin:0}}>
                {
                    this.state.tipo==='movil'? 
                    <MainMovil />
                    :
                    <MainDesktop />
                }
            </div>
        )
    }

}
export default Main