import React, { Component } from 'react';
import './desktop.css';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ModalPaquete from './modalPaquete';
import ModalComparaPaquete from './modalCompara';
import ReactPixel from 'react-facebook-pixel';

class Secc_paquetes extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            modpaq:false,
            modComp:false,
            paqimagen:'',
            codigo:'',
            valor:0,
            mesessi:''
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 5,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    showModalCompara=()=>{
        this.setState({modComp:!this.state.modComp});
    }

    showModalPaquete=(objeto)=>{
        this.setState({paqimagen: objeto.nombre + objeto.extension});
        this.setState({mesessi: objeto.mesessi});
        this.setState({codigo: objeto.codigo});
        this.setState({valor: objeto.valor});
        this.setState({modpaq:true});
    }

    cierraPaquete = ()=>{
        this.setState({modpaq:false});
    }
    
    componentDidMount(){
        this.getObjetos();
        if(this.props.codigo){
            this.showModalPaquete(this.props.codigo[0]);
        }
        
        // ReactPixel.init('132586586571350');
        // ReactPixel.pageView();
     } 
     
     
    render(){
        const responsive = {
            desktopl: {
              breakpoint: { max: 3000, min: 1440 },
              items: 3,
              slidesToSlide: 1 // optional, default to 1.
            },
            desktops: {
                breakpoint: { max: 1439, min: 1024 },
                items: 2,
                slidesToSlide: 1 // optional, default to 1.
              },
            tablet: {
              breakpoint: { max: 768, min: 464 },
              items: 4,
              slidesToSlide: 4 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div  className="rsection-paquetes">  
                    <div className="paqtone-paquetes" />
                        <div style={{  margin:0, position:'relative', left:-100, top:'30%'}} >
                            <img className="paqtatis" src={this.props.servurlassets+"paq_tatis.png"} alt="alternate text"/> 
                        </div>
                        <div style={{ position:'relative',height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div style={{height:350,width:'54%'}} >
                            <Carousel
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                autoPlay={ false}
                                autoPlaySpeed={2000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">

                                {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(
                                    <div className="paqcardestilo" onClick={this.showModalPaquete.bind(this,objetos)} key={index} >
                                        <img className="rpaqcarimg" src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                        {objetos.mesessi==='3'?
                                            <img className="rpaqcarimgmeses" src={this.props.servurlassets+"tres_meses_sin_interes.png"} alt="alternate text"/> 
                                            :
                                        objetos.mesessi==='6'?
                                            <img className="rpaqcarimgmeses" src={this.props.servurlassets+"seis_meses_sin_interes.png"} alt="alternate text"/> 
                                        :''
                                        }
                                    </div>
                                    )):[]
                                }
                            </Carousel>
                            <br/>
                            <p style={{color:"white"}}>La diferencia entre carga y lo que recibes, es en bonus. Bonus = dinero electrónico / * 1 bonus = $1.00 m.n.</p>
                            <p style={{color:"white"}}>Las tarjetas participantes en MSI son: AFIRME, BanBajío, Banco Famsa, Banjercito, BANORTE, Banregio, HSBC, INBURSA, Invex, Liverpool VISA, Banca Mifel, Santander, Scotiabank, Falabella Soriana, Banco Azteca.</p>
                        </div>
                    </div>
                    <div style={{margin:0, position:'absolute', display:'flex', right:'10%', top:'40%', height:'20%',cursor:'pointer'}} >
                        <img onClick={this.showModalCompara.bind()} style={{backgroundColor:'transparent'}} src={this.props.servurlassets+"comparapaq.png"} alt="alternate text"/> 
                    </div>
                </div>   
                {
                    this.state.modpaq === true?
                    <ModalPaquete getSesionCtato={this.props.getSesionCtato} cards={this.props.cards} activaBoton={this.props.activaBoton} activoBoton={this.props.activoBoton} valor={this.state.valor} agregaCarrito={this.props.agregaCarrito} token={this.props.token} costotarjeta={this.props.costotarjeta} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierraPaquete={this.cierraPaquete} mesessi={this.state.mesessi} codigo={this.state.codigo} nombreimagen={this.state.paqimagen}/>
                    :''
                }
                {
                    this.state.modComp === true?
                    <ModalComparaPaquete servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} showModalCompara={this.showModalCompara} modComp={this.state.modComp} />
                    :''
                }
                
            </div>
        )
    }

}
export default Secc_paquetes