import React, { Component } from 'react';
import './desktop.css';
import {  Row, Col} from 'reactstrap';

class Secc_Atrac_Snacks extends Component{

    render(){
        return(
            <div>
                <div  className="atracsnacks">  
                   <div className="as_royalg">
                        <Row style={{ width:'100%'}}>
                            <Col sm={4}>
                                <div className="as_titlesa">
                                    <h1>Snacks</h1>
                                </div>
                                <div className="as_subtitlesa">
                                    <h3>
                                    Lo que se te antoje
                                    </h3>
                                </div>
                                <div className="as_textsa">
                                    <p>
                                        Encuentra el menú en nuestro código QR en la zona de Snacks de nuestras sucursales ¡Recórcholis!
                                        <br/>
                                        O presiona en esta imagen para verlo. 
                                    </p>
                                    <a target={"_blank"} href={"https://recorcholis.com.mx/Home/SnacksMenu/"}><img style={{height:200}} src={this.props.servurlassets+"secc_atracc_img1.png"} alt='val'/></a>
                                    <br/>
                                    <br/>
                                    <p>
                                        Productos sujetos a disponibilidad. Consulta las sucursales con área de snacks, aquí
                                    </p>
                                </div>
                            </Col>
                            <Col sm={8}>
                            <div style={{ paddingTop:10,position:'relative', display:'flex', justifyContent:'center' ,textAlign:'center'}}>
                                <div className="as_carimg">
                                    <h4 className="as_font-main" style={{color:'#D5060F', fontSize:'18px', paddingLeft:'14px', paddingTop:'20px', position:'absolute'}}>
                                    <b>
                                        Tus antojos son
                                        <br/>
                                        órdenes para Tato.
                                    </b>
                                    </h4>
                                    <img  style={{height:500}} src={this.props.servurlassets+"secc_atracc_img2.png"} alt='val'/>
                                    <img  style={{height:500}} src={this.props.servurlassets+"secc_atracc_img3.png"} alt='val'/>
                                    <img  style={{height:500}} src={this.props.servurlassets+"secc_atracc_img4.png"} alt='val'/>
                                    </div>
                            </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Snacks