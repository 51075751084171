import React, { Component } from 'react';
import {  Row, Col} from 'reactstrap';

class Secc_Aviso extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }
     
    render(){
        return(
            <div>
                <div  className="movilaviso">  
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Aviso de Privacidad</h2>
                        </div>
                    </div>
                    <div className="movilroyalg" style={{paddingTop:"25%"}}>
                        <Row>
                            <Col className='movilscroll' sm={12} md={12} xs={12} lg={12}>
                                <div >
                                    <b>AVISO DE PRIVACIDAD INTEGRAL</b>
                                    <p>
                                        Franquicias Recórcholis, S.A. DE C.V., con fundamento en los artículos 8, 15, 16 y 17 de la Ley Federal
                                        de Protección de Datos Personales en Posesión de Particulares, y con domicilio en Boulevard Adolfo
                                        López Mateos número 183-13, Lomas de San Ángel Inn, Código Postal 01790, Ciudad de México,
                                        cuyo portal de internet es www.recorcholis.com.mx, es responsable de recabar sus datos
                                        personales, del uso que se le den a los mismos y de su protección.
                                    </p>
                                    <b>¿Para qué fines utilizaremos sus datos personales?</b>
                                    <p>
                                        Hacemos de su conocimiento que la información personal recabada será utilizada para dar
                                        cumplimiento a las obligaciones que hemos contraído con usted, así como para finalidades de:
                                        publicidad, eventos, promoción e información de productos o servicios, cumplir con los
                                        compromisos de garantía y calidad, procurar la correcta atención de sus solicitudes y propuestas,
                                        dar continuidad a su participación en eventos, promociones, estudios de mercado y, en general,
                                        cualquier actividad compatible a las indicadas, para ofrecerle el mejor servicio personalizado.
                                        Franquicias Recórcholis, S.A. DE C.V., realiza las actividades anteriores, incluso la recopilación y el
                                        uso de datos de ubicación para fines necesarios al cumplimiento de nuestras obligaciones con los
                                        usuarios, conforme a los Términos y Condiciones de uso. Cualquier uso distinto a la información
                                        antes descrita deberá realizarse previo aviso y aceptación del usuario, de igual forma la negativa
                                        para el uso de sus datos personales con finalidades secundarias y/o accesorias no podrá ser un
                                        motivo para que le sean negados los servicios y productos que solicita o contrata con nosotros.
                                    </p>
                                    <b>¿Qué datos personales utilizaremos para estos fines?</b>
                                    <p>
                                        Para llevar a cabo las finalidades descritas en el presente aviso de privacidad solicitaremos los siguientes datos:
                                    </p>
                                    <b>• Nombre completo<br/></b>
                                    <b>• Ocupación <br/></b>
                                    <b>• Edad<br/></b>
                                    <b>• Sexo<br/></b>
                                    <b>• Teléfono fijo y/o celular<br/></b>
                                    <b>• Correo electrónico<br/></b>
                                    <b>• Dirección y/o domicilio<br/><br/></b>
                                    <p>
                                        Asimismo, para el caso de los usuarios que optan por pagar los servicios que le presta Franquicias
                                        Recórcholis, S.A. DE C.V., sus subsidiarias y filiales, a través de cargos recurrentes o pagos
                                        domiciliados, se recopilará la información referente a una cuenta bancaria, tarjeta de crédito o
                                        tarjeta de débito y/o PayPal, que el usuario proporcionará para tal efecto.
                                        Franquicias Recórcholis, S.A. DE C.V., le informa que sus datos personales pueden, en su caso, ser
                                        transferidos, dentro y fuera del país a cualquier empresa filial, controladora o subsidiaria. En virtud
                                        de lo dispuesto por la fracción III del artículo 37 de la Ley Federal de Protección de Datos Personales
                                        en Posesión de los Particulares, dicha transferencia no requerirá de su consentimiento.
                                        En cualquier caso, el destinatario de sus datos personales se encuentra obligado al cumplimiento de
                                        lo convenido en el presente Aviso de Privacidad, limitando su uso a los fines establecidos
                                        anteriormente y de conformidad con la legislación en materia de protección de datos personales.
                                    </p>
                                    <b>¿Qué Tecnologías usamos?</b>
                                    <p>
                                        Con el fin de poder brindar a nuestros usuarios un mejor servicio y experiencia al momento de
                                        navegar en nuestro sitio web, así como recordar preferencias y configuraciones del usuario,
                                        determinar la popularidad del contenido y, en general, comprender los comportamientos e
                                        intereses en línea de las personas que interactúan con nuestros servicios, utilizamos cookies y
                                        tecnologías de identificación en nuestro sitio web, aplicaciones móviles, comunicaciones por correo
                                        electrónico, anuncios y otros servicios en línea.
                                        Usted tiene el derecho a elegir si acepta o no la aplicación de las tecnologías mencionadas. No
                                        obstante, le informamos que conforman una parte importante de cómo funcionan nuestros
                                        servicios, por lo que deberá considerar que, si elige rechazar o eliminar dichas tecnologías, esto
                                        podría afectar la disponibilidad y la funcionalidad de los servicios.
                                    </p>
                                    <b>Opciones y Transparencia</b>
                                    <p>
                                        Derechos (ARCO): es importante informarle que usted tiene derecho a solicitar en cualquier
                                        momento el acceso, rectificación, cancelación u oposición, respecto al tratamiento de sus datos
                                        personales o bien revocar el consentimiento que para dicho fin nos haya otorgado.
                                        Para lo anterior, es necesario enviar la solicitud en términos del artículo 29 de la Ley Federal de
                                        Protección de Datos Personales en Posesión de los Particulares a Franquicias Recórcholis, S.A. DE
                                        C.V., con domicilio en Boulevard Adolfo López Mateos número 183-13, Lomas de San Ángel Inn,
                                        Código Postal 01790, Ciudad de México, al teléfono 80.08.81.88.11, o bien, al correo electrónico
                                        atención.clientes@grupodiniz.com.mx , el cual solicitamos confirme vía telefónica para garantizar
                                        su correcta recepción.
                                        Asimismo, y en caso de que no desee recibir mensajes promocionales de nuestra parte, puede
                                        enviarnos su solicitud por medio de la dirección electrónica antes mencionada.
                                        Debidamente leídos los términos contenidos en el presente Aviso de Privacidad, autoriza el
                                        tratamiento de sus datos personales; reiterando, al momento de hacer uso de este sitio, que está
                                        aceptando los términos y condiciones, así como, la política de privacidad, en caso de no estar de
                                        acuerdo usted puede enviar un correo electrónico a la dirección antes señalada, manifestando su
                                        deseo de no aceptar finalidades y/o la transferencia de sus datos personales.
                                        Es posible que actualicemos este aviso ocasionalmente. Si realizamos cambios significativos, se los
                                        notificaremos a los usuarios a través del correo electrónico registrado al solicitar un servicio, o bien,
                                        recomendamos revisar periódicamente este aviso para obtener información actualizada sobre
                                        nuestras prácticas de privacidad, ya que el uso de nuestros servicios después de una actualización
                                        constituirá el consentimiento del aviso modificado en la medida en que la ley lo permita.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_Aviso