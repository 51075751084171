import React, { Component } from 'react';
import './desktop.css';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import ModalPromocion from './modalPromocion';
import ReactPixel from 'react-facebook-pixel';

class Secc_promociones extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            modpro:false,
            sw_auto:true,
            textbold:'',
            codigo:'',
            valor:0,
            proimagen:'',
            protipo:'',
            codigoPromocional:''
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 10,
            objetofilterid:this.props.objetofilterid
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
                if(this.props.codigo!==''){
                    for(let i=0;i<objetosLs.length;i++){
                        if(objetosLs[i].codigo===this.props.codigo){
                            this.showModalPromocion(objetosLs[i]);
                            break;
                        }
                    }
                }
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    showModalPromocion=(objeto)=>{
        this.setState({proimagen: objeto.nombre + objeto.extension});
        this.setState({textbold:objeto.textbold})
        this.setState({codigo: objeto.codigo});
        this.setState({protipo: objeto.protipo});
        this.setState({valor: objeto.valor});
        this.setState({codigoPromocional: objeto.codigoPromocional});
        this.setState({modpro:true});
        this.setState({sw_auto:false});
    }

    cierraPromocion = ()=>{
        this.setState({modpro:false});
        this.setState({sw_auto:true});
    }
    
    componentDidMount(){
        this.getObjetos();
        
        // ReactPixel.init('1334903514091210');
        // ReactPixel.pageView();
    } 
     
    render(){
        const responsive = {
            desktopl: {
              breakpoint: { max: 3000, min: 1440 },
              items: 4,
              slidesToSlide: 1 // optional, default to 1.
            },
            desktops: {
                breakpoint: { max: 1439, min: 1024 },
                items: 3,
                slidesToSlide: 1 // optional, default to 1.
              },
            tablet: {
              breakpoint: { max: 768, min: 464 },
              items: 4,
              slidesToSlide: 4 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div  className="rsection-promociones">  
                    <div style={{paddingTop:'5%', position:'absolute', width:'100%'}}>
                        <h1 className="secpro" style={{color:'white'}}>
                            Selecciona la promoción
                        </h1>
                    </div>
                    <div style={{ position:'relative',height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div style={{height:370,width:'75%',marginTop:90}} >
                            <Carousel
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                autoPlay={this.state.sw_auto}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                    <div className="procardestilo" onClick={this.showModalPromocion.bind(this,objetos)} key={index} >
                                        <img className="procarimg" src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                        {objetos.mesessi==='3'?
                                            <img className="procarimgmeses" src={this.props.servurlassets+"tres_meses_sin_interes.png"} alt="alternate text"/> 
                                            :
                                        objetos.mesessi==='6'?
                                            <img className="procarimgmeses" src={this.props.servurlassets+"seis_meses_sin_interes.png"} alt="alternate text"/> 
                                        :''
                                        }
                                    </div>)):[]
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>   
                {
                this.state.modpro === true?
                    <ModalPromocion cards={this.props.cards} codigoPromocional={this.state.codigoPromocional} valor={this.state.valor} token={this.props.token} agregaCarrito={this.props.agregaCarrito} costotarjeta={this.props.costotarjeta} protipo={this.state.protipo} textbold={this.state.textbold} codigo={this.state.codigo} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierraPromocion={this.cierraPromocion} nombreimagen={this.state.proimagen}/>
                :''
                }
            </div>
        )
    }

}
export default Secc_promociones