import React, { Component } from 'react';
import './desktop.css';
import {Row, Col} from 'reactstrap';

class Secc_validado extends Component{
     
    constructor(props) {
        super(props);

        this.state ={
            id_valida:props.id_valida
        };
    }

    validado = ()=>{
        let data ={
            id_valida:this.state.id_valida,
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'reg_validado.php', requestInfo)
        .then(response => response.json())
        .then(response => {

            if(response){

            }

        })

        .catch(e=>console.log(e));
    }

    componentDidMount() { 
        this.validado();
    }
     
    render(){
        return(
            <div>
                <div className="sesion_main">
                    <div className="ag_royalg">
                        <Row style={{ width:'100%'}}>
                            <Col sm={3} className='bg-warning'></Col>
                            <Col sm={6} style={{textAlign:'center'}}>
                                <img style={{ height:'250px'}}  src={this.props.servurlassets+"secc5_img1.png"} alt="Alternate Text" />
                                <h1 className='main__msg'>Correo validado con éxito</h1>
                                <div className="escribenos">
                                <h4>¡Muchas gracias por tu preferencia!</h4>
                                </div>
                                <a className='click_aqui' onClick={this.props.cambiaSeccion.bind(this, 'iniciar_club', '', '')} href='#'><p>Iniciar sesión</p></a>
                            </Col>
                            <Col sm={3} className='bg-success'></Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }


}
export default Secc_validado