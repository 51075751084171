import React, { Component } from 'react';
import './desktop.css';
import {  Row, Col,Label, Nav, NavItem,TabContent,TabPane, Collapse} from 'reactstrap';



class Secc_preguntas extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            t1active:true,
            t2active:false,
            t3active:false,
            activepanel:"1",
            collapse11:false,
            collapse12:false,
            collapse13:false,
            collapse14:false,
            collapse15:false,
            collapse16:false,
            collapse17:false,
            collapse18:false,
            collapse19:false,
            collapse110:false,
            collapse111:false,
            collapse112:false,
            collapse113:false,
            collapse114:false,
            collapse115:false,
            collapse21:false,
            collapse22:false,
            collapse23:false,
            collapse24:false,
            collapse25:false,
            collapse26:false,
            collapse27:false,
            collapse28:false,
            collapse29:false,
            collapse210:false,
            collapse211:false,
            collapse212:false,
            collapse213:false,
            collapse214:false,
            collapse215:false,
            collapse31:false,
            collapse32:false,
            collapse33:false,
            collapse34:false,
            collapse35:false,
            collapse36:false,
            collapse37:false,
            collapse38:false,
            collapse39:false,
            collapse310:false,
            collapse311:false,
            collapse312:false,
            collapse313:false,
            collapse314:false,
            collapse315:false,
            preguntas1Ls:[],
            preguntas2Ls:[],
            preguntas3Ls:[],
        };
    }


    changePanel = (pan) =>{
        this.setState({t1active:false});
        this.setState({t2active:false});
        this.setState({t3active:false});
        switch (pan){
            case '1' : this.setState({t1active:true});
                 break;
            case '2' : this.setState({t2active:true});
                break;
            case '3' : this.setState({t3active:true});
                break;
            default :this.setState({t1active:true});
                break;
        }
        this.setState({activepanel:pan});
    }
  
    toggle1 = (index)=>{
        switch (index){
            case 0: this.setState({collapse11:!this.state.collapse11}); break;
            case 1: this.setState({collapse12:!this.state.collapse12}); break;
            case 2: this.setState({collapse13:!this.state.collapse13}); break;
            case 3: this.setState({collapse14:!this.state.collapse14}); break;
            case 4: this.setState({collapse15:!this.state.collapse15}); break;
            case 5: this.setState({collapse16:!this.state.collapse16}); break;
            case 6: this.setState({collapse17:!this.state.collapse17}); break;
            case 7: this.setState({collapse18:!this.state.collapse18}); break;
            case 8: this.setState({collapse19:!this.state.collapse19}); break;
            case 9: this.setState({collapse110:!this.state.collapse110}); break;
            case 10: this.setState({collapse111:!this.state.collapse111}); break;
            case 11: this.setState({collapse112:!this.state.collapse112}); break;
            case 12: this.setState({collapse113:!this.state.collapse113}); break;
            case 13: this.setState({collapse114:!this.state.collapse114}); break;
            case 14: this.setState({collapse115:!this.state.collapse115}); break;
            default: break;
        }
        
    }

    toggle2 = (index)=>{
        switch (index){
            case 0: this.setState({collapse21:!this.state.collapse21}); break;
            case 1: this.setState({collapse22:!this.state.collapse22}); break;
            case 2: this.setState({collapse23:!this.state.collapse23}); break;
            case 3: this.setState({collapse24:!this.state.collapse24}); break;
            case 4: this.setState({collapse25:!this.state.collapse25}); break;
            case 5: this.setState({collapse26:!this.state.collapse26}); break;
            case 6: this.setState({collapse27:!this.state.collapse27}); break;
            case 7: this.setState({collapse28:!this.state.collapse28}); break;
            case 8: this.setState({collapse29:!this.state.collapse29}); break;
            case 9: this.setState({collapse210:!this.state.collapse210}); break;
            case 10: this.setState({collapse211:!this.state.collapse211}); break;
            case 11: this.setState({collapse212:!this.state.collapse212}); break;
            case 12: this.setState({collapse213:!this.state.collapse213}); break;
            case 13: this.setState({collapse214:!this.state.collapse214}); break;
            case 14: this.setState({collapse215:!this.state.collapse215}); break;
            default:break;
        }
        
    }

    toggle3 = (index)=>{
        switch (index){
            case 0: this.setState({collapse31:!this.state.collapse31}); break;
            case 1: this.setState({collapse32:!this.state.collapse32}); break;
            case 2: this.setState({collapse33:!this.state.collapse33}); break;
            case 3: this.setState({collapse34:!this.state.collapse34}); break;
            case 4: this.setState({collapse35:!this.state.collapse35}); break;
            case 5: this.setState({collapse36:!this.state.collapse36}); break;
            case 6: this.setState({collapse37:!this.state.collapse37}); break;
            case 7: this.setState({collapse38:!this.state.collapse38}); break;
            case 8: this.setState({collapse39:!this.state.collapse39}); break;
            case 9: this.setState({collapse310:!this.state.collapse310}); break;
            case 10: this.setState({collapse311:!this.state.collapse311}); break;
            case 11: this.setState({collapse312:!this.state.collapse312}); break;
            case 12: this.setState({collapse313:!this.state.collapse313}); break;
            case 13: this.setState({collapse314:!this.state.collapse314}); break;
            case 14: this.setState({collapse315:!this.state.collapse315}); break;
            default: break;
        }
        
    }

    getPreguntas1 = ()=>{
        let data = {
            seccion:'generales'
        };
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'getPreguntas.php', requestInfo)
        .then(response => response.json())
        .then(preguntas1Ls => {
            if(preguntas1Ls !== null){
                this.setState({ preguntas1Ls });
            }else{
                this.setState({ preguntas1Ls:[] });
            }
        })
        .catch(e=>console.log(e))
    }

    getPreguntas2 = ()=>{
        let data = {
            seccion:'clubTato'
        };
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'getPreguntas.php', requestInfo)
        .then(response => response.json())
        .then(preguntas2Ls => {
            if(preguntas2Ls !== null){
                this.setState({ preguntas2Ls });
            }else{
                this.setState({ preguntas2Ls:[] });
            }
        })
        .catch(e=>console.log(e))
    }

    getPreguntas3 = ()=>{
        let data = {
            seccion:'e-tickets'
        };
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'getPreguntas.php', requestInfo)
        .then(response => response.json())
        .then(preguntas3Ls => {
            if(preguntas3Ls !== null){
                this.setState({ preguntas3Ls });
            }else{
                this.setState({ preguntas3Ls:[] });
            }
        })
        .catch(e=>console.log(e))
    }


    componentDidMount(){
        this.getPreguntas1();
        this.getPreguntas2();
        this.getPreguntas3();
    } 
     
     
    render(){
        const space6={margin:6};
        return(
            <div>
                <div className="rpreguntas">  
                    <div className="rpreguntas_title" style={{display:'flex', alignContent:'center',alignItems:'baseline'}} > 
                        <h1 >¿Dudas? </h1><span style={space6}/><h3> Aquí puedes encontrar la solución.</h3>  
                    </div>
                    <div className="rpreguntas_royal" >
                    
                        <Row style={{width:'100%' ,paddingTop:30}} >
                            <Col sm={8}>
                            <div style={{paddingLeft:20, height:'85%', display:'contents', width:'100%'}}>
                                    <Nav tabs style={{display:'flex'}}>
                                        <NavItem  className="rpregNavLink" style={{backgroundColor:this.state.t1active===true?'rgb(22, 187, 179)':''}} onClick={this.changePanel.bind(this,'1')}>
                                            <Label style={{cursor:'pointer',color:this.state.t1active===true?'white':''}}  onClick={this.changePanel.bind(this,'1')} >
                                                Generales
                                            </Label>
                                        </NavItem>
                                        <NavItem className="rpregNavLink" style={{backgroundColor:this.state.t2active===true?'rgb(22, 187, 179)':''}} onClick={this.changePanel.bind(this,'2')}>
                                            <Label style={{cursor:'pointer',color:this.state.t2active===true?'white':''}}  onClick={this.changePanel.bind(this,'2')}>
                                                    Club Tato
                                            </Label>
                                        </NavItem>
                                        <NavItem className="rpregNavLink" style={{backgroundColor:this.state.t3active===true?'rgb(22, 187, 179)':''}} onClick={this.changePanel.bind(this,'3')}>
                                            <Label style={{cursor:'pointer',color:this.state.t3active===true?'white':''}}  onClick={this.changePanel.bind(this,'3')}>
                                                    E- Tickets
                                            </Label>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activepanel} >
                                        <TabPane tabId="1" style={{ overflowY:'scroll', overflowX:'hidden'}}>
                                        <Row style={{height:'50vh',padding:10}}>
                                            <Col sm="12">
                                               {
                                                this.state.preguntas1Ls.length>0 ? this.state.preguntas1Ls.map((preguntas1, index) => (
                                                    <div key={index} >
                                                        <Label onClick={this.toggle1.bind(this,index)} style={{ marginBottom: '1rem', fontWeight:'bold', fontSize:16, cursor:'pointer' }}>{preguntas1.pregunta}</Label>
                                                        {
                                                            index===0?
                                                            <Collapse isOpen={this.state.collapse11}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===1?
                                                            <Collapse isOpen={this.state.collapse12}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===2?
                                                            <Collapse isOpen={this.state.collapse13}>
                                                             <p>
                                                                {preguntas1.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===3?
                                                            <Collapse isOpen={this.state.collapse14}>
                                                             <p>
                                                                {preguntas1.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===4?
                                                            <Collapse isOpen={this.state.collapse15}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===5?
                                                            <Collapse isOpen={this.state.collapse16}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===6?
                                                            <Collapse isOpen={this.state.collapse17}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                            :
                                                            index===7?
                                                            <Collapse isOpen={this.state.collapse18}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===8?
                                                            <Collapse isOpen={this.state.collapse19}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                                :
                                                            index===9?
                                                            <Collapse isOpen={this.state.collapse110}>
                                                                <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse> 
                                                                :
                                                            index===10?
                                                                <Collapse isOpen={this.state.collapse111}>
                                                                    <p>
                                                                        {preguntas1.respuesta}
                                                                    </p>
                                                                </Collapse>
                                                                :
                                                            index===11?
                                                            <Collapse isOpen={this.state.collapse112}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===12?
                                                            <Collapse isOpen={this.state.collapse113}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===13?
                                                            <Collapse isOpen={this.state.collapse114}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===14?
                                                            <Collapse isOpen={this.state.collapse115}>
                                                                 <p>
                                                                    {preguntas1.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            ''
                                                        }
                                                    </div>
                                                )):[]
                                                }
                                            </Col>
                                        </Row>
                                        </TabPane>
                                        <TabPane tabId="2" style={{overflowY:'scroll', overflowX:'hidden'}}>
                                            <Row style={{height:'50vh',padding:10}}>
                                                <Col sm="12">
                                                {
                                                this.state.preguntas2Ls.length>0 ? this.state.preguntas2Ls.map((preguntas2, index) => (
                                                    <div key={index} >
                                                        <Label onClick={this.toggle2.bind(this,index)} style={{ marginBottom: '1rem', fontWeight:'bold', fontSize:16, cursor:'pointer' }}>{preguntas2.pregunta}</Label>
                                                        {
                                                            index===0?
                                                            <Collapse isOpen={this.state.collapse21}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===1?
                                                            <Collapse isOpen={this.state.collapse22}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===2?
                                                            <Collapse isOpen={this.state.collapse23}>
                                                             <p>
                                                                {preguntas2.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===3?
                                                            <Collapse isOpen={this.state.collapse24}>
                                                             <p>
                                                                {preguntas2.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===4?
                                                            <Collapse isOpen={this.state.collapse25}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===5?
                                                            <Collapse isOpen={this.state.collapse26}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===6?
                                                            <Collapse isOpen={this.state.collapse27}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                            :
                                                            index===7?
                                                            <Collapse isOpen={this.state.collapse28}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===8?
                                                            <Collapse isOpen={this.state.collapse29}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                                :
                                                            index===9?
                                                            <Collapse isOpen={this.state.collapse210}>
                                                                <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse> 
                                                                :
                                                            index===10?
                                                                <Collapse isOpen={this.state.collapse211}>
                                                                    <p>
                                                                        {preguntas2.respuesta}
                                                                    </p>
                                                                </Collapse>
                                                                :
                                                            index===11?
                                                            <Collapse isOpen={this.state.collapse212}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===12?
                                                            <Collapse isOpen={this.state.collapse213}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===13?
                                                            <Collapse isOpen={this.state.collapse214}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===14?
                                                            <Collapse isOpen={this.state.collapse215}>
                                                                 <p>
                                                                    {preguntas2.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            ''
                                                        }
                                                    </div>
                                                )):[]
                                                }
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="3" style={{overflowY:'scroll', overflowX:'hidden'}}>
                                            <Row style={{height:'50vh',padding:10}}>
                                                <Col sm="12">
                                                {
                                                this.state.preguntas3Ls.length>0 ? this.state.preguntas3Ls.map((preguntas3, index) => (
                                                    <div key={index} >
                                                        <Label onClick={this.toggle3.bind(this,index)} style={{ marginBottom: '1rem', fontWeight:'bold', fontSize:16, cursor:'pointer' }}>{preguntas3.pregunta}</Label>
                                                        {
                                                            index===0?
                                                            <Collapse isOpen={this.state.collapse31}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===1?
                                                            <Collapse isOpen={this.state.collapse32}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===2?
                                                            <Collapse isOpen={this.state.collapse33}>
                                                             <p>
                                                                {preguntas3.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===3?
                                                            <Collapse isOpen={this.state.collapse34}>
                                                             <p>
                                                                {preguntas3.respuesta}
                                                            </p>
                                                            </Collapse>
                                                            :
                                                            index===4?
                                                            <Collapse isOpen={this.state.collapse35}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===5?
                                                            <Collapse isOpen={this.state.collapse36}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===6?
                                                            <Collapse isOpen={this.state.collapse37}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                            :
                                                            index===7?
                                                            <Collapse isOpen={this.state.collapse38}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                            :
                                                            index===8?
                                                            <Collapse isOpen={this.state.collapse39}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>    
                                                                :
                                                            index===9?
                                                            <Collapse isOpen={this.state.collapse310}>
                                                                <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse> 
                                                                :
                                                            index===10?
                                                                <Collapse isOpen={this.state.collapse311}>
                                                                    <p>
                                                                        {preguntas3.respuesta}
                                                                    </p>
                                                                </Collapse>
                                                                :
                                                            index===11?
                                                            <Collapse isOpen={this.state.collapse312}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===12?
                                                            <Collapse isOpen={this.state.collapse313}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===13?
                                                            <Collapse isOpen={this.state.collapse314}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            index===14?
                                                            <Collapse isOpen={this.state.collapse315}>
                                                                 <p>
                                                                    {preguntas3.respuesta}
                                                                </p>
                                                            </Collapse>
                                                                :
                                                            ''
                                                        }
                                                    </div>
                                                )):[]
                                                }
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Col>
                            <Col sm={3} >
                                <div className="rpreguntas_tatodiv">
                                    <img className="rpreguntas_tato" src={this.props.servurlassets+"tato_preguntas.png"}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_preguntas