import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Pabellones extends Component{

    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
    return(
        <div>
            <div  className="atracpabellones">  
                <div className="ap_royalg">
                    <Row style={{ width:'100%'}}>
                        <Col sm={4}>
                            <div className="ap_titlesa">
                                <h1>Pabellones</h1>
                            </div>
                            <div className="ap_subtitlesa">
                                <h3>
                                Diversión exprés
                                </h3>
                            </div>
                            <br/>
                            <div className="ap_textsa">
                                <p>
                                    Conoce la forma más divertida de pasear en un Centro comercial. Nuestros pabellones son una zona especialmente diseñada para los niños y se encuentran en los pasillos de los centros comerciales.
                                    <br/><br/>
                                    Aquí los pequeños podrán andar en nuestros Zoo riders, Kars o divertirse en nuestros increíbles juegos.
                                </p>
                            </div>
                        </Col>
                        <Col sm={8} style={{textAlign:'center'}}>
                            <div style={{position:'relative'}}>
                                <YouTube videoId="FsEb4wiG8_k" opts={opts} onReady={this._onReady} />  
                            </div> 
                        </Col>
                    </Row>
                </div>
            </div>   
        </div>
        )
    }

}
export default Secc_Atrac_Pabellones