import React, { Component } from 'react';
import './desktop.css';
import {  Row, Col, Label, Input,Button} from 'reactstrap';
import Swal from 'sweetalert2';

class Secc_Evtos_Emp extends Component{
    constructor() {
        super();

        this.state ={
            swimg:'',
            info:false,
            infodet:false,
            sucursalesLs:[]
        };
    }

    enviar=()=>{
        let data = {
            cuerpo:"Nombre: "+document.getElementById("txtNombre").value+" <br/>Teléfono:"+document.getElementById("txtTelefono").value+" <br/>Invitados:"+document.getElementById("txtCantidad").value+" <br/>Email:"+document.getElementById("txtEmail").value,
            email:"aosorio@grupodiniz.com.mx",
            asunto:"- Eventos Empresariales",
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase+'/sendCorreoContacto.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                Swal.fire(
                    'Enviado',
                    'Se han enviado tus datos',
                    'success'
                )
            })
            .catch(e => console.log(e));
            Swal.fire(
                'Enviado',
                'Se han enviado tus datos',
                'success'
            )
            
    }

    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
       this.getSucursales();
    } 
     
     
    render(){

        return(
            <div>
                <div  className="evtosemp" style={{backgroundImage:"url('"+this.props.servurlassets+"secc_fiestas_back.png')"}}>  
                    {/* Inicio del contenido de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div className="ee_royal" >
                        <div style={{ width:'100%'}}>
                            <Row>
                                <Col sm={6}>
                                    <div style={{  margin:0, position:'absolute'}} >
                                        <div className="ee_title">
                                            <h1  style={{color:'white', textAlign:'left'}}>
                                            Eventos <br/>empresariales
                                            </h1>
                                        </div>
                                        <div className="ee_subtitle">
                                            <h2  style={{color:'white', textAlign:'left'}}>
                                                ¿Estás buscando una integración de grupo diferente y divertida?
                                                <br/><br/>
                                                Ven y descubre que tenemos muchas opciones para<br/>pasar un día muy divertido en grupo.
                                            </h2>
                                            <br/>
                                            <h5 style={{color:'white'}}>
                                            Da click aquí: 
                                            <a href="https://wa.me/+525559173835?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales" 
                                            className="a-link" style={{display:'contents'}}>
                                            <img src="https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/a43.png" alt="Alternate Text" style={{height:'20px'}}/></a>
                                            <br/>
                                            eventosempresariales@recorcholis.com.mx
                                            </h5>
                                        </div>
                                        <br/>
                                        <Row style={{color:'white'}}>
                                            <Col sm={6}>
                                                <Label>Nombre completo</Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Label>Teléfono</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Input bsSize='sm' type="text" id="txtNombre" />
                                            </Col>
                                            <Col sm={6}>
                                                <Input bsSize='sm' type="text" id="txtTelefono" />
                                            </Col>
                                        </Row>
                                        
                                        <Row style={{color:'white'}}>
                                            <Col sm={6}>
                                                <Label>Correo electrónico</Label>
                                            </Col>
                                            <Col sm={6}>
                                                <Label>Cantidad de Invitados</Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Input bsSize='sm' type="text" id="txtEmail" />
                                            </Col>
                                            <Col sm={6}>
                                                <Input bsSize='sm' type="Number" id="txtCantidad" />
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={6}>
                                                <Label>Sucursal de preferencia</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{color:'white'}}>
                                            <Col sm={6}>
                                                <Input  type="select" id='sucursalSelect' >
                                                <option key={0}>
                                                    Seleccione una Sucursal
                                                </option>
                                                {this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(   
                                                    <option key={index} value={sucursal.id} 
                                                        disabled={sucursal.sucursal===''?true:false}
                                                        style={{color:"black",
                                                        fontWeight:sucursal.sucursal===''?"bold":''}}>
                                                        {sucursal.estado}
                                                        {sucursal.sucursal}
                                                    </option>
                                                )):[]}    
                                                </Input>
                                            </Col>
                                            <Col sm={2}>
                                            </Col>
                                            <Col sm={3}>
                                            <Button className="ee_myButton" onClick={this.enviar.bind()}>Enviar</Button>
                                            </Col>
                                        </Row>
                                        <br/>
                                    </div>
                                </Col>
                                <Col sm={6}>

                                    <div >
                                    <img style={{zIndex:20}} className="ee_tatis" src={this.props.servurlassets+"secc_evtos_emp_img1.png"} alt="alternate text"/> 
                                    </div>
                                    <img style={{zIndex:20, height:300, bottom:0, left:'45%'}} className="ee_tatis" src={this.props.servurlassets+"secc_evtos_emp_img2.png"} alt="alternate text"/> 
                                </Col>
                            </Row>
                            
                        </div>
                    </div>   
                </div>   
            </div>
        )
    }

}
export default Secc_Evtos_Emp