import React, { Component } from "react";
import Swal from "sweetalert2";

class Secc_registra_club extends Component {
    constructor() {
        super();
        this.state = {
            objetosLs: [],
            tipo: "",
        };
    }

    registraTato = () => {
        if (!document.getElementById("id_terminos").checked) {
            Swal.fire("Términos y condiciones", "Acepte los términos y condiciones para continuar", "warning");
        } else {
            var nombre = document.getElementById("nombre").value;
            var apaterno = document.getElementById("apaterno").value;
            var amaterno = document.getElementById("amaterno").value;
            var nacimiento = document.getElementById("nacimiento").value;
            var correo = document.getElementById("correo").value;
            var telefono = document.getElementById("telefono").value;
            var contrasenia = document.getElementById("contrasenia").value;
            var contrasenia2 = document.getElementById("contrasenia2").value;

            let data = {
                nombre,
                apaterno,
                amaterno,
                nacimiento,
                correo,
                telefono,
                contrasenia,
            };

            const requestInfo = {
                method: "POST",
                body: JSON.stringify(data),
                header: new Headers({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }),
            };

            if (!nombre || !apaterno || !nacimiento || !correo || !telefono || !contrasenia) Swal.fire("Campos vacíos", "Llene todos los campos obligatorios.", "warning");
            else if (contrasenia != contrasenia2) Swal.fire("ERROR", "Las Contraseñas no coinciden", "warning");
            else
                fetch(this.props.servurlbase + "NewMember.php", requestInfo)
                    .then((response) => response.json())
                    .then((response) => {
                        if (response != 0) {
                            if (response["Message"]) {
                                Swal.fire("Error", "Ha ocurrido un error", "warning");
                            } else if (response["Data"]["MGUID"]) {

                                fetch(this.props.servurlbase + "enviarConfirmacion.php", requestInfo)
                                .then((response) => response.json())
                                .then((response) => {
                                    if (response) {
                                        this.props.cambiaSeccion('registro_completo')
                                    } 
                                })

                            }
                        } else {
                            Swal.fire("Error", "El correo ya había sido registrado antes", "warning");
                        }
                    })
                    .catch((e) => console.log(e));
        }
    };

    render() {
        return (
            <div className="container-fluid color_bckg">
                <div className="row">
                    <div className="login-grid__center">
                        <div className="col-12">
                            <br />
                        </div>
                        <div className="col-12">
                            <br />
                        </div>
                        <div className="col-12">
                            <br />
                        </div>
                        <h1 className="login__title hide-at-xs">Registro de Club Tato</h1>
                        <div className="column-flex show-at-xs register-title">
                            <h1>Asegúrate</h1>
                            <h2>
                                de escribir tu correo <span className="color-2"> correctamente,</span> para continuar el proceso de registro.
                            </h2>
                        </div>
                        <div className="col-12">
                            <br />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Nombre(s) *</label>
                            <input type="text" id="nombre" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Apellido paterno *</label>
                            <input type="text" id="apaterno" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Apellido materno</label>
                            <input type="text" id="amaterno" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Fecha de nacimiento *</label>
                            <input type="date" id="nacimiento" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Correo *</label>
                            <input type="text" id="correo" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Teléfono *</label>
                            <input type="text" id="telefono" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Contraseña *</label>
                            <input type="password" id="contrasenia" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <label className="control-label">Confirma contraseña *</label>
                            <input type="password" id="contrasenia2" className="form-control" style={{ textAlign: "center" }} />
                        </div>
                        <div className="col-12">
                            <br />
                        </div>
                        <div className="col-12 border">
                            <input type="checkbox" id="id_terminos" />
                            <a className="text-white" onClick={this.props.cambiaSeccion.bind(this, "terminosycondiciones", "", "")}>
                                Acepto el aviso de privacidad, así como los términos y condiciones.
                            </a>
                        </div>
                        <div className="col-12">
                            <br />
                        </div>
                        <div className="col-12 text-center">
                            <a className="ee_myButton" onClick={this.registraTato.bind()}>
                                Regístro
                            </a>
                        </div>
                        <div className="col-12">
                            <a className="click_aqui" onClick={this.props.cambiaSeccion.bind(this, "iniciar_club", "", "")} href="#">
                                <p>Regresar</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Secc_registra_club;
