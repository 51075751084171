import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import { Row, Col} from 'reactstrap';


class Secc_Atrac_Games extends Component{
    
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
    return(
        <div>
            <div className="atracgames">  
                <div className="ag_royalg">
                    <Row style={{ width:'100%'}}>
                        <Col sm={4}>
                            <div className="ag_titlesa">
                                <h1>Games</h1>
                            </div>
                            <div className="ag_subtitlesa">
                                <h3>
                                    Lo mejor en juegos
                                    de video, realidad
                                    virtual y destreza.
                                </h3>
                            </div>
                            <br/>
                            <div className="ag_textsa">
                                <p>
                                    Es un área dirigida a todas las edades
                                    donde se encuentra una gran variedad
                                    de atracciones novedosas, como: Juegos de redención,
                                    simuladores, juegos deportivos, juegos de destreza,
                                    juegos mecánicos y muchos más. <br/><br/>
                                    La diversión será aún mayor cuando descubras que puedes
                                    canjear los tickets que dan la gran mayoría de estos
                                    juegos por increíbles premios. <br/><br/>
                                </p>
                            </div>
                            <a href="#" className="ee_myButton" onClick={this.props.cambiaSeccion.bind(this,'recargas','','')}>Comprar ahora</a>
                        </Col>
                        <Col sm={8} style={{textAlign:'center',paddingTop:50}}>
                            <div style={{position:'relative'}}>
                                <YouTube videoId="3lhEBjwYC6k" opts={opts} onReady={this._onReady} />  
                            </div> 
                        </Col>
                    </Row>
                </div>
                {/* Fin del contenido de la seccion*/}
            </div>   
        </div>
        )
    }
}
export default Secc_Atrac_Games