import React, { Component } from 'react';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Snacks extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }
    render(){
        return(
            <div>
                <div  className="movilatractionsS">  
                   <div className="movilroyalgn">
                        <Row style={{paddingLeft:'5%', width:'90%'}}>
                            <Col sm={4}>
                                <div className="moviltitlesn">
                                    <h1>Snacks</h1>
                                </div>
                                <div className="movilsubtitlesn">
                                    <h2>
                                    Lo que se te antoje
                                    </h2>
                                </div>
                                <div className="moviltextsn">
                                    <p className="movilhide-at-sm hide-at-xs">
                                        Encuentra el menú en nuestro código QR en la zona de Snacks de nuestras sucursales ¡Recórcholis!
                                    <br/>
                                        O presiona en esta imagen para verlo. 
                                    </p>
                                    <div style={{ justifyContent:'center' ,textAlign:'center'}}>
                                        <a target={"_blank"} href={"https://recorcholis.com.mx/Home/SnacksMenu/"}><img style={{height:200}} src={this.props.servurlassets+"secc_atracc_img1.png"} alt='val'/></a>
                                    </div>
                                    <br/>
                                    <br/>
                                    <p className="movilhide-at-sm hide-at-xs">
                                        Productos sujetos a disponibilidad. Consulta las sucursales con área de snacks, aquí
                                        <br/>
                                        Tus antojos son
                                        órdenes para Tato.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={8}>
                                <div style={{ justifyContent:'center' ,textAlign:'center'}}>
                                    <div>
                                        <img  style={{height:200}} src={this.props.servurlassets+"secc_atracc_img2.png"} alt='val'/>
                                        <img  style={{height:200}} src={this.props.servurlassets+"secc_atracc_img3.png"} alt='val'/>
                                        <img  style={{height:200}} src={this.props.servurlassets+"secc_atracc_img4.png"} alt='val'/>
                                    </div>
                                </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Snacks