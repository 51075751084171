import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Pista extends Component{

     
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="atracpista">  
                   <div className="apis_royalg">
                          <Row style={{ width:'100%'}}>
                              <Col sm={4} >
                                  <div style={{textAlign:'left'}}>
                                    <div className="apis_titlesa">
                                        <h1>Pista de hielo</h1>
                                    </div>
                                    <div className="apis_subtitlesa">
                                        <h3>
                                        La atracción
                                        más cool de
                                        la Ciudad de México
                                        </h3>
                                    </div>
                                    <div className="apis_subtitlesa">
                                    <h4>
                                    Encuéntralas en Parque Vía Vallejo, CDMX y Mundo E, Edomex.
                                    </h4>
                                    </div>
                                    <div className="apis_textsa">
                                        <p>
                                        Aquí te quedarás frío de la emoción, ven y patina con tu familia y amigos en nuestras pistas de hielo;
                                        tendrás un momento lleno de tanta diversión que quitarte los patines será lo último que querrás hacer.
                                        <br/><br/>
                                        Para ti que siempre has soñado con aprender patinaje artístico tenemos clases con los mejores profesores. 
                                        </p>
                                    </div>
                                    </div>
                                    <br/>
                                    <a onClick={this.props.cambiaSeccion.bind(this,"clasesPH","","")} class="ee_myButton">Clases de patinaje aquí</a>
                              </Col>
                              <Col sm={8} style={{textAlign:'center'}}>
                                    <div style={{position:'relative'}}>
                                        <YouTube videoId="iAezXwkYp88" opts={opts} onReady={this._onReady} />  
                                    </div> 
                                </Col>
                          </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Pista