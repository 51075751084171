import React, { Component } from 'react';
import {Row} from 'reactstrap';

class Secc_Factura extends Component{    
    render(){
        return (
            <div>
                <div  className="movilcontacto">  
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Genera tu Factura</h2>
                        </div>
                    </div>
                    <div className="movilroyalg" >
                        <Row style={{paddingTop:'20%' ,width:'100%'}}>
                            <iframe title="Facturacion" src={'https://diniz.com.mx/facturacion_recrefam'} style={{height:"650px", width:"100%"}}/>
                        </Row>
                    </div>
                </div>
            </div>
        ) 
    }

}
export default Secc_Factura