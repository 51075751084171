import React, { Component } from 'react';
import {  Row, Col} from 'reactstrap';

class Secc_Graduaciones extends Component{
    constructor() {
        super();
        this.state ={
            swimg:'',
            info:false,
            infodet:false
        };
    }

    render(){
        return(
            <div>
                <div  className="movilfiestas" style={{backgroundImage:"url('"+this.props.servurlassets+"secc_gradua_back.png')"}}>  
                    <div className="movilroyalgra" >
                        <div style={{ width:'100%'}}>
                            <Row>
                                <Col sm={4}>
                                    <div style={{  margin:0, position:'absolute', left:'10%', top:'0%'}} >
                                        <img className="moviltatisgra" src={this.props.servurlassets+"secc_gradua_img1.png"} alt="alternate text"/> 
                                    </div>
                                </Col>
                                <Col sm={8}>
                                    <div>
                                        <h2 className="movilfont-pop"  style={{color:'black', fontSize:24, textAlign:'center',paddingTop:"110%", paddingRight:20, paddingLeft:20}}>
                                            Celebra jugando y llévate gratis la comida, bebida, pastel y dulces para los graduados y sus invitados.
                                        </h2>
                                    </div>
                                    <br/><br/>
                                    <div>
                                        <h2 className="movilfont-pop" style={{color:'#E1271D', fontSize:30, textAlign:'center'}}>
                                            ¡RESERVA YA TU GRADUACIÓN DE REGALO!
                                        </h2>
                                    </div>
                                    <Row>
                                        <Col sm={6}>
                                            <div>
                                                <img className="movilcarimggra" style={{height:200}} src={this.props.servurlassets+"secc_gradua_img3.png"} alt="alternate text"/> 
                                                <img className="movilcarimggra" style={{height:200}} src={this.props.servurlassets+"secc_gradua_img2.png"} alt="alternate text"/> 
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/><br/><br/>
                                    <Row>
                                        <Col sm={12}>
                                            <div style={{zIndex:20,position:'absolute', bottom:-70, left:'0%'}}>
                                                <img className="movilcarimggra" style={{height:100}} src={this.props.servurlassets+"secc_gradua_img4.png"} alt="alternate text"/> 
                                            </div>
                                            <div style={{backgroundColor:'#E1271D', color:'white', fontSize:18, paddingLeft:150, textAlign:'center'}}>
                                                <br/>
                                                <p>
                                                    <b>Un regalo para la graduación más divertida</b> y con más likes en las redes sociales de<br/>
                                                    ¡Recórcholis! o ¡KATAPLUM! <b>Ver términos y condiciones.</b>
                                                </p>
                                                <br/>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>   
                </div>   
            </div>
        )
    }

}
export default Secc_Graduaciones