import React, { Component } from 'react';
import {Label, Row, Col, Input, Button, Modal, ModalHeader, ModalBody,ModalFooter} from 'reactstrap';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import Swal from 'sweetalert2';

class ModalBT extends Component{
    constructor() {
        super();

        this.state ={
            sucursalesLs:[],
        };
    }
    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
       this.getSucursales();
     } 

     envioCorreo =()=>{
        
        let data = {
            cuerpo:"Nombre: "+document.getElementById("txtNombre").value+" <br/>Teléfono:"+document.getElementById("txtTelefono").value+" <br/>Email:"+document.getElementById("txtCorreo").value,
            email:"contacto@grupodiniz.com.mx",
            asunto:"Bolsa trabajo",
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(URL_CORESERVICE + '/sendCorreoContacto.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            
        })
        .catch(e=>console.log(e)); 
        Swal.fire(
            'Enviado',
            'Se han enviado tus datos',
            'success'
        )
        this.props.switchModal();
    }

    render(){
        return(
            <div>
                <Modal id="ModalBT" isOpen={this.props.modalBT} toggle={this.props.switchModal} size="xl" >
                    <ModalHeader toggle={this.props.switchModal} style={{color:'#D5060F'}}><b>Solicitud</b></ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b> Nombre *</b></Label>
                            </Col>
                        </Row>    
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Input id="txtNombre" type="text"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b>Apellido Materno *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" bsSize='sm' id="txtAm"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Label><b>Apellido Paterno *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtAp"  ></Input>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b> Correo *</b></Label>
                            </Col>
                        </Row>    
                        <Row>
                            <Col sm={4}>
                                <Input id="txtCorreo" type="text"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b>Telefono *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtTelefono"  ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Label><b>Fecha de nacimiento *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="date" id="txtFecha" max={"2005-01-31"} ></Input>
                            </Col>
                        </Row><br/><br/>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label> <b>Calle *</b></Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input id="txtCalle" type="text"></Input>
                            </Col>
                        </Row>
                        <Row>                            
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b>Numero *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtNumero"  ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Label><b>Colonia *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtColonia"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b> Delegación *</b></Label>
                            </Col>
                        </Row>   
                        <Row>
                            <Col sm={4}>
                                <Input id="txtDel" type="text"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={4}>
                                <Label><b>Municipio *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtMun"  ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Label><b>C.P. *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Input type="text" id="txtCP"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} sm={6}>
                                <Label><b>Puesto en el que estás interesado *</b></Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Input id="txtDel" type="text"></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{textAlign:"left"}} placeholder="Sucursal más cercana" sm={6}>
                                <Label><b>Sucursal más cercana *</b></Label>  
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6} xs={6}>
                                <Input  type="select" id='sucursalSelect' >
                                <option key={0}>
                                    Seleccione una Sucursal
                                </option>
                                {this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(   
                                    <option key={index} value={sucursal.id} 
                                        disabled={sucursal.sucursal===''?true:false}
                                        style={{color:"black",
                                        fontWeight:sucursal.sucursal===''?"bold":''}}>
                                        {sucursal.estado}
                                        {sucursal.sucursal}
                                    </option>
                                )):[]}    
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={3}>
                            <Button className='movilbtn movilbtn-recorcholis' onClick={this.envioCorreo.bind()} size="sm">Enviar</Button>
                        </Col>
                    </ModalFooter>
                </Modal>    
            </div>
        );
    }    
}

export default ModalBT