import React, { Component } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Row,Col} from 'reactstrap';
import ModalPaquete from './modalPaqueteMovil';
import ReactPixel from 'react-facebook-pixel';

class Secc_paquetes extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            modpaq:false,
            paqimagen:'',
            mesessi:'',
            codigo:'',
            monto:'',
            codigoPromocional:'',
            protipo:'',
            lastID:'',
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 10,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }   
    
    componentDidMount(){
        this.getObjetos();
        if(this.props.datos.codigo){
            this.props.datos.nombre=this.props.datos.nombre2;
            this.showModalPaquete(this.props.datos);
        }
        // ReactPixel.init('1334903514091210');
        // ReactPixel.pageView();
    } 
     
    showModalPaquete=(objeto)=>{
        this.setState({codigo: objeto.codigo});
        this.setState({codigoPromocional: objeto.codigoPromocional});
        this.setState({paqimagen: objeto.nombre + objeto.extension});
        this.setState({mesessi: objeto.mesessi});
        this.setState({protipo: objeto.protipo});
        this.setState({modpaq:true});
    }

    cierraPaquete = () =>{
        this.setState({modpaq:false});
    }
    
    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 2,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div  className="movilsection-promociones" >
                    <div className="moviltwo-tone-backgroundpromociones" >
                        <div className="moviltone-promociones" />
                    </div>
                    
                    <div style={{paddingTop:"5%",textAlign:"left",paddingLeft:"5%"}}>
                    <Row>
                        <Col xs={7} sm={8} md={8} className="movilfont-pop" style={{color:"#FFF"}}><h2>Selecciona tu Promoción</h2></Col>
                        
                    </Row>
                    </div>
                    <div>
                        <Carousel
                            arrows={this.props.display==="none"?true:false}
                            responsive={responsive}
                            infinite={true}
                            autoPlaySpeed={1000}
                            transitionDuration={500}
                            renderDotsOutside={false}>
                            {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                <Row key={index}>
                                    <Col xs={1} xl={2} md={2}></Col>
                                    <Col xs={10} xl={8} md={8}>
                                        <Card key={index} style={{ backgroundColor:'transparent',marginLeft:"12%", border:'none'}}>
                                            <div className='movilzoom'>
                                                <img onClick={this.showModalPaquete.bind(this,objetos)} src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col xs={1} xl={2} md={2}></Col>
                                </Row>
                            )):[]}    
                        </Carousel>
                    </div>   
                </div>   
                {
                    this.state.modpaq === true?
                        <ModalPaquete protipo={this.state.protipo} codigoPromocional={this.state.codigoPromocional} titulo="Promociones" getCarrito={this.props.getCarrito}token={this.props.token} color={"FFF"} backColor={"FF8B28"} codigo={this.state.codigo} monto={this.state.monto} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} cierraPaquete={this.cierraPaquete} mesessi={this.state.mesessi} nombreimagen={this.state.paqimagen}/>
                    :''
                }
            </div>
        )
    }
}
export default Secc_paquetes