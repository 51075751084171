import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

class Secc_Mapa extends Component{
    render(){
        const Marker = props => {
            return <div><img alt='ok' style={{height:"30px",width:"18px"}} src='https://ecommercegd.blob.core.windows.net/imagenes-r/marker.png' /></div>
        }
        const defaultProps = {
            center: {
                lat:this.props.lat,
                lng:this.props.long
            },
            zoom: 15
        };
        return(
              <div style={{ height: '400px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyC-6-ekQ4Y7yRLTceu2jNGBAE37g0n-FW0" }}
                        center={defaultProps.center}
                        defaultZoom={defaultProps.zoom}>
                        <Marker lat={this.props.lat} lng={this.props.long}/>
                    </GoogleMapReact>
                </div>)
    }
}
export default Secc_Mapa
