import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import {Label, Input, FormGroup, Button,Row} from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Swal from 'sweetalert2';

class Secc_recargas extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            modpaq:false,
            paqimagen:'',
            precioR:0,
            mesessi:'',
            response_card: false
        };
    }

    

    getObjetos = ()=>{
        let data ={
            carruselid : 1,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    add_ = ()=>{
        //166-345526-2
        let reco='';
        let montox = this.state.tipo==='nueva' ? Number(this.props.costotarjeta) + Number(document.getElementById("txtMonto").value) : Number(document.getElementById("txtMonto").value);
        
        if(this.state.tipo==='preexistente'){
            reco=document.getElementById("txtRecorcard").value;
            this.consulta_detalle(reco);
            setTimeout(() => {
                if(this.state.response_card === false){
                    return Swal.fire('Error','Tarjeta Invalida','warning');
                }
                else{

                    let data ={
                        token : this.props.token,
                        monto : montox,
                        codigo : "SCC001",
                        recorcard : reco,
                        tipo:this.state.tipo,
                        regalo_email:"",
                        regalo_name:""
                    }
                    this.add_recarga(data);
                }
            }, 1500);            
        }
        else{

            let data ={
                token : this.props.token,
                monto : montox,
                codigo : "SCC001",
                recorcard : reco,
                tipo:this.state.tipo,
                regalo_email:"",
                regalo_name:""
            }
            this.add_recarga(data);

        }

             
        
    }

    add_recarga = (data) => {
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'addRecarga.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            if(respuesta !==null){
                this.props.getCarrito(this.props.token);
                Swal.fire('Artículo agregado a carrito','','success') 
            }else{
                Swal.fire('Error','Upss, algo ocurrió, intente mas tarde','warning')
            } 
        })
        .catch(e=>console.log(e)); 
    }

    consulta_detalle = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    } 
    
    showModalPaquete=(objeto)=>{
        this.setState({paqimagen: "secc_recargas_img1.png"});
        this.setState({mesessi: 0});
        this.setState({modpaq:true});
    }

    cierraPaquete = () =>{
        this.setState({modpaq:false});
    }

    componentDidMount(){
        this.showModalPaquete();
        this.costoTarjetas();
    } 
     
    handleChange = e => {
        const {value } = e.target;
        this.setState({tipo:value});
    }

    valida_paquete = ()=>{
        let montox= Number(document.getElementById("txtMonto").value);
        let data ={
            montox : montox,
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getredireccion_promociones.php', requestInfo)
        .then(response => response.json())
        .then(redireccion_promociones => {
            if(redireccion_promociones !==null){
                Swal.fire({
                    title: 'Seleccionaste una recarga de '+montox,
                    text: 'Te redireccionaremos a un combo que te da mayores beneficios',
                    confirmButtonText: 'ÍR',
                  }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.cambiaSeccion("paquetes",redireccion_promociones,'');
                    }
                  })
            }
        })
        .catch(e=>console.log(e)); 
    }
    
    costoTarjetas = () =>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getCostoTarjeta.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ precioR:objetosLs[0].costo });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
     
    componentDidMount=()=>{
        this.props.getSesionCtato(this.props.token);
    }

    render(){
        return(
            <div>
                <div  className="movilsection-recargas" >  
                    <div >
                        <div className="moviltone-recargas"/>
                    </div>
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Recarga en línea</h2>
                        </div>
                    </div>
                    <div style={{paddingTop:"5%",paddingLeft:"25%",display:"flex"}}>
                        <img style={{backgroundColor:'transparent'}} width={"70%"} height={"auto"} src={this.props.servurlassets+"secc_recargas_img1.png"} alt="alternate text"/> 
                    </div>   
                        <div>
                            <div style={{margin:0, position:'relative', display:'flex', left:'10%'}}>
                                <Label style={{fontSize:25, fontWeight:'bold'}}>Deseas que el dinero <br/> y bonus sea cargado en:</Label>
                            </div>
                            <br/>
                            <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'10%', top:'30%'}} >
                                <Row style={{paddingLeft:"2%"}} >
                                </Row>
                                <Row style={{paddingLeft:"2%"}} >
                                <Input onChange={this.valida_paquete.bind()} defaultValue={100} id="txtMonto" type='select' style={{fontSize:20, width:"80%"}}>
                                        <option value="100">$100</option>
                                        <option value="150">$150</option>
                                        <option value="200">$200</option>
                                        <option value="250">$250</option>
                                        <option value="300">$300</option>
                                        <option value="350">$350</option>
                                        <option value="400">$400</option>
                                        <option value="450">$450</option>
                                        <option value="500">$500</option>
                                        <option value="550">$550</option>
                                        <option value="600">$600</option>
                                        <option value="650">$650</option>
                                        <option value="700">$700</option>
                                        <option value="750">$750</option>
                                        <option value="800">$800</option>
                                        <option value="850">$850</option>
                                        <option value="900">$900</option>
                                        <option value="950">$950</option>
                                        <option value="1000">$1000</option>
                                    </Input>
                                </Row>
                                <div style={{textAlign:"left"}}>
                                    <p>Monto Minimo $100</p>
                                    <p>Monto Maximo $1000</p>
                                </div>
                            </FormGroup>
                            <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'10%', top:'30%'}} >
                                <Label style={{fontSize:20}}>
                                    <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="preexistente" /> En tarjeta preexistente
                                </Label>
                                <br/>
                                <Label style={{fontSize:20}} >
                                    <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="nueva" /> En tarjeta nueva + (${this.props.costotarjeta})
                                </Label>
                                <br/>
                                <Label style={{fontSize:20}} >
                                    <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" defaultChecked={true} name="radio1" value="cupon" /> En cupón digital
                                </Label>
                                
                            <div style={{color:"red"}}>
                                <p>(no aplica la promoción miercoles 2x1)</p>
                            </div>
                            </FormGroup>
                            
                            <div style={{display:this.state.tipo==='preexistente'?"block":"none"}}>
                                <Row style={{paddingLeft:"10%"}} >
                                    <Label style={{fontSize:15, fontWeight:'bold'}}>Tarjeta Recorcard (10 dígitos)</Label>
                                </Row>
                                {this.props.cards.length>0?
                                    <div style={{margin:0, position:'relative', display:'flex', marginLeft:'10%', top:'45%'}} >
                                        <Input style={{width:280}} id="txtRecorcard" type="select"> 
                                            { this.props.cards.map((card,index) => (
                                                <option key={index} value={card.NUMBER}>{card.NUMBER}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    :
                                    <Row style={{paddingLeft:"12%"}} >
                                        <NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:20, width:"60%"}} />
                                    </Row>
                                }
                            </div>
                            <div style={{paddingLeft:"10%"}} >
                                <Button className="movilbtn-recorcholis" onClick={this.add_.bind(this)} >Agrega a carrito</Button>
                            </div>
                        </div>
                </div>   
            </div>
        )
    }

}
export default Secc_recargas