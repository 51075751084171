import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import {  Row, Col, Label,Card,Button} from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ModalFiestas from './modalFiestas';

class Secc_Fiestas extends Component{
    constructor() {
        super();

        this.state ={
            swimg:'',
            modal:false,
            info:false,
            infodet:false
        };
    }


    chan = (fiestaindex)=>{
        this.setState({swimg:fiestaindex});
        this.setState({info:false});
        this.setState({infodet:true});
    }

    kwan = ()=>{
        this.setState({infodet:false});
        this.setState({info:true});
        
    }
    
    switchModal = ()=>{
        this.setState({modal:!this.state.modal});
    } 
    
    componentDidMount(){
        this.setState({info:true});
     } 
     
     
    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
          };

       
        return(
            <div>
                <div style={{backgroundImage:"url('"+this.props.servurlassets+"secc_fiestas_back.png')"}}>  
                    {/* Inicio del contenido de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div >
                        <div style={{textAlign:'center'}}>
                            <p className="movilfont-pop" style={{color:'white',fontSize:"25px",paddingTop:"10%"}}>
                                Festeja tu cumpleaños<br/>
                            </p>
                        </div>
                        <div style={{margin:0, position:'absolute',left:"25%"}}>
                            <Button  style={{textAlign:"25px"}} onClick={this.switchModal.bind()} className='movilbtn btn-recorcholis' >! Reserva Aqui !</Button>
                        </div>
                        <div >
                            <br/><br/>
                            <Carousel
                                responsive={responsive}
                                infinite={true}
                                autoPlay={this.props.deviceType !== "mobile"? true : false}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                renderDotsOutside={false}
                                arrows={this.props.display==="none"?true:false}
                                deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px">
                                    <CSSTransition in={this.state.info} timeout={300} classNames="movilexamplex" unmountOnExit>
                                        <Card  style={{backgroundColor:'transparent',margin:0, border:'none'}}>
                                            <div style={{margin:30}} >
                                                <Row style={{justifyContent:'center'}}>
                                                    <img onClick={this.chan.bind(this,'1')} className="movilcarimgfies" style={{margin:0, height:250, width:250}} src={this.props.servurlassets+"secc_fiestas_img1a.png"} alt="alternate text"/> 
                                                </Row>
                                                <Row style={{justifyContent:'center', marginTop:20}}>
                                                    <div style={{backgroundColor:'#1f9a0f',color:'white', width:250,textAlign:'center'}}>
                                                        <Label className="moviltextlab">Fiesta Games</Label>
                                                    </div>
                                                </Row>
                                            </div> 
                                        </Card>    
                                    </CSSTransition>
                                    <CSSTransition in={this.state.info} timeout={300} classNames="movilexamplex" unmountOnExit>
                                        <Card  style={{backgroundColor:'transparent',margin:0, border:'none'}}>
                                            <div style={{margin:30}}>
                                                <Row style={{justifyContent:'center'}}>
                                                    <img onClick={this.chan.bind(this,'2')} className="movilcarimgfies" style={{margin:0, height:250, width:250}} src={this.props.servurlassets+"secc_fiestas_img2a.png"} alt="alternate text"/> 
                                                </Row>
                                                <Row style={{justifyContent:'center', marginTop:20}}>
                                                    <div style={{backgroundColor:'#FAC809',color:'red', width:250,textAlign:'center'}}>
                                                        <Label className="moviltextlab">Fiesta Boliche</Label>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Card>    
                                    </CSSTransition>
                                    <CSSTransition in={this.state.info} timeout={300} classNames="movilexamplex" unmountOnExit>
                                        <Card  style={{backgroundColor:'transparent',margin:0, border:'none'}}>
                                            <div  style={{margin:30}}>
                                                <Row style={{justifyContent:'center'}}>
                                                    <img onClick={this.chan.bind(this,'3')} className="movilcarimgfies" style={{margin:0, height:250, width:250}} src={this.props.servurlassets+"secc_fiestas_img3a.png"} alt="alternate text"/> 
                                                </Row>
                                                <Row style={{justifyContent:'center', marginTop:20}}>
                                                    <div style={{backgroundColor:'#eb00ff',color:'white', width:250,textAlign:'center'}}>
                                                        <Label className="moviltextlab">Fiesta Kids</Label>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Card>    
                                    </CSSTransition>
                                    <CSSTransition in={this.state.info} timeout={300} classNames="movilexamplex" unmountOnExit>
                                        <Card  style={{backgroundColor:'transparent',margin:0, border:'none'}}>
                                            <div  style={{margin:30}}>
                                                <Row style={{justifyContent:'center'}}>
                                                    <img onClick={this.chan.bind(this,'4')} className="movilcarimgfies" style={{margin:0, height:250, width:250}} src={this.props.servurlassets+"secc_fiestas_img4a.png"} alt="alternate text"/> 
                                                </Row>
                                                <Row style={{justifyContent:'center', marginTop:20}}>
                                                    <div style={{backgroundColor:'#a5eaff',color:'red', width:250,textAlign:'center'}}>
                                                        <Label className="moviltextlab">Fiesta Pista de Hielo</Label>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Card>    
                                    </CSSTransition>
                            </Carousel>
                        </div>
                        <CSSTransition in={this.state.infodet} timeout={300} classNames="movilexamplex" unmountOnExit>
                            <div>   
                                <Row>
                                    <Col sm={4} style={{textAlign:'right'}}>
                                        <Row style={{justifyContent:'center'}}>
                                            <img className="movilcarimgfies" style={{margin:0, height:300, width:300}} src={this.props.servurlassets+"secc_fiestas_img"+this.state.swimg+"b.png"} alt="alternate text"/> 
                                        </Row>
                                        <Row style={{justifyContent:'center'}}>
                                            <div style={{backgroundColor:this.state.swimg==='1'?'#1f9a0f':this.state.swimg==='2'?'#FAC809':this.state.swimg==='3'?'#eb00ff':'#a5eaff', width:250,textAlign:'center'}}>
                                                <Label className="moviltextlab" style={{color:this.state.swimg==='1'?'white':this.state.swimg==='2'?'red':this.state.swimg==='3'?'white':'red'}}>{this.state.swimg==='1'?'Fiesta Games':this.state.swimg==='2'?'Fiesta Boliche':this.state.swimg==='3'?'Fiesta Kids':'Fiesta Pista de Hielo'}</Label>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col sm={6} style={{textAlign:'left'}}><br/>
                                      <img  className="movilcarimgfies" style={{margin:0, height:"auto", width:'100%'}} src={this.state.swimg==='1'?this.props.servurlassets + "fiestas_games_m.png":this.state.swimg==='2'?this.props.servurlassets + "fiestas_boliche_m.png":this.state.swimg==='3'?this.props.servurlassets + "fiestas_kids_m.png":this.state.swimg==='4'?this.props.servurlassets + "fiestas_ice_m.png":""} alt="alternate text"/>
                                    </Col>
                                </Row><br/>
                                <div style={{width:150, textAlign:'center'}}>
                                    <button onClick={this.kwan.bind(this)} className="movilmyButton">Regresar</button>
                                </div>
                            </div>
                        </CSSTransition>
                        <ModalFiestas switchModal={this.switchModal} modal={this.state.modal} servurlassets={this.props.servurlassets}/>
                    </div>   
                </div>   
            </div>
        )
    }

}
export default Secc_Fiestas