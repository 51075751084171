import React, { Component } from 'react';
import { Input,  Modal, ModalHeader, ModalBody} from 'reactstrap';

import './desktop.css';


class ModalTYC extends Component{

    constructor() {
        super();

        this.state ={
        tyc:'',
        desc:''
        }
    }

    getTYC = ()=>{
        let data ={
            codigo : this.props.codigo
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getTYC.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
                if(respuesta !==null){
                  this.setState({desc:respuesta[0].descripcion});
                  this.setState({tyc:respuesta[0].tyc});
            }else{
                this.setState({tyc:''});
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
        this.getTYC();
    }
 
    render(){
        
        return(
            <div>
                <Modal id="ModalTYC" isOpen={true} toggle={this.props.cierraTYC} size="lg" >
                    <ModalHeader toggle={this.props.cierraTYC} style={{color:'#D5060F', backgroundColor:'#F7DC6F'}}><b>Terminos y condiciones</b></ModalHeader>
                    <ModalBody >
                        <h2 style={{textAlign:'center', color:'black'}}>{this.state.desc}</h2>
                        <br/>
                        <p>{this.state.tyc}</p>
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default ModalTYC