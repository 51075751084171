import React, { Component } from 'react';
import './cssMovil.css';
import "react-multi-carousel/lib/styles.css";

class Secc_ClubTato extends Component{
    render(){
        return(
             <div className="container text-center color_bckg">
                    <div className="row">
                        <div className="col-md-12">
                             <img  src={this.props.servurlassets+'club.png'} />
                         </div>
                         <div className="col-md-12">
                             <img  src={this.props.servurlassets+'op86.png'} />
                         </div>
                         <div className="col-md-12">
                            <div className="ag_title_club">
                              <h1>Conoce Club Tato</h1>
                            </div> 
                            <div className="ag_text_club">
                                <p>Es un programa de lealtad donde consentimos a nuestros
                                miembros con promociones especiales y beneficios únicos.
                                <br/>                           
                                Además, al formar parte de Club Tato podrás administrar
                                tus tarjetas Recorcard desde cualquier dispositivo móvil
                                con internet.
                                </p>
                                <a href="#" className="ee_myButton" onClick={this.props.cursecc.bind(this, 'iniciar_club')}>Iniciar</a>
                            </div>
                         </div>
                         <div className="col-md-12">
                          <br/>
                         </div>
                    </div>
                </div>
        )
    }

}
export default Secc_ClubTato