import React, { Component } from 'react';
import {  Row, Col,Input} from 'reactstrap';

class Secc_Factura extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            preguntasLs:[],
            terminos:"",
            modal:false,
        };
    }
     

    componentDidMount=()=>{

    }

    render(){
        return (
            <div>
                <div  className="rfactura">  
                    <div className="rfactura_royal" >
                        <div  className="rfactura_title">
                            <h2>Genera tu Factura</h2>
                        </div>
                    
                            <iframe src={'https://csticket.mx/recrefam/'} style={{height:"640px", width:"100%"}}/>
                        
                    </div>
                </div>
            </div>
        ) 
    }

}
export default Secc_Factura