import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Secc_About_us extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };
        return(
            <div>
                <div  className="movilnosotros">  
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Nosotros</h2>
                        </div>
                    </div>
                    <div className="movilroyalN" >
                        <Row style={{paddingLeft:'0%', width:'100%'}}  >
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <div style={{color:"white"}}>
                                    <p>
                                        <b>¡Recórcholis!</b> abre por primera vez sus puertas en febrero de 1989 en la ciudad de Toluca, 
                                        Estado de México y desde entonces sigue trabajando para cumplir su único objetivo: 
                                        Hacer feliz a la gente.
                                    </p>
                                    <p>
                                        <b>¡Recórcholis!</b> toma el tradicional concepto de arcades y lo transforma en centros de entretenimiento 
                                        familiar llenos de luz y diversión con espacios creados especialmente para que cada integrante de la 
                                        familia disfrute al máximo.
                                    </p>
                                    <p>Con 55 sucursales en 20 estados de la República Mexicana y más de 50,000 metros cuadrados de diversión sin 
                                        duda Recórcholis se ha posicionado como la cadena de Centros de Entretenimiento Familiar más importante 
                                        en el país llevando diversión a millones de mexicanos.
                                    </p>
                                    <br/>
                                </div>
                            </Col>
                            <Col sm={5} style={{textAlign:'center'}}>
                                <div style={{position:'relative'}}>
                                    <YouTube videoId="fmqZZkrvB9c" opts={opts} onReady={this._onReady} />  
                                </div> <br/>
                              </Col>
                              <Col sm={1}></Col>
                        </Row>
                        <Row style={{paddingLeft:'0%', width:'80%', textAlign:'center'}}  >
                            <hr/>
                        </Row>
                        <Row style={{paddingLeft:'0%', width:'100%', textAlign:'center'}}  >
                            <Col sm={1}></Col>
                            <Col sm={3}><h2>Nuestra misión:</h2>
                                <h5>Hacer feliz a la gente</h5>
                            </Col>
                            <Col sm={4}><h2>Nuestra visión:</h2>
                                <h5>
                                    Ser la marca líder para Latinoamérica<br/>
                                    en la Industria del Entretenimiento Familiar.
                                </h5>
                            </Col>
                            <Col sm={3}><h2>Nuestros valores:</h2><h5>CHISPA <br/>(Constancia, Honestidad, Innovación, Servicio, Pasión y Alegría)<br/><br/><br/></h5></Col>
                            <Col sm={1}></Col>
                        </Row>
                        <Row style={{paddingLeft:'0%', width:'80%', textAlign:'center'}}  >
                            <hr/>
                        </Row>
                        <Row style={{paddingLeft:'0%', width:'100%', textAlign:'center'}}  >
                            <Col><h3>Nuestros socios comerciales<br/><br/></h3></Col>
                        </Row>
                        <div style={{width:'100%'}} >
                            <Carousel
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                renderDotsOutside={false}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                >
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"sant.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"bar.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"coc.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"nes.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"her.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"hei.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"icee.png"} alt="alternate text"/> </Col>
                                </Row>
                                <Row style={{paddingLeft:'5%', width:'100%', textAlign:'center'}}  >
                                    <Col ><img className="moviliconosotros" src={this.props.servurlassets+"teq.png"} alt="alternate text"/> </Col>
                                </Row>
                            </Carousel>
                        </div>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_About_us