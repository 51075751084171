import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import {Input,Row} from 'reactstrap';
import Mapa from './secc_mapa';

class Secc_5 extends Component{
    constructor() {
        super();

        this.state ={
            sucursalesLs:[],
            lat:0,
            long:0,
            sucursal:"",
            juegos:false,
            fiestas:false,
            kids:false,
            boliche:false,
            snacks:false,
            pista_hielo:false,
            telefono:"",
            correo:"",
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 5,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                    this.setState({ objetosLs });
               // alert('Gracias por enviarnos sus comentarios.');
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    selectSucursal = ()=>{
        this.setState({ lat:0});
        this.setState({ long:0});
        var valor=document.getElementById("sucursalSelect").value;
        var cordenadas=valor.split(",");
        this.setState({ lat:parseFloat(cordenadas[0]) });
        this.setState({ long:parseFloat(cordenadas[1]) });

        this.setState({ juegos:cordenadas[2] });
        this.setState({ fiestas:cordenadas[3] });
        this.setState({ kids:cordenadas[4] });
        this.setState({ boliche:cordenadas[5] });
        this.setState({ snacks:cordenadas[6] });
        this.setState({ pista_hielo:cordenadas[7] });
        this.setState({ telefono:cordenadas[8] });
        this.setState({ correo:cordenadas[9] });
    }

    getSucursales = ()=>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSucursales.php', requestInfo)
        .then(response => response.json())
        .then(sucursalesLs => {
            if(sucursalesLs !==null){
                this.setState({ sucursalesLs });
            }else{
                this.setState({ sucursalesLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    componentDidMount(){
       this.getSucursales();
    } 

    render(){
        return(
            <div>
                <div className="movilsection-5" id='sucursales'>  
                    <div className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2>
                                Sucursales <br/>
                            </h2>
                        </div>
                    </div>
                    <div className="movilhide-at-xs hide-at-sm main-container-section5" style={{height:'100%'}}>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'baseline', justifyContent:'flex-start', width:'100%' , height:'100%'}} className="movilsub-container-section5">
                            <div style={{width:'100%', height:'100%',flex: '1 1 auto',position:'relative'}}>
                                <div className="movilimg-backgrounds5">
                                    <img  src={this.props.servurlassets+"secc5_back.png"} alt="alternate text"/> 
                                </div>
                                <div style={{display:'flex', justifyContent:'flex-start', alignItems:'flex-start', height:'100%'}}>
                                    <div style={{width:'100%',paddingTop:"5%"}}>
                                        <Input  type="select" id='sucursalSelect' onChange={this.selectSucursal.bind()}>
                                            <option key={0}>
                                                Seleccione una Sucursal
                                            </option>
                                            {this.state.sucursalesLs.length>0? this.state.sucursalesLs.map((sucursal,index) =>(   
                                                <option key={index} value={sucursal.latitud
                                                                    +","+sucursal.longitud
                                                                    +","+sucursal.juegos
                                                                    +","+sucursal.fiestas
                                                                    +","+sucursal.kids
                                                                    +","+sucursal.boliche
                                                                    +","+sucursal.snacks
                                                                    +","+sucursal.pista_hielo
                                                                    +","+sucursal.telefono
                                                                    +","+sucursal.correo} 
                                                    disabled={sucursal.sucursal===''?true:false}
                                                     style={{color:"black",
                                                     fontWeight:sucursal.sucursal===''?"bold":''}}>
                                                    {sucursal.estado}
                                                    {sucursal.sucursal}
                                                </option>
                                            )):[]}    
                                        </Input><br/>
                                        {this.state.lat>0?
                                            <div>
                                                <Row style={{color:'white',fontSize:'12px', margin:0,fontWeight:'normal'}}>
                                                    {this.state.juegos ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_games.png"} alt="Alternate Text" />:''
                                                    }
                                                    {this.state.boliche ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Boliche.png"} alt="Alternate Text" />:''
                                                    }
                                                    {this.state.snacks ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Snacks.png"} alt="Alternate Text" />:''
                                                    }
                                                    {this.state.fiestas ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_fiestas.png"} alt="Alternate Text" />:''
                                                    }
                                                    {this.state.kids ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_Kids.png"} alt="Alternate Text" />:''
                                                    }
                                                    {this.state.pista_hielo ==='true'?
                                                    <img style={{textAlign:'left',height:'65px', width:'auto'}} src={this.props.servurlassets+"suc_PistaHielo.png"} alt="Alternate Text" />:''
                                                    }
                                                    <Mapa  lat={this.state.lat} long={this.state.long}/>
                                                </Row>
                                                <Row style={{color:'white',paddingLeft:"30%",textAlign:"center",fontSize:'12px'}}>
                                                    {this.state.telefono}
                                                    <br/>
                                                    {this.state.correo}
                                                </Row>
                                            </div>
                                            :
                                            <div style={{ height:'80%',margin:0, justifyContent:'center', alignItems:'center', display:'flex'}}>
                                                <div className="sec5_tatodiv">
                                                    <img className="movilsec5_tato" src={this.props.servurlassets+"secc5_img1.png"} alt="Alternate Text" />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>
            </div>)
    }
}
export default Secc_5
