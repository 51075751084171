import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Games extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '240px',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="movilatractions">  
                    <div style={{justifyContent:"center"}}>
                        <Row style={{paddingLeft:'2%',paddingTop:"5%", width:'90%'}}>
                            <Col sm={4}>
                                <div className="moviltitlesa">
                                    <h1>Games</h1>
                                </div>
                                <div className="movilsubtitlesa">
                                    <h2>
                                        Lo mejor en juegos
                                        de video, realidad
                                        virtual y destreza.
                                    </h2>
                                </div>
                                <div className="moviltextsa">
                                    <p className="movilhide-at-sm hide-at-xs">
                                        Es un área dirigida a todas las edades
                                        donde se encuentra una gran variedad
                                        de atracciones novedosas, como: Juegos de redención,
                                        simuladores, juegos deportivos, juegos de destreza,
                                        juegos mecánicos y muchos más. <br/><br/>
                                        La diversión será aún mayor cuando descubras que puedes
                                        canjear los tickets que dan la gran mayoría de estos
                                        juegos por increíbles premios. <br/><br/>
                                    </p>
                                </div>
                                <a onClick={this.props.cursecc.bind(this,"recargas")} className="movilmyButton">Comprar ahora</a>
                            </Col>
                            <Col sm={8} style={{textAlign:'center',paddingTop:"10%"}}>
                                <div style={{position:'relative'}}>
                                    <YouTube videoId="3lhEBjwYC6k" opts={opts} onReady={this._onReady} />  
                                </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Games