import React, { Component } from 'react';
import {Modal,Row,Col,Input, ModalHeader, Button,ModalBody} from 'reactstrap';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import Swal from 'sweetalert2';

class ModalFiestas extends Component{
    
    enviar=()=>{
        let data = {
            cuerpo:"Correo "+document.getElementById("correo").value+
            "Nombre: "+document.getElementById("nombre").value
            +"<br/>Telefono:"+document.getElementById("telefono").value
            +"<br/>Invitados:"+document.getElementById("invitados").value
            +"<br/>Games:"+document.getElementById("games").value
            +"<br/>Boliche:"+document.getElementById("boliche").value
            +"<br/>kids:"+document.getElementById("kids").value
            +"<br/>Pista de Hielo:"+document.getElementById("ph").value,
            email:"aosorio@grupodiniz.com.mx",
            asunto:"contacto",
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(URL_CORESERVICE + '/sendCorreoContacto.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            alert("Solicitud enviada");
            this.props.switchModal();
        })
        .catch(e=>console.log(e)); 
        Swal.fire(
            'Enviado',
            'Se han enviado tus datos',
            'success'
        )
    }
 
    render(){
        return(
            <div>
                <Modal id="ModalFiestas" isOpen={this.props.modal} toggle={this.props.switchModal} size="xl" >
                    <ModalHeader toggle={this.props.switchModal} style={{color:'#D5060F'}}><b>¡Reserva tu fiesta con nosotros!</b></ModalHeader>
                    <ModalBody>
                        <Row>
                            <p>Si tienes dudas, da click aquí </p>
                            <br/><p>contacto@recorcholis.com.mx</p>
                        </Row>
                        <Row>
                            <Col><b>Nombre Completo</b></Col>
                        </Row>
                        <Row>
                            <Col><Input id='nombre'></Input></Col>
                        </Row><br/>
                        <Row>
                            <Col><b>Teléfono</b></Col>
                        </Row>
                        <Row>
                            <Col><Input id='telefono'></Input></Col>
                        </Row><br/>
                        <Row>
                            <Col><b>Correo electrónico</b></Col>
                        </Row>
                        <Row>
                            <Col><Input id='correo'></Input></Col>
                        </Row><br/>
                        <Row>
                            <Col><b>Numero de invitados</b></Col>
                        </Row>
                        <Row>
                            <Col><Input id='invitados'></Input></Col>
                        </Row><br/>
                        <Row>
                            <Col><Input type='checkbox' id='games'></Input><b>Games</b></Col>
                            <Col><Input type='checkbox' id='boliche'></Input><b>Boliche</b></Col>
                        </Row><br/>
                        <Row>
                            <Col><Input type='checkbox' id='kids'></Input><b>Kids</b></Col>
                            <Col><Input type='checkbox' id='ph'></Input><b>Pista de hielo</b></Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Button className='btn-recorcholis' onClick={this.enviar.bind()}>Enviar</Button>
                            </Col>
                        </Row>
                        
                    </ModalBody>
                </Modal>    
            </div>
        );
    }    
}

export default ModalFiestas