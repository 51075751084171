import React, { Component } from 'react';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import {  Row, Col,Input,Label, Button} from 'reactstrap';
import Swal from 'sweetalert2';

class Secc_Contacto extends Component{
    
    enviar=()=>{
        let data = {
            cuerpo:"CORREO "+document.getElementById("correo").value+"NOMBRE: "+document.getElementById("nombre").value+" <br/>TELEFONO:"+document.getElementById("telefono").value+" <br/>Comentarios:"+document.getElementById("comentarios").value,
            email:"contacto@grupodiniz.com.mx",
            asunto:"contacto",
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(URL_CORESERVICE + '/sendCorreoContacto.php', requestInfo)
        .then(response => response.json())
        .catch(e=>console.log(e)); 
        Swal.fire(
            'Enviado',
            'Se han enviado tus datos',
            'success'
        )
    }
    
    render(){
        return(
            <div>
                <div  className="movilcontacto">  
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Contacto</h2>
                        </div>
                    </div>
                    <div className="movilroyalg" style={{paddingTop:'15%',paddingLeft:"10%" , width:'100%'}} >
                        <Row>
                            <Col sm={10} xs={10}><Label><b>Nombre Completo *</b></Label></Col>
                            <Col sm={10} xs={10}><Input id='nombre'/></Col>
                            <br/><br/><br/>
                            <Col sm={10} xs={10}><Label><b>Telefono *</b></Label></Col>
                            <Col sm={10} xs={10}><Input id='telefono'/></Col>
                            <br/><br/><br/>
                            <Col sm={10} xs={10}><Label><b>correo *</b></Label></Col>
                            <Col sm={10} xs={10}><Input id='correo'/></Col>
                            <br/><br/><br/>
                            <Col sm={10} xs={10}><Label><b>Comentarios *</b></Label></Col>
                            <Col sm={10} xs={10}><Input id='comentarios' type='textarea'></Input></Col>
                        </Row>
                        <Row>
                            <Col sm={12}><Label><b>* Campos Obligatorios</b></Label></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12}>
                                <Input type='radio'/> <Label><b>Acepto recibir información sobre novedades y promociones de ¡Recórcholis!</b></Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}></Col>
                            <Col sm={4}>
                                <Button onClick={this.enviar.bind()}>Enviar</Button>
                            </Col>
                            <Col sm={4}></Col>
                        </Row>
                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={4} style={{textAlign:"center"}}>
                                <p>
                                   {/* <a href='https://wa.me/+?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales'
                                        className="a-link" style={{ display: 'contents' }}>
                                        <img src={URL_ASSETS+'/imagenes-r/wacon.png'} alt="Alternate Text" style={{ height: '20px' }} /></a>
                                     */}
                                    contacto@recorcholis.com.mx
                                </p>
                            </Col>
                            <Col sm={4}></Col>
                        </Row>
                                
                                
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Contacto