import React, { Component } from 'react';
import { Card, Row, Col, Label} from 'reactstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Secc_Canjes extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }

   
    getObjetos = ()=>{
        let data ={
            carruselid : 6,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 
     
     
    render(){
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
          };

        return(
            <div  className={"movilsection-1can"}>  
                <div className="moviltwo-tone-backgroundcan" >
                    <div className="moviltone-1can" />
                </div>
                <div style={{  height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                    <div style={{width:'90%', border:'none'}}>
                        <Row>
                            <Col sm={4}>
                                <div style={{color:'white', position:'absolute', width:400, top:"5%"}}>
                                    <img style={{ height:200, top:0, left:'25%'}} src={this.props.servurlassets+'secc_canjes_img_mensaje.png'} alt="canjestato"/>
                                </div>
                            </Col>
                            <Col><br/><br/><br/><br/><br/>
                                <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={this.props.deviceType !== "mobile"? true : false}
                                    autoPlaySpeed={1000}
                                    keyBoardControl={true}
                                    customTransition="all 1s linear"
                                    transitionDuration={500}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    arrows={true}
                                    deviceType={this.props.deviceType}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-20-px">
                                    {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>{   
                                        return(
                                            <Card  key={index} style={{backgroundColor:'transparent',margin:0,border:'none', paddingTop:"50px"}}>
                                                {<div >
                                                    <img width='85%' src={this.props.servurlassets+objetos.nombre+objetos.extension} alt={'img'+objetos.index}/>
                                                    <br/>
                                                    <Label style={{color:'yellow', fontSize:25}}>¡Aprovecha! Colección especial</Label> <br/>
                                                    <Label style={{color:'white', fontSize:20}}>Búscalos en tu ¡Recorcholis! favorito. Existencias limitadas.</Label>
                                                    <div style={{color:'#1F56FF'}}>asd</div>
                                                </div>} 
                                            </Card>
                                            );
                                        }):[]
                                    }
                                </Carousel>
                                <img style={{position:'absolute', height:"15%", top:"35%", left:'15%'}} src={this.props.servurlassets+'secc_canjes_img_tato.png'} alt="canjestato"/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>   
        )
    }

}
export default Secc_Canjes