import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Pabellones extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '240',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="movilatractionsP">  
                   <div className="movilroyalg">
                        <Row style={{paddingTop:'5%',paddingLeft:'2%', width:'90%'}}>
                            <Col sm={4}>
                                <div className="moviltitlesa">
                                    <h1>Pabellones</h1>
                                </div>
                                <div className="movilsubtitlesa">
                                    <h2>
                                    Diversión exprés
                                    </h2>
                                </div>
                                <div className="moviltextsa">
                                    <p className="movilhide-at-sm hide-at-xs">
                                        Conoce la forma más divertida de pasear en un Centro comercial. 
                                        Nuestros pabellones son una zona especialmente diseñada para los niños 
                                        y se encuentran en los pasillos de los centros comerciales.
                                        <br/><br/>
                                        Aquí los pequeños podrán andar en nuestros Zoo riders, Kars o divertirse 
                                        en nuestros increíbles juegos.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={8} style={{textAlign:'center',paddingTop:"10%"}}>
                            <div style={{position:'relative'}}>
                                <YouTube videoId="FsEb4wiG8_k" opts={opts} onReady={this._onReady} />  
                            </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Pabellones