import React, { Component } from 'react';
import './desktop.css';
import { CSSTransition } from 'react-transition-group';
import {  Row, Col, Label} from 'reactstrap';
import ModalReserva from './modalReserva';


class Secc_Fiestas extends Component{
    constructor() {
        super();

        this.state ={
            swimg:'',
            info:false,
            infodet:false,
            swreserva:false
        };
    }


    chan = (fiestaindex)=>{
        this.setState({swimg:fiestaindex});
        this.setState({info:false});
        this.setState({infodet:true});
    }

   kwan = ()=>{
    this.setState({infodet:false});
    this.setState({info:true});
    
   }

   showReserva = ()=>{
        this.setState({swreserva:true});
   }

   cierraReserva = ()=>{
    this.setState({swreserva:false});
}
  
    
    componentDidMount(){
        this.setState({info:true});
     } 
     
     
    render(){

       
        return(
            <div>
                <div  className="fiestas" style={{backgroundImage:"url('"+this.props.servurlassets+"secc_fiestas_back.png')"}}>  
                    {/* Inicio del contenido de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div className="fies_royalg" >
                        <div style={{  margin:0, position:'absolute', left:'10%', top:'10%'}} >
                                <img className="fies_tatis" src={this.props.servurlassets+"secc_fiestas_img5.png"} alt="alternate text"/> 
                        </div>
                        <h1 className="font-pop" style={{color:'white', fontSize:50}}>
                            Festeja tu cumpleaños
                        </h1>
                        <div style={{  marginLeft:'50%', position:'absolute',textAlign:'center' ,top:'20%'}}>
                        <a onClick={this.showReserva.bind(this)} className="fies_myButton">Reserva aquí</a>
                        </div>
                        
                       <CSSTransition in={this.state.info} timeout={300} classNames="examplex" unmountOnExit>
                            <div style={{ position:'absolute', top:'30%',width:'100%', display:'flex', justifyContent:'center'}}>
                                <div style={{margin:30}} >
                                    <Row style={{justifyContent:'center'}}>
                                        <img onClick={this.chan.bind(this,'1')} className="fies_carimgfies" src={this.props.servurlassets+"secc_fiestas_img1a.png"} alt="alternate text"/> 
                                    </Row>
                                    <Row style={{justifyContent:'center', marginTop:20}}>
                                        <div className="fies_etiq" onClick={this.chan.bind(this,'1')} style={{backgroundColor:'#1f9a0f',color:'white', textAlign:'center'}}>
                                            <Label className="fies_textlab">Fiesta Games</Label>
                                        </div>
                                    </Row>
                                </div>
                                <div style={{margin:30}}>
                                    <Row style={{justifyContent:'center'}}>
                                        <img onClick={this.chan.bind(this,'2')} className="fies_carimgfies" src={this.props.servurlassets+"secc_fiestas_img2a.png"} alt="alternate text"/> 
                                    </Row>
                                    <Row style={{justifyContent:'center', marginTop:20}}>
                                        <div className="fies_etiq" onClick={this.chan.bind(this,'2')} style={{backgroundColor:'#FAC809',color:'red', textAlign:'center'}}>
                                            <Label className="fies_textlab">Fiesta Boliche</Label>
                                        </div>
                                    </Row>
                                </div>
                                <div  style={{margin:30}}>
                                    <Row style={{justifyContent:'center'}}>
                                        <img onClick={this.chan.bind(this,'3')} className="fies_carimgfies" src={this.props.servurlassets+"secc_fiestas_img3a.png"} alt="alternate text"/> 
                                    </Row>
                                    <Row style={{justifyContent:'center', marginTop:20}}>
                                        <div className="fies_etiq" onClick={this.chan.bind(this,'3')} style={{backgroundColor:'#eb00ff',color:'white', textAlign:'center'}}>
                                            <Label className="fies_textlab">Fiesta Kids</Label>
                                        </div>
                                    </Row>
                                </div>
                                <div  style={{margin:30}}>
                                    <Row style={{justifyContent:'center'}}>
                                        <img onClick={this.chan.bind(this,'4')} className="fies_carimgfies"  src={this.props.servurlassets+"secc_fiestas_img4a.png"} alt="alternate text"/> 
                                    </Row>
                                    <Row style={{justifyContent:'center', marginTop:20}}>
                                        <div className="fies_etiq" onClick={this.chan.bind(this,'4')} style={{backgroundColor:'#a5eaff',color:'red', textAlign:'center'}}>
                                            <Label className="fies_textlab">Fiesta Pista de Hielo</Label>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </CSSTransition>
                       
                        <CSSTransition in={this.state.infodet} timeout={300} classNames="examplex" unmountOnExit>
                            <div className="fies_detdiv">   
                                <Row>
                                    <Col sm={4} style={{textAlign:'right'}}>
                                        <Row style={{justifyContent:'center'}}>
                                            <img className="fies_carimgdet" src={this.props.servurlassets+"secc_fiestas_img"+this.state.swimg+"b.png"} alt="alternate text"/> 
                                        </Row>
                                        <Row style={{justifyContent:'center', marginTop:20}}>
                                            <div style={{backgroundColor:this.state.swimg==='1'?'#1f9a0f':this.state.swimg==='2'?'#FAC809':this.state.swimg==='3'?'#eb00ff':'#a5eaff', textAlign:'center'}}>
                                                <Label className="fies_textlabdet" style={{color:this.state.swimg==='1'?'white':this.state.swimg==='2'?'red':this.state.swimg==='3'?'white':'red'}}>{this.state.swimg==='1'?'Fiesta Games':this.state.swimg==='2'?'Fiesta Boliche':this.state.swimg==='3'?'Fiesta Kids':'Fiesta Pista de Hielo'}</Label>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col sm={6} style={{textAlign:'left'}}>
                                      <img  className="fies_carimgdet2"  src={this.state.swimg==='1'?this.props.servurlassets + "fiestas_games.png":this.state.swimg==='2'?this.props.servurlassets + "fiestas_boliche.png":this.state.swimg==='3'?this.props.servurlassets + "fiestas_kids.png":this.state.swimg==='4'?this.props.servurlassets + "fiestas_ice.png":""} alt="alternate text"/>
                                    </Col>
                                </Row>
                                <br/> <br/>
                                <div style={{width:150, textAlign:'center'}}>
                                <a onClick={this.kwan.bind(this)} className="fies_myButton">Regresar</a>
                                </div>
                            </div>
                           
                        </CSSTransition>
                        
                    </div>   
                    {
                        this.state.swreserva === true?
                            <ModalReserva cierraReserva={this.cierraReserva}/>:''
                        
                    }
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_Fiestas