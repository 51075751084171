import React, { Component } from 'react';
import './desktop.css';
import NumberFormat from 'react-number-format';
import { Input, Label, Button, FormGroup} from 'reactstrap';
import Swal from 'sweetalert2';

class Secc_recargas extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            tipo:'',
            response_card:false
        };
    }

    addRecarga = ()=>{

        let reco = '';
        let montox = this.state.tipo==='nueva' ? Number(this.props.costotarjeta) + Number(document.getElementById("txtMonto").value) : Number(document.getElementById("txtMonto").value);

        if(this.state.tipo==='preexistente'){
            reco=document.getElementById("txtRecorcard").value;
            this.consulta_detalle(reco);

            setTimeout(() => {
                if(this.state.response_card === false){
                    return Swal.fire('Error','Tarjeta Invalida','warning');
                }else {
                    let data ={
                        token : this.props.token,
                        codigo : 'SCC001',
                        monto : montox,
                        tipo : this.state.tipo,
                        recorcard : reco,
                        regalo_name:'',
                        regalo_email:''
                    }
                   this.props.agregaCarrito(data);
                }
            }, 1500);       
        }
        else{

            let data ={
                token : this.props.token,
                codigo : 'SCC001',
                monto : montox,
                tipo : this.state.tipo,
                recorcard : reco,
                regalo_name:'',
                regalo_email:''
            }
            
            this.props.agregaCarrito(data);
        }
        

    }

    valida_paquete = ()=>{
        let montox= Number(document.getElementById("txtMonto").value);
        let data ={
            montox : montox,
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getredireccion_promociones.php', requestInfo)
        .then(response => response.json())
        .then(redireccion_promociones => {
            if(redireccion_promociones !==null){
                Swal.fire({
                    title: 'Seleccionaste una recarga de '+montox,
                    text: 'Te redireccionaremos a un combo que te da mayores beneficios',
                    confirmButtonText: 'ÍR',
                  }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.cambiaSeccion("paquetes",redireccion_promociones,'');
                    }
                  })
            }
        })
        .catch(e=>console.log(e)); 
    }

    consulta_detalle = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({tipo:value});
    }

    componentDidMount=()=>{
        this.props.getSesionCtato(this.props.token);
    }
    
    render(){
        return(
            <div>
                <div  className="rsection-recargas">  

                    <div style={{  height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div style={{width:'90%'}}>
                            <div style={{margin:0, position:'absolute', display:'flex', left:'3%', top:'30%'}} >
                                <img className="recargaImg" src={this.props.servurlassets+"secc_recargas_img1.png"} alt="alternate text"/> 
                            </div>
                            <div  >
                                <div style={{margin:0, position:'relative', marginLeft:'10%', top:'25%'}}>
                                    <Label style={{fontSize:20, fontWeight:'bold'}}>Selecciona el monto de la recarga</Label>
                                </div>
                                <br/>
                                
                                <div style={{margin:0, position:'relative', marginLeft:'43%', top:'30%'}} >
                                <Input onChange={this.valida_paquete.bind()} id="txtMonto" bsSize='sm' type="select" style={{fontSize:20, width:"80%"}}>
                                        <option value="100">$100</option>
                                        <option value="150">$150</option>
                                        <option value="200">$200 </option>
                                        <option value="250">$250</option>
                                        <option value="300">$300 </option>
                                        <option value="350">$350</option>
                                        <option value="400">$400</option>
                                        <option value="450">$450</option>
                                        <option value="500">$500 </option>
                                        <option value="550">$550</option>
                                        <option value="600">$600</option>
                                        <option value="650">$650</option>
                                        <option value="700">$700</option>
                                        <option value="750">$750</option>
                                        <option value="800">$800</option>
                                        <option value="850">$850</option>
                                        <option value="900">$900</option>
                                        <option value="950">$950</option>
                                        <option value="1000">$1000</option>
                                    </Input>
                                        <br/>        
                                    <div style={{textAlign:"left"}}>
                                        <p>Monto Minimo $100</p>
                                        <p>Monto Maximo $1000</p>
                                    </div>
                                </div>
                                <br/>
                                
                                <FormGroup style={{margin:0, position:'relative', textAlign:'left', width:'300px',marginLeft:'43%', top:'30%'}} >
                                   
                                    <Label style={{fontSize:20}}>
                                        <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="preexistente" /> En tarjeta preexistente
                                    </Label>
                                    <br/>
                                    <Label style={{fontSize:20}} >
                                        <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="nueva" /> En tarjeta nueva ( + ${this.props.costotarjeta} )
                                    </Label>
                                    <br/>
                                    <Label style={{fontSize:20}} >
                                        <Input style={{fontSize:20}} defaultChecked onChange={this.handleChange} type="radio" name="radio1" value="cupon" /> En cupón digital
                                    </Label>
                                    <div style={{color:"red"}}>
                                        <p>(no aplica la promoción miercoles 2x1)</p>
                                    </div>
                                </FormGroup>
                                <br/>

                                {
                                    this.state.tipo==='preexistente'?
                                    <>
                                        <div style={{margin:0, position:'relative', display:'flex', marginLeft:'43%', top:'40%'}} >
                                        <Label style={{fontSize:20, fontWeight:'bold'}}>Tarjeta Recorcard (10 dígitos)</Label>
                                        </div>
                                        {
                                            this.props.cards.length>0?
                                            <div style={{margin:0, position:'relative', display:'flex', marginLeft:'43%', top:'45%'}} >
                                                <Input style={{width:280}} id="txtRecorcard" type="select"> 
                                                    { this.props.cards.map((card,index) => (
                                                        <option key={index} value={card.NUMBER}>{card.NUMBER}</option>
                                                    ))}
                                                </Input>
                                            </div>
                                            :
                                            <div style={{margin:0, position:'relative', display:'flex', marginLeft:'43%', top:'45%'}} >
                                                <NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:25, width:230}} />
                                            </div>
                                        }
                                        
                                    </>:''
                                }
                                 <br/> <br/>
                                 <div style={{margin:0, position:'relative', display:'flex', marginLeft:'43%', top:'70%'}} >
                                    <Button className="btn-recorcholis" onClick={this.addRecarga.bind(this)} >Agrega a carrito</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_recargas