import React, { Component } from 'react';
import './desktop.css';
import { Row, Col, Input, Label, Button } from 'reactstrap';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'
import Swal from 'sweetalert2';

class Secc_Contacto extends Component {
    constructor() {
        super();

        this.state = {
            objetosLs: [],
        };
    }

    enviar=()=>{
        let data = {
            cuerpo:"Nombre: "+document.getElementById("txtNombre").value+" <br/>Teléfono:"+document.getElementById("txtTelefono").value+" <br/>Invitados:"+document.getElementById("txtCantidad").value+" <br/>Email:"+document.getElementById("txtEmail").value,
            email:"contacto@grupodiniz.com.mx",
            asunto:"- Eventos Sociales",
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.props.servurlbase+'/sendCorreoContacto.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                Swal.fire(
                    'Enviado',
                    'Se han enviado tus datos',
                    'success'
                )
            })
            .catch(e => console.log(e));
            Swal.fire(
                'Enviado',
                'Se han enviado tus datos',
                'success'
            )
    }

    render() {
        return (
            <div>
                <div className="rcontacto">
                    <div className="rcontacto_title" >
                        <h1>¡Escribenos! te escuchamos</h1>
                    </div>
                    <div className="contacto_royal" >

                        <Row style={{ width: '100%' }} >
                            <Col sm={3} >
                                <div className="contacto_tatodiv">
                                    <img className="contacto_tato" src={this.props.servurlassets + "tato_con.png"} alt="" />
                                </div>
                            </Col>
                            <Col sm={6}>
                                <Row>
                                    <Col sm={6}><Label><b>Nombre Completo *</b></Label></Col>
                                </Row>
                                <Row>
                                    <Col sm={6}><Input type="text" id="txtNombre" bsSize="sm" /></Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm={6}><Label><b>Télefono *</b></Label></Col>
                                    <Col sm={6}><Label><b>Correo *</b></Label></Col>
                                </Row>
                                <Row>
                                    <Col sm={6}><Input type="text" id="txtTelefono" bsSize="sm" /></Col>
                                    <Col sm={6}><Input type="text" id="txtEmail" bsSize="sm" /></Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm={12}><Label><b>Comentario *</b></Label></Col>
                                </Row>
                                <Row>
                                    <Col sm={12}><Input type='textarea' bsSize="sm" id="txtComentario" /></Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm={12}><Label><b>* Campos Obligatorios</b></Label></Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm={12}>
                                        <Input type='radio' /> <Label><b>Acepto recibir información sobre novedades y promociones de ¡Recórcholis!</b></Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4}></Col>
                                    <Col sm={4}>
                                        <Button onClick={this.enviar.bind()}>Enviar</Button>
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                                <Row>
                                    <Col sm={3}></Col>
                                    <Col sm={4} style={{ textAlign: "center" }}>
                                        <p>
                                            {/*
                                                Si tienes dudas, da click aquí: 
                                                <a href='https://wa.me/+?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales'
                                                    className="a-link" style={{ display: 'contents' }}>
                                                    <img src={URL_ASSETS+'/imagenes-r/wacon.png'} alt="Alternate Text" style={{ height: '20px' }} /></a>
                                                <br />
                                            */}
                                            contacto@recorcholis.com.mx
                                        </p>
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <div className="contacto_tatisdiv">
                                    <img className="contacto_tatis" src={this.props.servurlassets + "tatis_con.png"} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>
            </div>
        )
    }

}
export default Secc_Contacto