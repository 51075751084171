import React, { Component } from 'react';
import {Row, Col, Button} from 'reactstrap';
import ModalBT from './modalBT';

class Secc_Bolsa_trab extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            modalBT:false
        };
    }

    switchModal = () =>{
        this.setState({modalBT : !this.state.modalBT});
    }
     
    render(){
        return(
            <div>
                <div className="movilbolsa">  
                    <div style={{backgroundColor:'#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Bolsa de Trabajo</h2>
                        </div>
                    </div>
                    <div >
                        <Row style={{paddingTop:'0%', width:'100%'}}  >
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <div className='movilfuente' style={{color:"black",paddingRight:"10%",paddingTop:"10%",paddingLeft:"10%",fontSize:"15px",fontFamily:" GothamRounded"}}>
                                    <p>
                                        Queremos que te unas al equipo más divertido.
                                    </p>
                                    <p>
                                        No solo te divertirás trabajando, aprenderás y adquirirás nuevas habilidades, conocerás gente nueva, 
                                        podrás crecer profesionalmente y lo más importante es que harás feliz a la gente!!!
                                    </p>
                                    <h3><b>¿Qué necesitas para unirte al equipo Recórcholis?</b></h3>
                                    <p>
                                        • Tener al menos 18 años.<br/>
                                        • Ser entusiasta, alegre, extrovertido, contar con una gran sonrisa, 
                                        tener pasión por la diversión y sobre todo que te guste atender a los invitados.
                                    </p>
                                    <h1 style={{textAlign:'center'}}>
                                        <b>#SoyRecorcholis</b>
                                    </h1>
                                    <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={6}><Button className='movilbtn-recorcholis' onClick={this.switchModal.bind()}>Solicitud</Button></Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={4} style={{textAlign:'left'}} >
                                <img className="moviltato" style={{width:"55%"}} src={"https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/op89n.png"} alt="alternate text"/>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>
                        <ModalBT modalBT={this.state.modalBT} servurlbase={this.props.servurlbase} switchModal={this.switchModal} />
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Bolsa_trab