import React, { Component } from 'react';
import './desktop.css';
import { Row, Col } from 'reactstrap';
import ModalBT from './modalBT';

class Secc_Bolsa_trab extends Component {
    constructor() {
        super();

        this.state = {
            objetosLs: [],
            modalBT: false
        };
    }


    modalBT = () => {
    }

    switchModal = () => {
        this.setState({ modalBT: !this.state.modalBT });
    }


    render() {
        return (
            <div>
                <div className="bolsa">
                    <div className="bolsa_royal" >
                        <Row style={{ paddingLeft: '0%', width: '100%' }}  >
                            <Col sm={1}></Col>
                            <Col sm={5}>
                                <div className='bolsa_text'>
                                    <h2>
                                        Queremos que te unas al equipo más divertido.
                                    </h2>

                                    <p>
                                        No solo te divertirás trabajando, aprenderás y adquirirás nuevas habilidades, conocerás gente nueva,
                                        podrás crecer profesionalmente y lo más importante es que harás feliz a la gente!!!
                                    </p>
                                    <br />
                                    <h3><b>¿Qué necesitas para unirte al equipo Recórcholis?</b></h3>
                                    <p>
                                        • Tener al menos 18 años.<br />
                                        • Ser entusiasta, alegre, extrovertido, contar con una gran sonrisa,
                                        tener pasión por la diversión y sobre todo que te guste atender a los invitados.
                                    </p>
                                    <br />
                                    <h2 style={{ textAlign: 'center' }}>
                                        <b>#SoyRecorcholis</b>
                                    </h2>
                                    <br />
                                    <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={6}>
                                            <a href="#" onClick={this.switchModal.bind()} className="ee_myButton"> Solicitud  </a>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={4} style={{ textAlign: 'left' }} >
                                <div className="bolsa_tatodiv">
                                    <img className="bolsa_tato" src={"https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/op89n.png"} alt="alternate text" />
                                </div>
                            </Col>
                            <Col sm={1}></Col>
                        </Row>

                        <ModalBT modalBT={this.state.modalBT} switchModal={this.switchModal} servurlbase={this.props.servurlbase}/>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>
            </div>
        )
    }

}
export default Secc_Bolsa_trab