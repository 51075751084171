import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";

class Secc_ClubTato extends Component{
    render(){
        
        return(

            <div className="container-fluid text-center color_bckg">
                    <div className="row">
                        <div className="col-md-6 div_height">
                            <br/>
                          <div className="padre" style={{width:'100%',height:'300px',textAlign:'left'}}>
                            <div className='hijo_top'>
                             <img style={{width:'95%'}} src={this.props.servurlassets+'club.png'} />
                            </div>
                          </div>
                          <div className="ag_title_club">
                                <h1>Conoce Club Tato</h1>
                          </div>
                          <div className='padre'>
                            <div className="ag_text_club hijo_top" style={{width:'70%'}}>
                                <p>Es un programa de lealtad donde consentimos a nuestros
                                miembros con promociones especiales y beneficios únicos.
                                <br/>                           
                                Además, al formar parte de Club Tato podrás administrar
                                tus tarjetas Recorcard desde cualquier dispositivo móvil
                                con internet.
                                </p>
                                <a href="#" className="ee_myButton" onClick={this.props.cambiaSeccion.bind(this, 'iniciar_club', '', '')}>Iniciar</a>
                            </div>
                          </div>
                         </div>
                         <div className="col-md-6 padre div_height">
                            <div className="col-md-6 hijo_center" style={{width:'100%'}} >
                             <img style={{width:'85%'}} src={this.props.servurlassets+'op86.png'} />
                            </div>
                         </div>
                    </div>
                </div>
        )
    }

}
export default Secc_ClubTato