import React, { Component } from 'react';
import './desktop.css';
import { Row, Col} from 'reactstrap';


class Secc_registro extends Component{

    constructor() {
        super();
        this.state ={
        };
    }
  
    render(){
        return(
            <div>
            <div className="sesion_main">  
                <div className="ag_royalg">
                    <Row style={{ width:'100%'}}>
                        <Col sm={3} className='bg-warning'>    
                        </Col>
                        <Col sm={6} style={{textAlign:'center'}}>
                            <img style={{ height:'250px'}}  src={this.props.servurlassets+"secc5_img1.png"} alt="Alternate Text" />
                            <h1 className='main__msg'>Estás por completar tu registro</h1>
                            <div className='control-label'>
                                <label >Corre a la bandeja de entrada de tu correo y abre el mail de validación que te acabamos de enviar.</label>
                            </div>
                            <div className="escribenos">
                            <h4>Si no te llegó el correo, escríbenos a atencion.clientes@grupodiniz.com.mx</h4>
                            </div>
                            <a className='click_aqui' onClick={this.props.cambiaSeccion.bind(this, 'iniciar_club', '', '')} href='#'><p>Iniciar sesión</p></a>
                        </Col>
                        <Col sm={3} className='bg-success'>
                        </Col>
                    </Row>
                </div>
            </div>   
        </div>
        )
    }
}
export default Secc_registro