import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Kids extends Component{

    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="atrackids">  
                   <div className="ak_royalg">
                        <Row style={{ width:'100%'}}>
                            <Col sm={4}>
                                <div className="ak_titlesa">
                                    <h1>KIDS</h1>
                                </div>
                                <div className="ak_subtitlesa">
                                    <h4>
                                    El lugar donde pueden jugar, correr, saltar, gritar y su imaginación es el límite.
                                    </h4>
                                </div>
                                <br/>
                                <div className="ak_textsa">
                                    <p>
                                Área infantil creada para niños pequeños, en la que podrán desenvolverse libremente y desarrollar sus habilidades sociales
                                    y psicomotrices mientras se divierten al máximo en un área que cuenta con los más altos estándares de seguridad.
                                    <br/><br/>
El acceso a esta área es de tiempo ilimitado. 

                                    </p>
                                </div>
                                <a href="#" class="ee_myButton" onClick={this.props.cambiaSeccion.bind(this,'recargas','','')}>Comprar ahora</a>
                            </Col>
                            <Col sm={8} style={{textAlign:'center', paddingTop:40}}>
                            <div style={{position:'relative'}}>
                                <YouTube videoId="SKlyBpwE9-g" opts={opts} onReady={this._onReady} />  
                            </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_Atrac_Kids