import React, { Component } from 'react';
import { Label, Input, Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';
import './desktop.css';
import { URL_CORESERVICE, URL_ASSETS, URL_SOCKETSERVICE, URL_WHATSAPP } from './../config'


class ModalReserva extends Component {

    constructor() {
        super();

        this.state = {

        }
    }

    enviar = () => {
        let data = {
            cuerpo: "NOMBRE: " + document.getElementById("txtReservaNombre").value + " <br/>TELEFONO:" + document.getElementById("txtReservaTelefono").value + " <br/>Tipo fiesta:" + document.getElementById("txtTipoFiesta").value + " <br/>Invitados:" + document.getElementById("txtReservaCantidad").value + " <br/>Email:" + document.getElementById("txtReservaEmail").value,
            email: "aosorio@grupodiniz.com.mx",
            asunto: "- Reservación de Fiesta",
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(URL_CORESERVICE + '/sendCorreoContacto.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            Swal.fire(
                'Enviado',
                'Se han enviado tus datos',
                'success'
            )
        })
        .catch(e => console.log(e));
        Swal.fire(
            'Enviado',
            'Se han enviado tus datos',
            'success'
        )
    }




    render() {

        return (
            <div>
                <Modal id="ModalPaquete" isOpen={true} toggle={this.props.cierraReserva} size="lg" >
                    <ModalHeader toggle={this.props.cierraReserva} style={{ color: '#D5060F', backgroundColor: '#F7DC6F' }}><b>Reserva tu fiesta</b></ModalHeader>
                    <ModalBody style={{ backgroundColor: 'white', color: 'black' }}>

                        <div  >
                            <Row>
                                <Label>¡Reserva tu fiesta con nosotros!</Label>
                            </Row>
                            <Row>
                            &nbsp;&nbsp;&nbsp;
                                <a href='https://wa.me/+525526979865?text=%C2%A1Hola!%20Solicito%20informaci%C3%B3n%20de%20eventos%20empresariales'
                                    className="a-link" style={{ display: 'contents' }}>
                                  Si tienes dudas da clic aquí: <img src={URL_ASSETS+'/imagenes-r/wacon.png'} alt="Alternate Text" style={{ height: '20px' }} />
                                </a>
                                <br />
                            </Row>
                            <Row>
                                <Label>contacto@recorcholis.com.mx</Label>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Label>Nombre completo</Label>
                                </Col>
                                <Col sm={6}>
                                    <Label>Teléfono</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Input type="text" bsSize='sm' id="txtReservaNombre" />
                                </Col>
                                <Col sm={6}>
                                    <Input type="text" bsSize='sm' id="txtReservaTelefono" />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={6}>
                                    <Label>Correo electrónico</Label>
                                </Col>
                                <Col sm={6}>
                                    <Label>Cantidad de invitados</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Input type="text" bsSize='sm' id="txtReservaEmail" />
                                </Col>
                                <Col sm={6}>
                                    <Input type="text" bsSize='sm' id="txtReservaCantidad" />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={6}>
                                    <Label>Tipo de fiesta</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Input type="select" bsSize='sm' id="txtTipoFiesta">
                                        <option key={0} value="Games">Games</option>
                                        <option key={1} value="Boliche">Boliche</option>
                                        <option key={2} value="Kids">kids</option>
                                        <option key={3} value="Pista de hielo">Pista de hielo</option>
                                    </Input>
                                </Col>
                            </Row>
                            <br /><br />
                            <Button onClick={this.enviar.bind(this)} size='sm' style={{ width: 100 }} color="success">
                                Enviar
                            </Button>
                        </div>
                    </ModalBody>


                </Modal>

            </div>
        );
    }
}

export default ModalReserva