import React, { Component } from 'react';
import {  Row, Col,Input} from 'reactstrap';
import ModalTerminos from './modalTerminos';

class Secc_Terminos extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[],
            preguntasLs:[],
            terminos:"",
            modal:false,
        };
    }
     
    getPreguntas = () =>{
        var seccion = document.getElementById("secciones").value;
        let data ={
            seccion : seccion
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getTerminosycondiciones.php', requestInfo)
        .then(response => response.json())
        .then(preguntasLs => {
            if(preguntasLs !==null){
                this.setState({ preguntasLs });
            }else{
                this.setState({ preguntasLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    getSecciones = () =>{
        const requestInfo={
            method:'POST',
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getSeccionesAgrupa.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    modalTerminos=(terminos)=>{
        this.setState({terminos});
        this.switchModal();
    }

    switchModal = () =>{
        this.setState({modal:!this.state.modal});
    }

    componentDidMount=()=>{
        this.getSecciones();
    }

    render(){
        return(
            <div>
                <div  className="movilPreguntas">  
                    <div style={{backgroundColor: '#FFFFFF'}} className="movilfont-pop">
                        <div className="moviltop-msg__container" style={{color:"#D60812"}}>
                            <h2 style={{fontSize:25, fontWeight:'bold'}}>Terminos y Condiciones</h2>
                        </div>
                    </div>
                    <div >
                        <Row style={{paddingLeft:'20%' ,width:'80%'}}>
                            <Col ><br/>
                                <Input type={"select"} id={"secciones"} style={{fontSize:25}} onClick={this.getPreguntas.bind()}>
                                    <option value={0}>Términos y Condiciones</option>
                                    {this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                        <option key={index} value={objetos.seccion}>{objetos.seccion}</option>
                                    )):[]}
                                </Input>
                            </Col>
                        </Row>
                        <Row style={{paddingTop:'10%',paddingLeft:"20%" ,width:'80%'}} className={"movilscroll"}>
                            {this.state.preguntasLs.length>0? this.state.preguntasLs.map((pregunta,index) =>(   
                                <div key={index} onClick={this.modalTerminos.bind(this,pregunta.tyc)} 
                                    style={{paddingTop:'2%',fontSize:"20px",borderBottom:"solid",paddingBottom:"5%"}}>
                                    <b>{pregunta.promocion}</b>
                                </div>
                            )):[]}
                        </Row>
                    </div>
                </div>   
                <ModalTerminos titulo={"Terminos y Condiciones"} modal={this.state.modal} switchModal={this.switchModal} terminos={this.state.terminos}/>
            </div>
        )
    }

}
export default Secc_Terminos