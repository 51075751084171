import React, { Component } from 'react';
import {  Row, Col} from 'reactstrap';

class Secc_politica_r extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }
     
    render(){
        return(
            <div>
                <div  className="aviso">  
                    {/* Inicio del conteni"do de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div className="aviso_royal"  >
                        <Row >
                            <Col className='aviso_scroll' >
                                <div >
                                    <b>Politica de Reembolsos y devoluciones</b>
                                    
                                    <p>
                                    La presente política de reembolsos y devoluciones regula la relación entre Usted 
                                    (en lo sucesivo el “Usuario”) y ¡Recórcholis! en relación con la compraventa de servicios
                                    por medio del sitio web recorcholis.com.mx. Al hacer uso de este servicio usted entiende, 
                                    está conforme y acepta expresa e irrevocablemente los mismos,
                                    así como las modificaciones o adiciones que sean aplicables y se encuentren vigentes
                                    al momento de utilizar los servicios que el sitio web de recorcholis.com.mx ofrece.
                                    </p>
                                    
                                    <p>
                                    El Usuario, expresamente reconoce y acepta que la solicitud de compra de las recargas, 
                                    paquetes, promociones o tarjetas de regalo en todas sus opciones y modalidades de compra
                                     que realiza a través del sitio web recorcholis.com.mx, es una oferta vinculante y que una vez 
                                    recibida la autorización del banco emisor de la tarjeta de crédito o débito correspondiente
                                    o del banco aceptante, la operación de compra será definitiva y no estará sujeta a cambios o 
                                    cancelaciones.
                                    </p>
                                    <b>¿Qué debo hacer en caso de solicitar un reembolso o devolución?</b>
                                    <p>
                                    En caso de solicitar una reembolso o devolución de la compra,
                                    El Usuario deberá solicitarlo al banco emisor de la tarjeta de crédito o débito que se haya utilizado
                                    al realizar la compra y se iniciará un proceso de “revisión de pago”, en el cual se tomarán en consideración
                                    las siguientes condiciones para realizar el reembolso o devolución:
                                    </p>
                                        <b>•No haber redimido el/los cupones electrónicos recibidos al realizar la compra.<br/></b>
                                        <b> •	Realizar el reclamo o solicitud de reembolso o devolución en un periodo máximo de 7 días.<br/></b>
                                        <b>  •	El periodo para percibir el reembolso o devolución, en caso de proceder, será de 5 a 10 días hábiles.<br/></b>

                                    
                                   
                                   
                                </div>
                            </Col>
                            <Col >
                                <div className="aviso_tatodiv">
                                    <img className="aviso_tatis" src={this.props.servurlassets+"tatis_con.png"}/>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_politica_r