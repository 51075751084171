import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';

class Secc_About_us extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

 
    componentDidMount(){
       
     } 
     
     
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="nosotros">  
                    {/* Inicio del conteni"do de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div className="nos_royal" >
                        <Row style={{ width:'100%'}}  >
                            
                            <Col sm={4}>
                                <div className='nos_text'>
                                    <p>
                                        <b>¡Recórcholis!</b> abre por primera vez sus puertas en febrero de 1989 en la ciudad de Toluca, 
                                        Estado de México y desde entonces sigue trabajando para cumplir su único objetivo: 
                                        Hacer feliz a la gente.
                                    </p>
                                    <br/>
                                    <p>
                                        <b>¡Recórcholis!</b> toma el tradicional concepto de arcades y lo transforma en centros de entretenimiento 
                                        familiar llenos de luz y diversión con espacios creados especialmente para que cada integrante de la 
                                        familia disfrute al máximo.
                                    </p>
                                    <br/>
                                    <p>Con 55 sucursales en 20 estados de la República Mexicana y más de 50,000 metros cuadrados de diversión sin 
                                        duda Recórcholis se ha posicionado como la cadena de Centros de Entretenimiento Familiar más importante 
                                        en el país llevando diversión a millones de mexicanos.
                                    </p>
                                    <br/>
                                </div>
                            </Col>
                            <Col sm={6} style={{textAlign:'center'}}>
                                <div style={{position:'relative'}}>
                                    <YouTube videoId="fmqZZkrvB9c" opts={opts} onReady={this._onReady} />  
                                </div> 
                              </Col>
                              <Col sm={1}></Col>
                        </Row>
                        <Row style={{width:'100%', textAlign:'center'}}  >
                            <Col><h4>Nuestros socios comerciales</h4></Col>
                        </Row>
                        <Row style={{paddingLeft:'0%', width:'100%', textAlign:'center'}}  >
                            <Col ></Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"sant.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"bar.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"coc.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"nes.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"her.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"hei.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"icee.png"} alt="alternate text"/> </Col>
                            <Col ><img className="iconosotros" src={this.props.servurlassets+"teq.png"} alt="alternate text"/> </Col>
                            <Col ></Col>
                        </Row>
                    </div>
                    {/* Fin del contenido de la seccion*/}
                </div>   
            </div>
        )
    }

}
export default Secc_About_us