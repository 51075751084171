import React, { Component } from 'react';

class Secc_compraWeb extends Component{
    constructor() {
        super();

        this.state = {
            cuponesLS: '',
            show: 'none',
            cupones:0
        };
    }
    getCompraWeb = ()=>{
        let data ={
            tokenCompra:this.props.tokenCompra,
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCupones.php', requestInfo)
        .then(response => response.json())
        .then(cuponesLS => {
            if(cuponesLS){
                this.setState({cuponesLS});
            }else{
                this.setState({cuponesLS:[]});
                this.setState({show:'block'}); 
            }

        })

        .catch(e=>console.log(e));
    }

    componentDidMount() { 
        this.getCompraWeb();
        this.props.remplazarToken();
    }
     
    render(){
        return(
            <div>
            {this.state.cuponesLS.length>0?
                <div style={{paddingTop:'100px',paddingBottom:'50px',textAlign:'center'}}>
                    <img src="https://grupodiniz.blob.core.windows.net/imagenes-r/op22.png" style={{width:'10%'}} alt="Alternate Text" />
                    <h3>¡Listo! Compra exitosa</h3>
                        <div>
                            <span>Toma captura de pantalla para cualquier aclaración.<br/></span>
                            <span>Toma captura de pantalla de tus cupones y preséntalo en nuestras taquillas para canjearlo o para cualquier aclaración.</span>
                            <br/>Además te lo enviaremos por correo electrónico.
                            {this.state.cuponesLS.length>0? this.state.cuponesLS.map((cupones,index) =>(   
                                <div  key={index} >
                                    <div  key={index} style={{display:cupones.codigoCupon!==''||cupones.codigoCupon!=='Recarga'?'block':'none'}}>
                                        <img alt='cupon' src={'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl='+cupones.codigoCupon+'&choe=UTF-8'}/>
                                        <h2>{cupones.codigoCupon}</h2>
                                    </div>
                                    <div  key={index} style={{display:cupones.codigoCupon===''||cupones.codigoCupon==='Recarga'?'block':'none'}}>
                                        <h3>Tu recarga de ${cupones.monto} a realizado <br/>RecordCard #{cupones.recorcard}</h3>
                                    </div>
                                </div>
                            )):[]}
                        </div>
                    <div>
                        <p>Recibirás por correo electrónico tu confirmación <br/>de compra.</p>
                    </div>
                </div>
            :
                <div style={{paddingTop:'100px',paddingBottom:'200px',textAlign:'center', display:this.state.show}}>
                    <div>
                        <div>
                        <img src="https://grupodiniz.blob.core.windows.net/imagenes-r/op22.png" style={{width:'10%'}} alt="Error de compra" />
                        </div>
                        <h3 >Lo sentimos, algo salió mal</h3>
                        <div>
                            <p>envíanos un correo electrónico a contacto@recorcholis.com.mx<br/></p>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }


}
export default Secc_compraWeb