import React, { Component } from 'react';
import Swal from 'sweetalert2';

class Secc_iniciar_club extends Component{
     
    enviarContra=()=>{
        let data ={
            correo : document.getElementById("correo").value
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'enviarContra.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if(response===0){
                Swal.fire('Error','Usuario no registrado','warning');
            }else{
                Swal.fire('Correo','Enviado correctamente','success');
            }
        })
        .catch(e=>console.log(e)); 
    }

    render(){
        return(
            <div  className={"movilsection-1can"}>  
              <div className="container text-center color_bckg"a style={{  height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                <div className="row">
                    <div className="col-12 _pading">
                        <h1 className="login__title hide-at-xs">Recuperar contraseña</h1>
                        <label className="control-label">Correo *</label>
                        <input type="text" id='correo' className="form-control" />
                        <a className="ee_myButton" onClick={this.enviarContra.bind()}>Enviar contraseña</a>
                        <a className='click_aqui' onClick={this.props.cursecc.bind(this, 'iniciar_club')} href='#'><p>Regresar</p></a>
                    </div>
                </div>
              </div>
            </div>
        )
    }


}
export default Secc_iniciar_club