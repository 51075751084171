import React, { Component } from 'react';
import {Label, Input, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter,Row} from 'reactstrap';
import NumberFormat from 'react-number-format';
import './desktop.css';
import ModalTYC from './modalTYC';
import Swal from 'sweetalert2';

class ModalPromocion extends Component{

    constructor() {
        super();
        this.state ={
        total :0,
        promocional :0,
        tipo:'',
        swTYC:false
        }
    }

    addRecarga2 = ()=>{
        let montox =0;
        let reco='';

        console.log(this.state.tipo);
        if(this.props.protipo ==='seleccionable'){
            montox= Number(document.getElementById("txtMonto").value);
        }else{
            montox = this.state.tipo==='nueva' ? Number(this.props.costotarjeta) + Number(this.props.valor) : Number(this.props.valor);
            reco=this.state.tipo==='preexistente'?document.getElementById("txtRecorcard").value:'';
        }

        if(montox<1){
            montox=Number(this.props.valor);
        }
        let data ={
            token : this.props.token,
            codigo : this.props.codigo,
            monto : montox,
            recorcard : reco,
            tipo:this.state.tipo,
            regalo_name:'',
            regalo_email:''
        }

        this.props.agregaCarrito(data);
        this.props.cierraPromocion();
    }

    addRecarga = ()=>{
        if(this.props.protipo ==='promocional'){
            if(this.props.codigoPromocional === document.getElementById("codigoPromocional").value){
                this.addRecarga2();
            }else{
                Swal.fire(
                    'Error',
                    'Codigo incorrecto',
                    'warning'
                )
            }
        }else{
            if(this.state.tipo==='preexistente'){
                let data ={
                    CGUID:document.getElementById("txtRecorcard").value
                }
                const requestInfo={
                    method:'POST',
                    body:JSON.stringify(data),
                    header: new Headers({
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json'
                    })
                };
                fetch(this.props.servurlbase+'GetCard.php', requestInfo)
                .then(response => response.json())
                .then(detalles => {  
                    if(detalles.Code == 0){     
                        this.addRecarga2();
                    }
                    else{
                        return Swal.fire('Error','Tarjeta Invalida','warning');
                    }
                })
                .catch(e=>console.log(e));
            }else{
                this.addRecarga2();
            }
            
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({tipo:value});
    }

    abreTYC=()=>{
        this.setState({swTYC:true});
    }

    cierraTYC=()=>{
        this.setState({swTYC:false});
    }

    componentDidMount(){
        console.log(this.props.protipo);
        if(this.props.protipo ==='promocional'){
            this.setState({promocional:1});
        }
    }
 
    render(){
        
        return(
            <div>
                <Modal id="ModalPaquete" isOpen={true} toggle={this.props.cierraPromocion} size="lg" >
                    <ModalHeader toggle={this.props.cierraPromocion} style={{color:'#D5060F', backgroundColor:'#F7DC6F'}}><b>Promoción</b></ModalHeader>
                    <ModalBody style={{backgroundColor:'#FF8B28', color:'white', height:this.props.protipo ==='recargas'?600:400}}>
                        <div style={{margin:0, position:'absolute', display:'flex', left:'8%', top:'10%'}} >
                            <div  >
                                <img className="procarimg" src={this.props.servurlassets+this.props.nombreimagen} alt="alternate text"/>
                            </div>
                        </div>
                        <div  >
                            {this.props.protipo ==='seleccionable'?
                                <div>
                                    <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'25%'}}>
                                        <p style={{width:450, fontSize:13}}>
                                            Recargas al doble en 3 pasos
                                            <br/>
                                            1- Compra en línea cuando quieras
                                            <br/>
                                            2- Abona tus cupones a tu Recorcard, sólo los miércoles en sucursal
                                            <br/>
                                            3- ¡Juega cuando quieras con el doble de saldo!
                                            <br/>
                                            Carga mínima: $200 pesos
                                            <br/>
                                            EL CUPÓN ADQUIRIDO SÓLO SE PODRÁ CANJEAR LOS MIÉRCOLES EN SUCURSALES
                                        </p>
                                    </div>
                                    <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'25%'}}>
                                        <Label style={{fontSize:20, fontWeight:'bold'}}>Selecciona el monto de la recarga</Label>
                                    </div>
                                    <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'30%'}} >
                                        <Input id="txtMonto" bsSize='sm' type="select" style={{fontSize:20, width:150}}>
                                            <option key={2} value={200}>200</option>
                                            <option key={3} value={250}>250</option>
                                            <option key={4} value={300}>300</option>
                                            <option key={5} value={350}>350</option>
                                            <option key={6} value={440}>400</option>
                                            <option key={7} value={450}>450</option>
                                            <option key={8} value={500}>500</option>
                                            <option key={9} value={550}>550</option>
                                            <option key={10} value={600}>600</option>
                                            <option key={11} value={650}>650</option>
                                            <option key={12} value={700}>700</option>
                                            <option key={13} value={750}>750</option>
                                            <option key={14} value={800}>800</option>
                                            <option key={15} value={850}>850</option>
                                            <option key={16} value={900}>900</option>
                                            <option key={17} value={950}>950</option>
                                            <option key={18} value={1000}>1000</option>
                                        </Input>
                                    </div>
                                </div>
                            :this.props.protipo==='recargas'?
                                <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'43%', top:'30%'}} >
                                    <Label style={{fontSize:20}}>
                                        <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="preexistente" /> En tarjeta preexistente
                                    </Label>
                                    <br/>
                                    <Label style={{fontSize:20}} >
                                        <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="nueva" /> En tarjeta nueva ( + ${this.props.costotarjeta} )
                                    </Label>
                                    <br/>
                                    <Label style={{fontSize:20}} >
                                        <Input style={{fontSize:20}} defaultChecked onChange={this.handleChange} type="radio" name="radio1" value="cupon" /> En cupón digital
                                    </Label>
                                    {this.state.tipo==='preexistente'?
                                        <>
                                        <div style={{margin:0, position:'relative', display:'flex', left:'0%', top:'40%'}} >
                                        <Label style={{fontSize:20, fontWeight:'bold'}}>Tarjeta Recorcard (10 dígitos)</Label>
                                        </div>
                                        {this.props.cards.length>0?
                                            <div style={{margin:0, position:'relative', display:'flex', marginLeft:'0%', top:'45%'}} >
                                                <Input style={{width:280}} id="txtRecorcard" type="select"> 
                                                    { this.props.cards.map((card,index) => (
                                                        <option key={index} value={card.NUMBER}>{card.NUMBER}</option>
                                                    ))}
                                                </Input>
                                            </div>
                                        :
                                            <div style={{margin:0, position:'relative', display:'flex', left:'0%', top:'45%'}} >
                                                <NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:25, width:230}} />
                                            </div>
                                        }
                                        </>:''
                                    }
                                    <p style={{fontWeight:'bold', width:400}}>{this.props.textbold}</p>
                                </FormGroup>
                            :this.props.protipo ==='promocional'?
                                <div style={{height:'50%',width:'50%',margin:0, position:'relative', display:'flex',paddingTop:'15%', left:'45%'}} >
                                    <div style={{margin:0, position:'relative', left:'0'}} >
                                        <Input id="txtMonto" style={{display:"none"}}/>
                                        <p style={{fontWeight:'bold'}}>{this.props.textbold}</p><br/>
                                        <Input id="codigoPromocional" placeholder='Código Promocional'/>
                                        <br/>
                                        <p style={{fontWeight:'bold'}}>Precio ${this.props.valor}</p>
                                    </div>
                                </div>
                            :
                                <div>
                                    <Input id="txtMonto" style={{display:"none"}}/>
                                    <div style={{height:300,margin:0, position:'relative', display:'flex', left:'45%'}} >
                                        <p style={{fontWeight:'bold', width:400}}>{this.props.textbold}</p>
                                    </div>
                                </div>
                            }
                            <div style={{margin:0, position:'relative', left:'45%'}} >
                                <Button className="btn-recorcholis" onClick={this.addRecarga.bind(this)} >Agrega a carrito</Button>
                            </div>
                        </div>
                        
                    </ModalBody>
                    <ModalFooter onClick={this.abreTYC} style={{justifyContent:'center', backgroundColor:'#FF8B28', color:'white'}}>
                        <h4  className="proTYC"> Ver Términos y Condiciones</h4>
                    </ModalFooter>
                </Modal>    
                {this.state.swTYC===true?
                    <ModalTYC servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} codigo={this.props.codigo} cierraTYC={this.cierraTYC}/>:''
                }
            </div>
        );
    }    
}
export default ModalPromocion