import React, { Component } from 'react';
import Secc_1 from './secc_1Movil';
import Secc_2 from './secc_2Movil';
import Secc_3 from './secc_3Movil';
import Secc_4 from './secc_4Movil';
import Secc_5 from './secc_5Movil';

class Secc_0 extends Component{
    render(){
        return(
            <div> 
                <Secc_1 cursecc={this.props.cursecc} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} display = {this.props.display} />
                <Secc_2 cursecc={this.props.cursecc} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} display = {this.props.display} />
                <Secc_3 cursecc={this.props.cursecc} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_4 cursecc={this.props.cursecc} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_5 cursecc={this.props.cursecc} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
            </div>
                
        )
    }

}
export default Secc_0