import React, { Component } from 'react';
import { Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalDetalleTarjeta extends Component {

    render() {

        return (
            <div>
                <Modal id="ModalDetalleTarjeta" isOpen={true}>
                    <ModalHeader toggle={this.props.cierraReserva} style={{ color: 'white', backgroundColor: '#1f56ff' }}><b>Detalle de tarjeta</b></ModalHeader>
                        <ModalBody style={{ backgroundColor: 'white', color: 'black' , padding: "10%"}} >
                            <div style={{ textAlign:'center' }}>
                                <Row>
                                <Col sm={6}>
                                 <h4 className='main_msg_modal'>E - money:</h4>
                                 <Input value={this.props.detalles_.emoney} style={{textAlign:'center'}} type="text" id="emoney" disabled/>
                                 </Col>
                                 <Col sm={6}>
                                 <h4 className='main_msg_modal'>Bonus:</h4>
                                 <Input value={this.props.detalles_.bonus} style={{textAlign:'center'}} type="text" id="bonus" disabled/>
                                 </Col>
                                </Row>                                
                                <Row>
                                <Col sm={6}>
                                 <br/>
                                 <h4 className='main_msg_modal'>E - tickets:</h4>
                                 <Input value={this.props.detalles_.etickets} style={{textAlign:'center'}} type="text" id="etickets" disabled/>
                                 </Col>
                                 <Col sm={6}>
                                 <br/>
                                 <h4 className='main_msg_modal'>Gamepoints:</h4>
                                 <Input value={this.props.detalles_.gamepoints} style={{textAlign:'center'}} type="text" id="gamepoints" disabled/>
                                 </Col>
                                </Row>
                                <Row>
                                <Col sm={12}>
                                 <br/>
                                 <h4 className='main_msg_modal'>Última visita:</h4>
                                 <Input value={this.props.detalles_.branchCaption} style={{textAlign:'center'}} type="text" id="branchCaption" disabled/>
                                </Col>
                                </Row>                
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ color: 'white', backgroundColor: '#1f56ff' }}>
                         <button className="btn btn-light" size='sm' onClick={this.props.cierraDetailT.bind()} >Cerrar</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalDetalleTarjeta