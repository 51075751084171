import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";
import { Button } from 'reactstrap';


class Secc_4 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }

    getObjetos = ()=>{
        let data ={
            carruselid : 4,
            objetofilterid:""
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }
    
    componentDidMount(){
        this.getObjetos();
     } 

    render(){
       
        
        return(
            <div>            
                <div  className="movilsection-4" onClick={this.props.cursecc.bind(this,"fiestas")}>
                    <div style={{backgroundColor: '#FFFFFF',textAlign:"center"}} className="movilfont-pop top-msg">
                        <div className="moviltop-msg__container">
                            <h5>Consulta términos y condiciones de cada promoción</h5>
                        </div>
                    </div>
                    <div style={{height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div style={{ zIndex:-5,width:'100%', height:'100%', position:'absolute', top:'5%'}}>
                            <img width='100%' height='90%' src={this.props.servurlassets+"secc4_img1.png"} alt="secc41" />
                        </div>
                        <div style={{zIndex:-2,width:'100%', height:'100%',textAlign:'left',paddingLeft:'12.5%', position:'absolute', paddingTop:'15%',top:'5%'}}>
                            <h1 className="movilfont-pop" style={{color:'white'}}>
                                Festeja tu cumpleaños
                            </h1>
                        <div className="movilimage-footer font-pop" style={{fontWeight:600, color:'#01FEE4', position:'absolute', marginLeft:-60, bottom:'10%'}}>
                            <h3 style={{textAlign:'center', fontWeight:600}}>
                                Fechas disponibles limitadas<br/>
                                ¡RESERVA YA TU FIESTA DE REGALO!<br/>
                                <Button className='movilmyButton' >! Reserva Aqui !</Button>
                            </h3>
                        </div>
                    </div>
                    <div style={{zIndex:-1, textAlign:'center', paddingTop:'15%', paddingLeft:'auto', width:'70%', height:'70%', position:'absolute'}}>
                        <img width='100%' height='auto' src={this.props.servurlassets+"secc4_img3.png"} alt="sec43"/>
                    </div>
                </div>
            </div>
            </div>
        )
    }

}
export default Secc_4