import React, { Component } from 'react';
import './desktop.css';

class Secc_recupera_club extends Component{
     
    constructor() {
        super();

        this.state ={
            objetosLs:[],
            tipo:''
        };
    }
     
    render(){
        return(
            <div className="container-fluid text-center color_bckg">
                    <div className="row">
                        <div className="col-md-4 padre" style={{height:'100vh',left:'100%'}}>
                          <div className="hijo">
                           <img style={{width:'100%'}} src={this.props.servurlassets+'tato_registro.png'} />
                          </div>
                        </div>
                        <div className="col-md-4 padre" style={{height:'100vh',left:'100%'}}>
                            <div className="hijo_center">
                             <h1 className="login__title hide-at-xs">Recuperar contraseña</h1>
                             <label className="control-label">Correo *</label>
                             <input type="text" className="form-control" />
                             <a className="ee_myButton">Enviar contraseña</a>
                             <a className='click_aqui' onClick={this.props.cambiaSeccion.bind(this, 'iniciar_club', '', '')} href='#'><p>Regresar</p></a>
                            </div>      
                        </div>
                        <div className="col-md-4 padre" style={{height:'100vh',left:'100%'}}>
                           <div className="hijo">
                            <img style={{width:'100%'}} src={this.props.servurlassets+'tatis_registro.png'} />
                           </div>
                        </div>
                    </div>
                </div>
        )
    }


}
export default Secc_recupera_club