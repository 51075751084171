import React, { Component } from 'react';
import { Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { URL_CORESERVICE} from './../config'

class ModalEditarTarjeta extends Component {

    editar = ()=>{
        let data ={
            name: document.getElementById("name").value,
            lastname: document.getElementById("lastname").value,
            email: document.getElementById("email").value,
            password: document.getElementById("password").value,
            MGUID: this.props.detalles_.MGUID,
            idavatar: this.props.detalles_['AVATAR'].idavatar
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(URL_CORESERVICE + '/NewMemberEditar.php', requestInfo)
        .then(response => response.json())
        .then(response => {})

        .catch(e=>console.log(e)); 
    }

    render() {
        return (

            <div>
                <Modal id="ModalEditarTarjeta" isOpen={true}>
                    <ModalHeader toggle={this.props.cierraReserva} style={{ color: 'white', backgroundColor: '#1f56ff' }}><b>Editar datos</b></ModalHeader>
                        <ModalBody style={{ backgroundColor: 'white', color: 'black' }}>
                            <div style={{ textAlign:'center' }}>
                                <Row>
                                <Col sm={12}>
                                 <h4 className='main_msg_modal'>Nombre:</h4>
                                 <Input id='name' placeholder={this.props.detalles_.FIRSTNAME} style={{textAlign:'center'}} type="text"/>
                                 </Col>
                                 <Col sm={12}>
                                 <h4 className='main_msg_modal'>Apellido:</h4>
                                 <Input id='lastname' placeholder={this.props.detalles_.LASTNAME} style={{textAlign:'center'}} type="text"/>
                                 </Col>
                                 <Col sm={12}>
                                 <h4 className='main_msg_modal'>Correo:</h4>
                                 <Input id='email' placeholder={this.props.detalles_.EMAIL} style={{textAlign:'center'}} type="text"/>
                                 </Col>
                                 <Col sm={12}>
                                 <h4 className='main_msg_modal'>Contraseña:</h4>
                                 <Input id='password' placeholder='*****' style={{textAlign:'center'}} type="password"/>
                                 </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ color: 'white', backgroundColor: '#1f56ff' }}>
                         <button className="btn btn-light" size='sm' onClick={this.editar.bind()} style={{ width: 100 }}>Guardar</button>            
                         <button className="btn btn-light" size='sm' onClick={this.props.cierraEditarT.bind()} >Cancelar</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalEditarTarjeta