import React, { Component } from 'react';
import { Input, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Swal from "sweetalert2";

class ModalRecargaTarjeta extends Component {

    constructor() {
        super();
    }
    
    componentDidMount=()=>{

    }

    addRecarga = ()=>{

        let montox= Number(document.getElementById("txtMonto").value);
        let reco=document.getElementById("txtRecorcard").value;

        let data ={
            token : this.props.token,
            monto : montox,
            codigo : "SCC001",
            recorcard : reco,
            tipo:"preexistente",
            regalo_email:"",
            regalo_name:""
        }

        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase + 'addRecarga.php', requestInfo)
        .then(response => response.json())
        .then(respuesta => {
            if(respuesta !==null){
                this.props.getCarrito(this.props.token);
                Swal.fire(
                    'Artículo agregado a carrito',
                    '',
                    'success'
                ) 
            }else{
                Swal.fire(
                    'Error',
                    'Upss, algo ocurrió, intente mas tarde',
                    'warning'
                )
            } 
        })
        .catch(e=>console.log(e)); 
    }

    render() {
        return (
            <div>
                <Modal id="ModalRecargaTarjeta" isOpen={true}>
                    <ModalHeader toggle={this.props.cierraReserva} style={{ color: 'white', backgroundColor: '#1f56ff' }}><b>Recarga de tarjeta</b></ModalHeader>
                        <ModalBody style={{ backgroundColor: 'white', color: 'black' }}>
                            <div style={{ textAlign:'center' }}>
                                <Row>
                                <Col sm={12}>
                                 <img style={{ height:'180px'}} className="sec5_tato" src={this.props.servurlassets+"secc_recargas_img1.png"} alt="Alternate Text" />
                                </Col>
                                <Col>
                                 <br/>
                                </Col>
                                <Col sm={12}>
                                    <label className='msg_table'>Monto de la recarga</label>
                                    <Input id="txtMonto" bsSize='sm' type="select" style={{fontSize:20}}>
                                    <option value={100}>100</option>
                                    <option value={150}>150</option>
                                    <option value={200}>200 </option>
                                    <option value={250}>250</option>
                                    <option value={300}>300 </option>
                                    <option value={350}>350</option>
                                    <option value={440}>400</option>
                                    <option value={450}>450</option>
                                    <option value={500}>500 </option>
                                    <option value={550}>550</option>
                                    <option value={600}>600</option>
                                    <option value={650}>650</option>
                                    <option value={700}>700</option>
                                    <option value={750}>750</option>
                                    <option value={800}>800</option>
                                    <option value={850}>850</option>
                                    <option value={900}>900</option>
                                    <option value={950}>950</option>
                                    <option value={1000}>1000</option>
                                    </Input>
                                </Col>
                                <Col sm={12}>
                                <br/>
                                </Col>
                                <Col sm={12}>
                                  <label className='msg_table'>Tarjeta Recorcard (10 dígitos)</label>
                                 <NumberFormat id="txtRecorcard" format="###-######-#" value={this.props.number} style={{fontSize:25, width:'100%', textAlign:'center'}} disabled readonly/>
                                </Col>
                                </Row>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{ color: 'white', backgroundColor: '#1f56ff' }}>
                         <button className="btn btn-light" size='sm' onClick={this.addRecarga.bind(this)} >Agrega a carrito</button>            
                         <button className="btn btn-light" onClick={this.props.cierraRecargaT.bind()} size='sm' >Cancelar</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalRecargaTarjeta