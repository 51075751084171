import React, { Component } from 'react';
import {Label, Input, FormGroup, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import NumberFormat from 'react-number-format';
import './desktop.css';
import ModalTYC from './modalTYC';
import Swal from 'sweetalert2';

class ModalPaquete extends Component{

    constructor() {
        super();
        this.state ={
        total :0,
        tipo:'',
        swTYC:false
        }
    }

    addRecarga = ()=>{
        let reco = '';
        let montox = this.state.tipo==='nueva' ? Number(this.props.costotarjeta) + Number(this.props.valor) : Number(this.props.valor);

        if(this.state.tipo==='preexistente'){
            reco=document.getElementById("txtRecorcard").value;
            this.consulta_detalle(reco);
            setTimeout(() => {
                if(this.state.response_card === false){
                    return Swal.fire('Error','Tarjeta Invalida','warning');
                }else {
                    let data ={
                        token : this.props.token,
                        codigo : this.props.codigo,
                        monto : montox,
                        tipo : this.state.tipo,
                        recorcard : reco,
                        regalo_name:'',
                        regalo_email:''
                    }
                   this.props.agregaCarrito(data);
                }
            }, 1500);       
        }
        else{

            let data ={
                token : this.props.token,
                codigo : this.props.codigo,
                monto : montox,
                tipo : this.state.tipo,
                recorcard : reco,
                regalo_name:'',
                regalo_email:''
            }
            
            this.props.agregaCarrito(data);
        }
        

    }

    consulta_detalle = (CGUID)=>{
        let data ={
            CGUID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };

        fetch(this.props.servurlbase+'GetCard.php', requestInfo)
        .then(response => response.json())
        .then(detalles => {  

            if(detalles.Code == 0){     
             this.setState({response_card:true});
            }
            else{
             this.setState({response_card:false});  
            }

        })

        .catch(e=>console.log(e));
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({tipo:value});
    }

    abreTYC=()=>{
        this.setState({swTYC:true});
    }

    cierraTYC=()=>{
        this.setState({swTYC:false});
    }
 
    componentDidMount=()=>{
        var x=window.location + "";
        var ruta = x.split('/');
        if(ruta.length>5){this.registrarMedio(ruta[5],ruta[4],this.props.token)}
        this.props.getSesionCtato(this.props.token);
    }

    registrarMedio = (medio,combo,token) => {
        let data ={
            medio,
            combo,
            token
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'guardarMedio.php', requestInfo)
    }
    
    render(){
        
        return(
            <div>
                <Modal id="ModalPaquete" isOpen={true} toggle={this.props.cierraPaquete} size="lg" >
                    <ModalHeader toggle={this.props.cierraPaquete} style={{color:'#D5060F', backgroundColor:'#F7DC6F'}}><b>Paquete</b></ModalHeader>
                    <ModalBody style={{backgroundColor:'rgb(215, 11,22)', color:'white'}}>
                        <div style={{margin:0, position:'absolute', display:'flex', left:'8%', top:'10%'}} >
                            <div  >
                                <img className="rpaqcarimg_modal" src={this.props.servurlassets+this.props.nombreimagen} alt="alternate text"/>
                                {
                                    this.props.mesessi==='3'?
                                    <img className="rpaqcarimgmeses_modal" src={this.props.servurlassets+"tres_meses_sin_interes.png"} alt="alternate text"/> 
                                    :
                                    this.props.mesessi==='6'?
                                    <img className="rpaqcarimgmeses_modal" src={this.props.servurlassets+"seis_meses_sin_interes.png"} alt="alternate text"/> 
                                    :''
                                }
                            </div>
                        </div>
                        <div>
                            <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'25%'}}>
                                <Label style={{fontSize:20, fontWeight:'bold'}}>Quieres aplicar tu paquete en:</Label>
                            </div>
                                
                            <br/>
                            <FormGroup style={{margin:0, position:'relative', textAlign:'left',  left:'43%', top:'30%'}} >
                                <Label style={{fontSize:20}}>
                                    <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="preexistente" /> En tarjeta preexistente
                                </Label>
                                <br/>
                                <Label style={{fontSize:20}} >
                                    <Input style={{fontSize:20}} onChange={this.handleChange} type="radio" name="radio1" value="nueva" /> En tarjeta nueva ( + ${this.props.costotarjeta} )
                                </Label>
                                <br/>
                                <Label style={{fontSize:20}} >
                                    <Input style={{fontSize:20}} defaultChecked onChange={this.handleChange} type="radio" name="radio1" value="cupon" /> En cupón digital
                                </Label>
                            </FormGroup>
                            <br/>
                            {this.state.tipo==='preexistente'?
                                <>
                                <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'40%'}} >
                                <Label style={{fontSize:20, fontWeight:'bold'}}>Tarjeta Recorcard (10 dígitos)</Label>
                                </div>
                                {this.props.cards.length>0?
                                    <div style={{margin:0, position:'relative', display:'flex', marginLeft:'43%', top:'45%'}} >
                                        <Input style={{width:280}} id="txtRecorcard" type="select"> 
                                            { this.props.cards.map((card,index) => (
                                                <option key={index} value={card.NUMBER}>{card.NUMBER}</option>
                                            ))}
                                        </Input>
                                    </div>
                                    :
                                    <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'45%'}} >
                                        <NumberFormat id="txtRecorcard" format="###-######-#" style={{fontSize:25, width:230}} />
                                    </div>
                                }
                                </>:''
                            }
                            <br/> <br/>
                            <div style={{margin:0, position:'relative', display:'flex', left:'43%', top:'70%'}} >
                                <Button className="btn-recorcholis" onClick={this.addRecarga.bind(this)} >Agrega a carrito</Button>
                            </div>
                        </div>
                        <br/><br/>
                    </ModalBody>
                    <ModalFooter style={{justifyContent:'center', backgroundColor:'rgb(215, 11,22)', color:'white'}}>
                        <h4 onClick={this.abreTYC}  className="proTYC"> Ver Términos y Condiciones</h4>
                    </ModalFooter>
                    {this.state.swTYC===true?
                        <ModalTYC servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} codigo={this.props.codigo} cierraTYC={this.cierraTYC}/>:''
                    }
                </Modal>    
            </div>
        );
    }    
}

export default ModalPaquete