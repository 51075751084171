import React, { Component } from 'react';
import Secc_0 from './secc_0';
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import Secc_recargas from './secc_recargas';
import Secc_paquetes from './secc_paquetes';
import Secc_Atrac_Games from './secc_atrac_games';
import Secc_Atrac_Pabellones from './secc_atrac_pabellones';
import Secc_Atrac_Kids from './secc_atrac_kids';
import Secc_Atrac_Snacks from './secc_atrac_snacks';
import Secc_Atrac_Pista from './secc_atrac_pista';
import Secc_clases_PH from './secc_clases_PH';
import Secc_Atrac_AM from './secc_atrac_am';
import Secc_Atrac_Boliche from './secc_atrac_boliche';
import Secc_Fiestas from './secc_fiestas';
import Secc_regalos from './secc_regalos';
import Secc_Evtos_Emp from './secc_evtos_emp';
import Secc_Evtos_Soc from './secc_evtos_soc';
import Secc_Evtos_Bol from './secc_evtos_bol';
import Secc_Canjes from './secc_canjes';
import Secc_About_us from './secc_about_us';
import Secc_Aviso from './secc_aviso';
import Secc_Bolsa_trab from './secc_bolsa_trab';
import Secc_Contacto from './secc_contacto';
import Secc_promociones from './secc_promociones';
import Secc_preguntas from './secc_preguntas';
import Secc_clases from './secc_clases';
import Secc_terminosycondiciones from './secc_terminosycondicionesC';
import Secc_Factura from './secc_factura';
import Secc_ClubTato from './secc_clubTato';
import Secc_iniciar_club from './secc_iniciar_club';
import Secc_registrar_club from './secc_registrar_club';
import Secc_recupera_club from './secc_recupera_club';
import Secc_sesion_club from './secc_sesion_club';
import Secc_validado from './secc_validado';
import Secc_registro from './secc_registro';
import Secc_comunidad from './secc_comunidad';
import { CSSTransition } from "react-transition-group";
import ModalCarrito from './modalCarrito';
import Secc_nuevaC_club from './secc_nueva_contra';
import Secc_reembolsos from './secc_politica_r';
import Secc_compraWeb from './secc_compraWeb';
import "./desktop.css";
import Swal from 'sweetalert2';
import { URL_CORESERVICE, URL_ASSETS} from './../config'
import ReactPixel from 'react-facebook-pixel';

class MainDesktop extends Component {
    constructor() {
        super();
        this.state = {
            sesionCtato: '',
            pvDim: '',
            tokenCompra: '',
            cursecc: 'main',
            token: '',
            cards: '',
            codigo: '',
            objetofilterid: '',
            showcarrito: false,
            activoBoton: true,
            carritoLs: [],
            costoLs: [],
            costotarjeta: 0,
            servurlbase: URL_CORESERVICE + '/',
            servurlassets: URL_ASSETS + '/imagenes-r/',
            id_valida: "",
            lastID:0,
        };
    }

    activaBoton=()=>{
        this.setState({activoBoton:false});
    }

    remplazarToken=()=>{
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        cookies.set('recoToken', undefined, { path: '/' });
        
        let uniquetoken = uuid();
        cookies = new Cookies();
        cookies.set('recoToken', uniquetoken, { path: '/' });
        token = cookies.get('recoToken');
    }

    goToken = () => {
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        let uniquetoken = uuid();
        
        if (token === undefined) {
            let cookies = new Cookies();
            
            // Intenta configurar una cookie para verificar si las cookies están desactivadas
            cookies.set('testCookie', 'testValue', { path: '/' });
            
            if (cookies.get('testCookie') === undefined) {
                // Las cookies están desactivadas, asigna el token generado sin establecer la cookie
                token = uniquetoken;
            } else {
                // Las cookies están activadas, establece la cookie 'recoToken'
                cookies.set('recoToken', uniquetoken, { path: '/' });
                token = cookies.get('recoToken');
            }
        }
        
        this.setState({ token: token });
        this.getCarrito(token);
        this.getSesionCtato(token);
    }
    
    eliminaItem = (id) => {
        let data = {
            token: this.state.token,
            id: id
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'deleteItemCarrito.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                if (respuesta !== null) {
                    this.getCarrito(this.state.token);
                } else {
                    alert('Upss, algo ocurrió, intente mas tarde');
                }
            })
            .catch(e => console.log(e));
    }

    getSesionCtato = (token) => {
        let data = {
            token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getSesion.php', requestInfo)
            .then(response => response.json())
            .then(sesion => {
                if(sesion!==0){
                    if(sesion["Data"]["cards"].length>0){
                        this.setState({cards:sesion["Data"]["cards"]});
                    }else{
                        this.setState({cards:[]});    
                    }
                    this.setState({sesionCTato:sesion["Data"]["EMAIL"]});
                }else{
                    this.setState({cards:[]});
                    this.setState({sesionCTato:''});
                }
            })
            .catch(e => console.log(e)
        );
    }

    getCarrito = (token) => {
        let data = {
            token: token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getCarrito.php', requestInfo)
            .then(response => response.json())
            .then(carritoLs => {
                if (carritoLs !== null) {
                    this.setState({ carritoLs });
                    let tot = 0;
                    carritoLs.forEach(element => {
                        tot = tot + Number(element.monto);
                    });
                    this.setState({ total: tot });
                } else {
                    this.setState({ carritoLs: [] });
                    this.setState({ total: 0 });
                }
            })
            .catch(e => console.log(e));
    }

    agregaCarrito = (data) => {
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'addRecarga.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                if (respuesta !== null) {
                    this.getCarrito(this.state.token);
                    Swal.fire(
                        'Artículo agregado a carrito',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error',
                        'Upss, algo ocurrió, intente mas tarde',
                        'warning'
                    )
                }
            })
            .catch(e => console.log(e));
    }

    getCostoTarjeta = () => {
        const requestInfo = {
            method: 'POST',
            // body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getCostoTarjeta.php', requestInfo)
            .then(response => response.json())
            .then(costoLs => {
                if (costoLs !== null) {
                    this.setState({ costotarjeta: costoLs[0].costo });

                    // alert('Gracias por enviarnos sus comentarios.');
                } else {
                    this.setState({ costotarjeta: 0 });
                }
            })
            .catch(e => console.log(e));
    }

    abreCarrito = () => {
        this.setState({ showcarrito: true });
    }

    cierraCarrito = () => {
        this.setState({ showcarrito: false });
    }

    componentDidMount() {
        this.getCostoTarjeta();
        this.setState({ pvDim: window.innerWidth });
        this.goToken();
        var x=window.location + "";
        var ruta = x.split('/');
        var id_valida = ""
        var tokenCompra = ""

        ReactPixel.init('132586586571350');
        ReactPixel.pageView();
        
        if(ruta[3] === '#pista'){
            this.cambiaSeccion('clasesPH', '');
        }else if(ruta[3] === '#fiestas'){
            this.cambiaSeccion('fiestas', '');
        }else if(ruta[3] === '#recuperaContra'){
            this.cambiaSeccion('recuperaContra');
        }else if(ruta[3] === "#valida"){
            id_valida = ruta[4];
            this.setState({id_valida:id_valida});
            this.cambiaSeccion('validado');
        }else if(ruta[3] === "#promo"){
            var promocion=ruta[4];
            this.cambiaSeccion('promociones',promocion);
            if(ruta[5]){
                
            }
        }else if(ruta[3] === "#paquetes"){
            if(ruta.length>4){
                var combo = ruta[4];
                let data ={
                    codigo:combo
                }
                const requestInfo={
                    method:'POST',
                    body:JSON.stringify(data),
                    header: new Headers({
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json'
                    })
                };
                fetch(this.state.servurlbase+'getPaquetes.php', requestInfo)
                .then(response => response.json())
                .then(combo => {
                    if(combo !==null){
                        this.cambiaSeccion('paquetes',combo);
                    }
                })
                .catch(e=>console.log(e)); 
            }else{
                this.cambiaSeccion('paquetes');
            }
            
        }else if(ruta[3] === "#compraWeb"){
            tokenCompra = ruta[4];
            this.setState({tokenCompra:tokenCompra});
            this.cambiaSeccion('compraWeb');
        }     
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    registrarMedio = (medio,combo) => {
        let data ={
            medio,
            combo,
            token:this.state.token
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.state.servurlbase+'guardarMedio.php', requestInfo)
        .then(response => response.json())
        .catch(e=>console.log(e)); 
    }

    registrarVisita = (seccion) => {
        let data ={
            seccion,
            token:this.state.token,
            lastID:this.state.lastID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.state.servurlbase+'guardarSeccion.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if (response !== null) {
                this.setState({ lastID: response });
            } else {
                this.setState({ lastID: 0 });
            }
        }).catch(e=>console.log(e));
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
      
    handleBeforeUnload = () => {
        this.registrarVisita(null);
    };

    cambiaSeccion = (sec, codigo, objetofilterid) => {
        this.cierraCarrito();
        this.registrarVisita(sec);
        this.setState({ objetofilterid: objetofilterid });
        this.setState({ codigo: codigo });
        this.setState({ cursecc: sec });
    }

    render() {
        return (            
            <div >
                <nav >
                    <div id="navbar-beta">
                        <div className="nav-container hide-at-sm hide-at-xs hide-at-sm hide-at-md">
                            <div className="logo-container" >
                                <a href="/" style={{ display: "contents" }}>
                                    <img src={this.state.servurlassets + 'logo_recorcholis.png'} alt="logo" />
                                </a>
                            </div>
                            <div className="menuitems-container" style={{fontSize:"16px"}}>
                                <ul >
                                    <li >
                                        <a href="/#recarga" onClick={this.cambiaSeccion.bind(this, 'main', '')}>
                                            Recarga
                                        </a>
                                    </li>
                                    <li >
                                        <a href="#promociones" onClick={this.cambiaSeccion.bind(this, 'main', '')}>
                                            Promociones
                                        </a>
                                    </li>
                                    <li>
                                        <div>
                                            <span> Atracciones</span>
                                            <div className="submenu">
                                                <div className="triangle-svg"></div>
                                                <div className="submenuitems">
                                                    <ul>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_games', '', '')} href="#">
                                                                Games
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_pabellones', '', '')} href="#">
                                                                Pabellones
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_kids', '', '')} href="#">
                                                                Kids
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_snacks', '', '')} href="#">
                                                                Snacks
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_pista', '', '')} href="#">
                                                                Pista de hielo
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'atrac_am', '', '')} href="#">
                                                                Atracciones mayores
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a onClick={this.cambiaSeccion.bind(this, 'atrac_boliche', '', '')} href="#">
                                            Boliche
                                        </a>
                                    </li>
                                    <li>
                                        <div>
                                            <span> Fiestas </span>
                                            <div className="submenu">
                                                <div className="triangle-svg"></div>
                                                <div className="submenuitems">
                                                    <ul>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'fiestas', '', '')} href="#">
                                                                Fiestas
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'evtos_emp', '', '')} href="#">
                                                                Eventos empresariales
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'evtos_soc', '', '')} href="#">
                                                                Eventos sociales
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a onClick={this.cambiaSeccion.bind(this, 'evtos_bol', '', '')} href="#">
                                                                Torneos de boliche
                                                            </a>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a onClick={this.cambiaSeccion.bind(this, 'canjes', '', '')} href="#">
                                            Canje
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/#encuentraSucursal" onClick={this.cambiaSeccion.bind(this, 'main', '', '')}>
                                            Sucursales
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="login-container" style={{ minWidth: '100px' }}>
                                <div className="item">
                                    <button style={{ display: 'contents', position: 'relative' }}>
                                        <div onClick={this.abreCarrito.bind(this)} style={{ position: 'relative' }}>
                                            <div className="rnav-container__cart-notification">
                                                <span id="shop-badge">{this.state.carritoLs.length}</span>
                                            </div>
                                            <img src={this.state.servurlassets + 'carrito.png'} alt="Alternate Text" className="on-pointer nav-container__images" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div className="login-container" style={{ minWidth: '100px' }}>
                                <div className="item" onClick={this.cambiaSeccion.bind(this, 'clubTato', '', '')} style={{ position: 'relative'}}>
                                    <img style={{width:'100%'}} src={this.state.servurlassets + 'logoclub.png'} alt="Alternate Text" className="on-pointer nav-container__images" />
                                </div>
                            </div>            
                        </div>
                    </div>
                </nav>
                <div className="sidebarSocial hidden-xs">
                    <div className="social-icons-container ">
                        <a href="https://www.facebook.com/RECORCHOLISoficial/" className="a-link on-pointer" target="_blank">
                            <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'mediaicon1.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://twitter.com/recorcholis" className="a-link  on-pointer" target="_blank">
                            <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'mediaicon2.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://www.instagram.com/recorcholisoficial/" className="a-link  on-pointer" target="_blank">
                            <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'mediaicon3.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://www.youtube.com/user/RecorcholisOficial" className="a-link  on-pointer" target="_blank">
                            <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'mediaicon4.png'} alt="Alternate Text" />
                        </a>
                        <a href="https://www.linkedin.com/company/recorcholis-grupo-diniz" className="a-link on-pointer" target="_blank">
                            <img style={{ width: 40, height: 40 }} src={this.state.servurlassets + 'mediaicon5.png'} alt="Alternate Text" />
                        </a>
                    </div>
                </div>
                <CSSTransition in={this.state.showcarrito} timeout={300} classNames="example" unmountOnExit>
                    <ModalCarrito cambiaSeccion={this.cambiaSeccion} remplazarToken={this.remplazarToken} token={this.state.token} total={this.state.total} eliminaItem={this.eliminaItem} carritoLs={this.state.carritoLs} cierraCarrito={this.cierraCarrito} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                </CSSTransition>
                <div>
                    {this.state.cursecc === 'main' ?
                        <Secc_0 costotarjeta={this.state.costotarjeta} cambiaSeccion={this.cambiaSeccion} pvDim={this.state.pvDim} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'recargas' ?
                        <Secc_recargas cambiaSeccion={this.cambiaSeccion} getSesionCtato={this.getSesionCtato} cards={this.state.cards} activaBoton={this.activaBoton} activoBoton={this.state.activoBoton} agregaCarrito={this.agregaCarrito} costotarjeta={this.state.costotarjeta} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'paquetes' ?
                        <Secc_paquetes getSesionCtato={this.getSesionCtato} cards={this.state.cards} codigo={this.state.codigo} activaBoton={this.activaBoton} activoBoton={this.state.activoBoton} agregaCarrito={this.agregaCarrito} costotarjeta={this.state.costotarjeta} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_games' ?
                        <Secc_Atrac_Games getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_pabellones' ?
                        <Secc_Atrac_Pabellones getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_kids' ?
                        <Secc_Atrac_Kids getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_snacks' ?
                        <Secc_Atrac_Snacks getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_pista' ?
                        <Secc_Atrac_Pista getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'clasesPH' ?
                        <Secc_clases_PH getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_am' ?
                        <Secc_Atrac_AM getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'atrac_boliche' ?
                        <Secc_Atrac_Boliche getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'fiestas' ?
                        <Secc_Fiestas getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'evtos_emp' ?
                        <Secc_Evtos_Emp getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'evtos_soc' ?
                        <Secc_Evtos_Soc getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'evtos_bol' ?
                        <Secc_Evtos_Bol getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'canjes' ?
                        <Secc_Canjes getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'about' ?
                        <Secc_About_us getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'aviso' ?
                        <Secc_Aviso getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'bolsa' ?
                        <Secc_Bolsa_trab getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'contacto' ?
                        <Secc_Contacto getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'promociones' ?
                        <Secc_promociones cards={this.state.cards} activaBoton={this.activaBoton} activoBoton={this.state.activoBoton} agregaCarrito={this.agregaCarrito} objetofilterid={this.state.objetofilterid} costotarjeta={this.state.costotarjeta} codigo={this.state.codigo} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'regalo' ?
                        <Secc_regalos activaBoton={this.activaBoton} activoBoton={this.state.activoBoton} agregaCarrito={this.agregaCarrito} costotarjeta={this.state.costotarjeta} codigo={this.state.codigo} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'preguntas' ?
                        <Secc_preguntas token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'terminosycondiciones' ?
                        <Secc_terminosycondiciones token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'facturas' ?
                        <Secc_Factura token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'clubTato' ?
                        <Secc_ClubTato token={this.state.token} servurlbase={this.state.servurlbase}  cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'iniciar_club' ?
                        <Secc_iniciar_club getSesionCtato={this.getSesionCtato} sesionCTato={this.state.sesionCTato} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'registrar_club' ?
                        <Secc_registrar_club cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'recupera_club' ?
                        <Secc_recupera_club cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'sesion_club' ?
                        <Secc_sesion_club cliente={this.state.codigo} agregaCarrito={this.agregaCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} getCarrito={this.getCarrito}/>
                        : this.state.cursecc === 'sesion_club' ?
                        <Secc_clases cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'validado' ?
                        <Secc_validado cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} id_valida={this.state.id_valida}/>
                        : this.state.cursecc === 'registro_completo' ?
                        <Secc_registro cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'recuperaContra' ?
                        <Secc_nuevaC_club cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'comunidad' ?
                        <Secc_comunidad objetofilterid={this.state.objetofilterid} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'reembolsos' ?
                        <Secc_reembolsos objetofilterid={this.state.objetofilterid} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'compraWeb' ?
                        <Secc_compraWeb remplazarToken={this.remplazarToken} tokenCompra={this.state.tokenCompra} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        :''
                    }
                </div>
                <footer >
                    <div id="footer-beta" className="hide-at-xs hide-at-sm">
                        <div className="footer-container">
                            <a className="a-link" style={{ display: 'contents' }} href="tel:800 881 8811">
                                <img src="https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/telephonenumber.png" alt="Alternate Text" className="footer-atencion" />
                            </a>
                            <div className="footer-links-container">
                                <a onClick={this.cambiaSeccion.bind(this, 'about', '', '')} href="#">Nosotros</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'comunidad', '', '')} href="#">En la comunidad</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'bolsa', '', '')} href="#">Bolsa de trabajo</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'contacto', '', '')} href="#">Contacto</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'preguntas', '', '')} href="#">Preguntas</a>
                                <a href="https://diniz.com.mx/facturacion_recrefam/" target="_blank">Facturas</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'aviso', '', '')} href="#">Aviso de privacidad</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'reembolsos', '', '')} href="#">Reembolsos y Devoluciones</a>
                                <a onClick={this.cambiaSeccion.bind(this, 'terminosycondiciones', '')} href="#">Términos y condiciones</a>
                            </div>
                            <img className="footer-super-empresas" src="https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/n26.png" alt="Alternate Text" />
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

}
export default MainDesktop