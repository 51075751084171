import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_AM extends Component{
     
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="ampista">  
                   <div className="am_royalg">
                          <Row style={{ width:'100%'}}>
                              <Col sm={4} >
                                  <div style={{textAlign:'left'}}>
                                    <div className="am_titlesa">
                                        <h1>Atracciones mayores</h1>
                                    </div>
                                    <div className="am_subtitlesa">
                                        <h4>
                                        Contamos con atracciones mecánicas para una diversión más extrema.
                                        </h4>
                                    </div>
                                    <br/>
                                    <div className="am_textsa">
                                        <p>
                                        Porque ¡Recórcholis! piensa en la diversión en grande, creó atracciones mayores,
                                        un área donde encontrarás; Carruseles, Sillas voladoras, Lanchas choconas, Ruedas de la fortuna,
                                        Convoy, Go-karts, Mini golf y muchas atracciones más, en las que podrás disfrutar la diversión al máximo. 
                                        Sólo se encuentran disponibles en algunas sucursales, consulta nuestras ubicaciones.
                                        </p>
                                    </div>
                                    </div>
                                    <br/>
                                    <a href="#" onClick={this.props.cambiaSeccion.bind(this,'recargas','','')} class="ee_myButton">Comprar ahora</a>
                              </Col>
                              <Col sm={8} style={{paddingTop:20,textAlign:'center'}}>
                                    <div style={{position:'relative'}}>
                                        <YouTube videoId="CEyeIZoCXHA" opts={opts} onReady={this._onReady} />  
                                    </div> 
                                </Col>
                          </Row>
                    </div>
                </div>   
            </div>
        )
    }
}
export default Secc_Atrac_AM