import React, { Component } from 'react';
import { Label, Row, Col, Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
class ModalCompara extends Component{

    render(){
        
        return(
            <div>
                <Modal id="ModalCarrito" isOpen={true} toggle={this.props.modComp} size="lg" >
                    <ModalHeader toggle={this.props.showModalCompara} ></ModalHeader>
                    <ModalBody>
                        <img style={{width:"100%"}} src={this.props.servurlassets+"Combos_R_web.png"}/>
                    </ModalBody>
                </Modal>
            </div>
        );
    }    
}

export default ModalCompara