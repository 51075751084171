import React, { Component } from 'react';
import './cssMovil.css';
import Secc_0 from './secc_0_movil';
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import Secc_recargas from './secc_recargas';
import Secc_paquetes from './secc_paquetes';
import Secc_Boliche from './secc_bolera';
import Secc_Promociones from './secc_promociones';
import Secc_Tarjeta from './secc_regalo';
import Secc_Games from './secc_atrac_games';
import Secc_Pabellones from './secc_atrac_pabellones';
import Secc_Kids from './secc_atrac_kids';
import Secc_Snack from './secc_atrac_snacks';
import Secc_Pista from './secc_atrac_pista';
import Secc_clases_PH from './secc_clases_PH';
import Secc_Mayores from './secc_atrac_am';
import Secc_Fiestas from './secc_fiestas';
import Secc_Sociales from './secc_evtos_soc';
import Secc_Graduaciones from './secc_graduaciones';
import Secc_Empresariales from './secc_evtos_emp';
import Secc_Eve_Boliche from './secc_evtos_bol';
import Secc_Canje from './secc_canjes';
import Secc_Nosotros from './secc_about_us';
import Secc_Facturas from './secc_factura';
import Secc_Bolsa from './secc_bolsa_trab';
import Secc_Contacto from './secc_contacto';
import Secc_Aviso from './secc_aviso';
import Secc_Preguntas from './secc_preguntas';
import Secc_Terminos from './secc_terminos';
import Secc_ClubTato from './secc_clubTato';
import Secc_iniciar_club from './secc_iniciar_club';
import Secc_registrar_club from './secc_registrar_club';
import Secc_recupera_club from './secc_recupera_club';
import Secc_sesion_club from './secc_sesion_club';
import Secc_validado from './secc_validado';
import Secc_registro from './secc_registro';
import { CSSTransition } from "react-transition-group";
import ModalCarrito from './modalCarrito';
import { Collapse, Row, Col } from 'reactstrap';
import Secc_nuevaC_club from './secc_nueva_contra';
import Secc_comunidad from './secc_comunidad';
import Secc_reembolsos from './secc_politica_r';
import Secc_compraWeb from './secc_compraWeb';
import { URL_CORESERVICE, URL_ASSETS} from './../config'
import ReactPixel from 'react-facebook-pixel';

class Main extends Component {
    constructor() {
        super();
        this.toggle = this.toggle.bind(this);
        this.state = {
            cursecc: 'main',
            datos: [],
            cards: '',
            codigo: '',
            sesionCTato: '',
            tokenCompra: '',
            token: '',
            titulo: '',
            total: 0,
            lastID: 0,
            collapse: false,
            collapse2: false,
            collapse3: false,
            display: "none",
            showcarrito: false,
            carritoLs: [],
            promocion: [],
            modalOpcion: true,
            servurlbase: URL_CORESERVICE + '/',
            servurlassets: URL_ASSETS + '/imagenes-r/',
            id_valida: "",
            costotarjeta: 0,

        };
    }

    switchModalOpcion = () => {
        this.setState({ modalOpcion: !this.state.modalOpcion });
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    toggle2 = () => {
        this.setState({ collapse2: !this.state.collapse2 });
    }

    toggle3 = () => {
        this.setState({ collapse3: !this.state.collapse3 });
    }

    goToken = () => {
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        let uniquetoken = uuid();
        
        if (token === undefined) {
            let cookies = new Cookies();
            
            // Intenta configurar una cookie para verificar si las cookies están desactivadas
            cookies.set('testCookie', 'testValue', { path: '/' });
            
            if (cookies.get('testCookie') === undefined) {
                // Las cookies están desactivadas, asigna el token generado sin establecer la cookie
                token = uniquetoken;
            } else {
                // Las cookies están activadas, establece la cookie 'recoToken'
                cookies.set('recoToken', uniquetoken, { path: '/' });
                token = cookies.get('recoToken');
            }
        }
        
        this.setState({ token: token });
        this.getCarrito(token);
        this.getSesionCtato(token);
    }

    actualizaItem = (event, id) => {
        let data = {
            token: this.state.token,
            id: id,
            cantidad: event.target.value
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'updateItemCarrito.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                if (respuesta !== null) {
                    this.getCarrito(this.state.token);
                } else {
                    alert('Upss, algo ocurrió, intente mas tarde');
                }
            })
            .catch(e => console.log(e));
    }

    eliminaItem = (id) => {
        let data = {
            token: this.state.token,
            id: id
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'deleteItemCarrito.php', requestInfo)
            .then(response => response.json())
            .then(respuesta => {
                if (respuesta !== null) {
                    this.getCarrito(this.state.token);
                } else {
                    alert('Upss, algo ocurrió, intente mas tarde');
                }
            })
            .catch(e => console.log(e));
    }

    getCostoTarjeta = () => {
        const requestInfo = {
            method: 'POST',
            // body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getCostoTarjeta.php', requestInfo)
            .then(response => response.json())
            .then(costoLs => {
                if (costoLs !== null) {
                    this.setState({ costotarjeta: costoLs[0].costo });

                    // alert('Gracias por enviarnos sus comentarios.');
                } else {
                    this.setState({ costotarjeta: 0 });
                }
            })
            .catch(e => console.log(e));
    }

    getCarrito = (token) => {
        let data = {
            token: token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getCarrito.php', requestInfo)
            .then(response => response.json())
            .then(carritoLs => {
                if (carritoLs !== null) {
                    this.setState({ carritoLs });
                    let tot = 0;
                    carritoLs.forEach(element => {
                        tot = tot + (Number(element.monto));
                    });
                    this.setState({ total: tot });
                } else {
                    this.setState({ carritoLs: [] });
                    this.setState({ total: 0 });
                }
            })
            .catch(e => console.log(e));
    }

    abreCarrito = () => {
        this.getCarrito(this.state.token);
        this.setState({ showcarrito: true });
        this.menuOnClick();
    }

    cierraCarrito = () => {
        this.setState({ showcarrito: false });
    }

    menuOnClick = () => {
        document.getElementById("movilmenu-bar").classList.toggle("movilchange");
        document.getElementById("movilnav").classList.toggle("movilchange");
        document.getElementById("movilmenu-bg").classList.toggle("movilchange-bg");
        if (this.state.display === "none") {
            this.setState({ display: "block" });
        } else {
            this.setState({ display: "none" });
        }
    }

    cursecc = (seccion, datos = null) => {
        this.setState({ cursecc: seccion });
        this.setState({ datos });
        this.registrarVisita(seccion);
        this.cierraCarrito();
        if (this.state.display === "block") {
            this.menuOnClick();
        }
        this.setState({ collapse3: false });
    }
    
    remplazarToken=()=>{
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        cookies.set('recoToken', undefined, { path: '/' });
        
        let uniquetoken = uuid();
        cookies = new Cookies();
        cookies.set('recoToken', uniquetoken, { path: '/' });
        token = cookies.get('recoToken');
    }

    cambiaSeccion = (sec, codigo, objetofilterid) => {
        this.cierraCarrito();
        this.registrarVisita(sec);
        this.setState({ objetofilterid: objetofilterid });
        this.setState({ codigo: codigo });
        this.setState({ datos: codigo });
        this.setState({ cursecc: sec });
    }

    getSesionCtato = (token) => {
        let data = {
            token
        }
        const requestInfo = {
            method: 'POST',
            body: JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };
        fetch(this.state.servurlbase + 'getSesion.php', requestInfo)
            .then(response => response.json())
            .then(sesion => {
                if(sesion!==0){
                    if(sesion["Data"]["cards"].length>0){
                        this.setState({cards:sesion["Data"]["cards"]});
                    }else{
                        this.setState({cards:[]});    
                    }
                    this.setState({sesionCTato:sesion["Data"]["EMAIL"]});
                }else{
                    this.setState({cards:[]});
                    this.setState({sesionCTato:''});
                }
            })
            .catch(e => console.log(e)
        );
    }

    registrarVisita = (seccion) => {
        let data ={
            seccion,
            token:this.state.token,
            lastID:this.state.lastID
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.state.servurlbase+'guardarSeccion.php', requestInfo)
        .then(response => response.json())
        .then(response => {
            if (response !== null) {
                this.setState({ lastID: response });
            } else {
                this.setState({ lastID: 0 });
            }
        }).catch(e=>console.log(e));
    }

    goToken = () => {
        let cookies = new Cookies();
        let token = cookies.get('recoToken');
        let uniquetoken = uuid();
        
        if (token === undefined) {
            let cookies = new Cookies();
            
            // Intenta configurar una cookie para verificar si las cookies están desactivadas
            cookies.set('testCookie', 'testValue', { path: '/' });
            
            if (cookies.get('testCookie') === undefined) {
                // Las cookies están desactivadas, asigna el token generado sin establecer la cookie
                token = uniquetoken;
            } else {
                // Las cookies están activadas, establece la cookie 'recoToken'
                cookies.set('recoToken', uniquetoken, { path: '/' });
                token = cookies.get('recoToken');
            }
        }
        
        this.setState({ token: token });
        this.getCarrito(token);
        this.getSesionCtato(token);
    }

    getObjetoDetalle = (codigo)=>{
        let data ={
            codigo
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.state.servurlbase+'getObjetoDetalle.php', requestInfo)
        .then(response => response.json())
        .then(promocion => {
            if(promocion !==null){
                this.cambiaSeccion('promociones',promocion[0]);
            }else{
                this.setState({ promocion:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }  
    
    componentDidMount= () => {
        // ReactPixel.init('845706100505335');
        // ReactPixel.pageView();
        this.getCostoTarjeta();
        this.goToken();
        var x=window.location + "";
        var ruta = x.split('/');
        var id_valida = ""
        var tokenCompra = ""
        
        if(ruta[3] === '#pista'){
            this.cambiaSeccion('clasesPH', '');
        }else if(ruta[3] === '#fiestas'){
            this.cambiaSeccion('fiestas', '');
        }else if(ruta[3] === '#recuperaContra'){
            this.cambiaSeccion('recuperaContra');
        }else if(ruta[3] === "#valida"){
            id_valida = ruta[4];
            this.setState({id_valida:id_valida});
            this.cambiaSeccion('validado');
        }else if(ruta[3] === "#promo"){
            var promocion=ruta[4];
            this.getObjetoDetalle(promocion);
        }else if(ruta[3] === "#paquetes"){
            if(ruta.length>4){
                var combo = ruta[4];
                let data ={
                    codigo:combo
                }
                const requestInfo={
                    method:'POST',
                    body:JSON.stringify(data),
                    header: new Headers({
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json'
                    })
                };
                fetch(this.state.servurlbase+'getPaquetes.php', requestInfo)
                .then(response => response.json())
                .then(combo => {
                    if(combo !==null){
                        this.cambiaSeccion('paquetes',combo);
                    }
                })
                .catch(e=>console.log(e)); 
            }else{
                this.cambiaSeccion('paquetes');
            }
            
        }else if(ruta[3] === "#compraWeb"){
            tokenCompra = ruta[4];
            this.setState({tokenCompra:tokenCompra});
            this.cambiaSeccion('compraWeb');
        }     

        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
      
    handleBeforeUnload = () => {
        this.registrarVisita(null);
    };
    
    render() {
        return (
            <div>
                <div id="movilmenu" style={{ width: "100%" }} >
                    <div id="movilmenu-bar" onClick={this.menuOnClick.bind()}>
                        <div id="movilbar1" className="movilbar"></div>
                        <div id="movilbar2" className="movilbar"></div>
                        <div id="movilbar3" className="movilbar"></div>
                    </div>
                    <nav className="movilnav" style={{ display: this.state.display }} id="movilnav">l
                        <ul>
                            <br /><li ><img onClick={this.cursecc.bind(this, 'main')} src={this.state.servurlassets + 'logo_recorcholisW.png'} style={{ width: "250px", display: this.state.display }} alt="logo" /></li>
                            <li ><a href="#recarga" onClick={this.cursecc.bind(this, 'main')}>Recarga en línea</a></li>
                            <li ><a href="#promociones" onClick={this.cursecc.bind(this, 'main')}>Promociones</a></li>
                            <li>
                                <div>
                                    <div onClick={this.toggle} style={{ cursor: "pointer", color: "white" }}>Atracciones</div>
                                    <Collapse isOpen={this.state.collapse}>
                                        <div className="movilsubtitlesa">
                                            <ul>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'games')}>
                                                        Games
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'pabellones')}>
                                                        Pabellones
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'kids')}>
                                                        Kids
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'snack')}>
                                                        Snacks
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'pista')}>
                                                        Pista de hielo
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'mayores')}>
                                                        Atracciones mayores
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                            </li>
                            <li ><div onClick={this.cursecc.bind(this, 'boliche')}>Boliche</div></li>
                            <li>
                                <div>
                                    <div> <div onClick={this.toggle2.bind()} style={{ cursor: "pointer", color: "white" }}>Fiestas</div></div>
                                    <Collapse isOpen={this.state.collapse2}>
                                        <div className="movilsubmenuitems" style={{ color: "white" }}>
                                            <ul>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'fiestas')}>
                                                        Fiestas
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'even_emp')}>
                                                        Eventos Empresariales
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'even_soc')}>
                                                        Eventos Sociales
                                                    </div>
                                                </li>
                                                <li>
                                                    <div onClick={this.cursecc.bind(this, 'even_bol')}>
                                                        Torneo de Boliche
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </Collapse>
                                </div>
                            </li>
                            <li><div onClick={this.cursecc.bind(this, 'canje')}>Canje</div></li>
                            <li><a href="#sucursales" onClick={this.cursecc.bind(this, 'main')}>Sucursales</a></li>
                            <li>
                               <div onClick={this.cursecc.bind(this, 'clubTato')} style={{ position: 'relative'}}>
                                    <img src={this.state.servurlassets + 'logoclub.png'}  alt="Alternate Text"/>
                                </div>
                            </li>
                            <li>
                                <div onClick={this.abreCarrito.bind(this)} style={{ position: 'relative' }}>
                                    <div className="movilnav-container__cart-notification">
                                        <span id="movilshop-badge">{this.state.carritoLs.length}</span>
                                    </div>
                                    <img src={this.state.servurlassets + 'CarritoW.png'} width={"54px"} alt="Alternate Text"/>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                <br /><br />
                {this.state.titulo !== "" ?
                    <div style={{ backgroundColor: "white" }} className="movilfont-pop"><h1>{this.state.titulo}</h1></div> : ""
                }
                <div className="movilmenu-bg" id="movilmenu-bg"></div>
                <CSSTransition in={this.state.showcarrito} timeout={300} classNames="movilexample" unmountOnExit>
                    <ModalCarrito cursecc={this.cursecc} remplazarToken={this.remplazarToken} token={this.state.token} total={this.state.total} eliminaItem={this.eliminaItem} carritoLs={this.state.carritoLs} cierraCarrito={this.cierraCarrito} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                </CSSTransition>
                <div>
                    {
                        this.state.cursecc === 'main' ?
                        <Secc_0 display={this.state.display} servurlbase={this.state.servurlbase} cursecc={this.cursecc} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'recargas' ?
                        <Secc_recargas cambiaSeccion={this.cambiaSeccion} getSesionCtato={this.getSesionCtato} costotarjeta={this.state.costotarjeta} cards={this.state.cards} display={this.state.display} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'paquetes' ?
                        <Secc_paquetes getSesionCtato={this.getSesionCtato} codigo={this.state.codigo} cards={this.state.cards} display={this.state.display} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'boliche' ?
                        <Secc_Boliche getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'promociones' ?
                        <Secc_Promociones display={this.state.display} getCarrito={this.getCarrito} datos={this.state.datos} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'fiestas' ?
                        <Secc_Fiestas display={this.state.display} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'regalo' ?
                        <Secc_Tarjeta display={this.state.display} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'games' ?
                        <Secc_Games cursecc={this.cursecc} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'pabellones' ?
                        <Secc_Pabellones getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'kids' ?
                        <Secc_Kids cursecc={this.cursecc} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'snack' ?
                        <Secc_Snack getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'clasesPH' ?
                        <Secc_clases_PH getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'pista' ?
                        <Secc_Pista getCarrito={this.getCarrito} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'mayores' ?
                        <Secc_Mayores cursecc={this.cursecc} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'even_soc' ?
                        <Secc_Sociales getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'graduaciones' ?
                        <Secc_Graduaciones getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'even_emp' ?
                        <Secc_Empresariales getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'even_bol' ?
                        <Secc_Eve_Boliche cursecc={this.cursecc} getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'canje' ?
                        <Secc_Canje getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'nosotros' ?
                        <Secc_Nosotros getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'facturas' ?
                        <Secc_Facturas getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'bolsa' ?
                        <Secc_Bolsa getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'contacto' ?
                        <Secc_Contacto getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'aviso' ?
                        <Secc_Aviso getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'preguntas' ?
                        <Secc_Preguntas getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'terminos' ?
                        <Secc_Terminos getCarrito={this.getCarrito} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'clubTato' ?
                        <Secc_ClubTato       token={this.state.token} cursecc={this.cursecc} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'iniciar_club' ?
                        <Secc_iniciar_club   token={this.state.token} cursecc={this.cursecc} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} getSesionCtato={this.getSesionCtato} sesionCTato={this.state.sesionCTato}/>
                        : this.state.cursecc === 'registrar_club' ?
                        <Secc_registrar_club token={this.state.token} cursecc={this.cursecc} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} tokencambiaSeccion={this.cambiaSeccion}/>
                        : this.state.cursecc === 'recupera_club' ?
                        <Secc_recupera_club  token={this.state.token} cursecc={this.cursecc} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'sesion_club' ?
                        <Secc_sesion_club    token={this.state.token} cursecc={this.cursecc} servurlbase={this.state.servurlbase} cambiaSeccion={this.cambiaSeccion} servurlassets={this.state.servurlassets} cliente={this.state.codigo} getCarrito={this.getCarrito}/>
                        :this.state.cursecc === 'recuperaContra' ?
                        <Secc_nuevaC_club cambiaSeccion={this.cursecc} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        : this.state.cursecc === 'validado' ?
                        <Secc_validado cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} id_valida={this.state.id_valida}/>
                        : this.state.cursecc === 'registro_completo' ?
                        <Secc_registro cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'comunidad' ?
                        <Secc_comunidad objetofilterid={this.state.objetofilterid} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'reembolsos' ?
                        <Secc_reembolsos objetofilterid={this.state.objetofilterid} token={this.state.token} servurlbase={this.state.servurlbase} servurlassets={this.state.servurlassets} />
                        :this.state.cursecc === 'compraWeb' ?
                        <Secc_compraWeb remplazarToken={this.remplazarToken} tokenCompra={this.state.tokenCompra} cambiaSeccion={this.cambiaSeccion} token={this.state.token} servurlbase={this.state.servurlbase}  servurlassets={this.state.servurlassets} />
                        :''
                    }
                </div>
                <div style={{ paddingTop: "2%", paddingLeft: "2%", backgroundColor: 'white', position: "absolute", width: "100%" }}>
                    <div>
                        <Row>
                            <Col width="auto">
                                <a href="https://www.facebook.com/RECORCHOLISoficial/" className="movila-link on-pointer" target="_blank">
                                    <img src={this.state.servurlassets + 'mediaicon1.png'} alt="Alternate Text" />
                                </a>
                            </Col>
                            <Col width="auto">
                                <a href="https://twitter.com/recorcholis" className="movila-link  on-pointer" target="_blank">
                                    <img src={this.state.servurlassets + 'mediaicon2.png'} alt="Alternate Text" />
                                </a>
                            </Col>
                            <Col width="auto">
                                <a href="https://www.instagram.com/recorcholisoficial/" className="movila-link  on-pointer" target="_blank">
                                    <img src={this.state.servurlassets + 'mediaicon3.png'} alt="Alternate Text" />
                                </a>
                            </Col>
                            <Col width="auto">
                                <a href="https://www.youtube.com/user/RecorcholisOficial" className="movila-link  on-pointer" target="_blank">
                                    <img src={this.state.servurlassets + 'mediaicon4.png'} alt="Alternate Text" />
                                </a>
                            </Col>
                            <Col width="auto">
                                <a href="https://www.linkedin.com/company/recorcholis-grupo-diniz" className="movila-link on-pointer" target="_blank">
                                    <img src={this.state.servurlassets + 'mediaicon5.png'} alt="Alternate Text" />
                                </a>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "2%", paddingLeft: "2%", backgroundColor: 'black' }}>
                            <Col sm={6} xs={6}><h1>Información</h1></Col>
                            <Col sm={6} xs={6}><div onClick={this.toggle3.bind()}><h1>+</h1></div></Col>
                        </Row>
                        <Collapse isOpen={this.state.collapse3}>
                            <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "3%", paddingLeft: "2%", backgroundColor: 'black' }}>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "nosotros")}><h5>Nostros</h5></Col>
                                <Col sm={6} xs={6} onClick={() => window.open('https://diniz.com.mx/facturacion_recrefam/', '_blank')}><h5>Facturas</h5></Col>
                            </Row>
                            <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "3%", paddingLeft: "2%", backgroundColor: 'black' }}>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "bolsa")}><h5>Bolsa de trabajo</h5></Col>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "comunidad")}><h5>En la Comunidad</h5></Col>
                            </Row>
                            <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "3%", paddingLeft: "2%", backgroundColor: 'black' }}>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "contacto")}><h5>Contacto</h5></Col>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "aviso")}><h5>Aviso de Privacidad</h5></Col>
                            </Row>
                            <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "3%", paddingLeft: "2%", backgroundColor: 'black' }}>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "preguntas")}><h5>Preguntas</h5></Col>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "terminos")}><h5>Terminos y condiciones</h5></Col>
                            </Row>
                            <Row style={{ paddingTop: "5%", color: "white", paddingBottom: "3%", paddingLeft: "2%", backgroundColor: 'black' }}>
                                <Col sm={6} xs={6} onClick={this.cursecc.bind(this, "reembolsos")}><h5>Reembolsos y Devoluciones</h5></Col>
                            </Row>
                        </Collapse>
                        <Row style={{ paddingTop: "2%", color: "white", paddingLeft: "2%", backgroundColor: 'black' }}>
                            <Col sm={6} xs={6}><img src='https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/telephonenumber.png' alt="logo" /></Col>
                            <Col sm={6} xs={6}><img src='https://recorcholisstorage.blob.core.windows.net/recorcholispublic/recwebsite/n26.png' alt="logo" /></Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }

}
export default Main