import React, { Component } from 'react';
import YouTube from 'react-youtube';
import {  Row, Col, Button} from 'reactstrap';

class Secc_Atrac_AM extends Component{
    constructor() {
        super();
        this.state ={
            objetosLs:[]
        };
    }

    render(){
        const opts = {
            height: '100%',
            width: '100%',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div className="movilatractionsM">  
                    <div className="movilroyalM">
                        <h2 class="font-pop"></h2>
                        <Row style={{paddingTop:'5%', width:'90%'}}>
                            <Col sm={4} >
                                <div style={{textAlign:'left'}}>
                                    <div className="moviltitleam">
                                        <h1>Atracciones mayores</h1>
                                    </div>
                                    <div className="movilsubtitleam">
                                        <h4>
                                        Contamos con atracciones mecánicas para una diversión más extrema.
                                        </h4>
                                    </div>
                                    <br/>
                                    <div className="moviltextam">
                                        <p className="movilhide-at-sm movilhide-at-xs">
                                            Porque ¡Recórcholis! piensa en la diversión en grande, creó atracciones mayores,
                                            un área donde encontrarás; Carruseles, Sillas voladoras, Lanchas choconas, Ruedas de la fortuna,
                                            Convoy, Go-karts, Mini golf y muchas atracciones más, en las que podrás disfrutar la diversión al máximo. 
                                            Sólo se encuentran disponibles en algunas sucursales, consulta nuestras ubicaciones.
                                        </p>
                                    </div>
                                </div>
                                <br/>
                                <Button onClick={this.props.cursecc.bind(this,"recargas")} class="myButton">Comprar ahora</Button>
                            </Col>
                            <Col sm={8} style={{paddingTop:'5%',textAlign:'center'}}>
                                <div style={{position:'relative'}}>
                                    <YouTube videoId="CEyeIZoCXHA" opts={opts} onReady={this._onReady} />  
                                </div> 
                            </Col>
                            <Col sm={8}>
                                <div style={{ justifyContent:'center' ,textAlign:'center'}}>
                                    <div>
                                        <img  style={{height:350}} src={this.props.servurlassets+"am1.png"} alt='val'/>
                                        <img  style={{height:350}} src={this.props.servurlassets+"am2.png"} alt='val'/>
                                    </div>
                                </div> 
                            </Col>
                        </Row>
                    </div>
                </div>   
            </div>
        )
    }
}
export default Secc_Atrac_AM