import React, { Component } from 'react';
import Secc_1 from './secc_1';
import Secc_2 from './secc_2';
import Secc_3 from './secc_3';
import Secc_4 from './secc_4';
import Secc_5 from './secc_5';

class Secc_0 extends Component{
    render(){
        return(
            <div> 
                <Secc_1 cambiaSeccion={this.props.cambiaSeccion} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_2 cambiaSeccion={this.props.cambiaSeccion} pvDim={this.props.pvDim} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_3 cambiaSeccion={this.props.cambiaSeccion} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_4 cambiaSeccion={this.props.cambiaSeccion} servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
                <Secc_5 servurlbase={this.props.servurlbase} servurlassets={this.props.servurlassets} />
            </div>
                
        )
    }
}
export default Secc_0