import React, { Component } from 'react';
import './desktop.css';
import NumberFormat from 'react-number-format';
import { Input, Label, Button, FormGroup} from 'reactstrap';




class Secc_clases extends Component{
     
     
    render(){
        return(
            <div><br/><br/><br/><br/>
                <img  src={this.props.servurlassets+"PH_Clases.png"}/>
            </div>
        )
    }

}
export default Secc_clases