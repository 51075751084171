import React, { Component } from 'react';
import './desktop.css';
import YouTube from 'react-youtube';
import {  Row, Col} from 'reactstrap';


class Secc_Atrac_Boliche extends Component{

    compra = ()=>{
        this.props.cambiaSeccion('promociones','','1');
    }
  
    render(){
        const opts = {
            height: '390',
            width: '640',
            playerVars: {
              autoplay: 0,
            }
        }
        return(
            <div>
                <div  className="atracboliche">  
                   <div className="bol_royalg">
                          <Row style={{ width:'100%'}}>
                              <Col sm={4} >
                                  <div style={{textAlign:'left'}}>
                                    <div className="bol_titlesa">
                                        <h1>La bolera más grande de México.</h1>
                                    </div>
                                    <br/>
                                    <div className="bol_textsa">
                                        <p>
                                        Esta área cuenta con líneas de boliche profesional donde podrán divertirse personas de todas las edades. En esta área se pueden realizar cumpleaños, fiestas con diferentes conceptos, torneos y eventos empresariales, así como dinámicas de integración de personal.
                                        <br/><br/>
La venta de la línea es por persona e incluye bolos, calzado de boliche y 10 tiros. Si ya cuentas con tu equipo hay lockers disponibles para guardar tus pertenencias.
                                        </p>
                                    </div>
                                    </div>
                                    <br/>
                              </Col>        
                                <Col sm={8} style={{paddingTop:20,textAlign:'center'}}>
                                    <div style={{position:'relative'}}>
                                        <YouTube videoId="rw3syy0g9OE" opts={opts} onReady={this._onReady} />  
                                    </div> 
                                </Col>
                          </Row>
                    </div>
                </div>   
            </div>
        )
    }
}
export default Secc_Atrac_Boliche