import React, { Component } from 'react';
import './desktop.css';
import { Card} from 'reactstrap';
import classNames from "classnames";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

class Secc_1 extends Component{
    constructor() {
        super();

        this.state ={
            objetosLs:[]
        };
    }
  
    getObjetos = ()=>{
        let data ={
            carruselid : 1,
            objetofilterid:''
        }
        const requestInfo={
            method:'POST',
            body:JSON.stringify(data),
            header: new Headers({
                'Content-Type': 'application/json',
                'Accept' : 'application/json'
            })
        };
        fetch(this.props.servurlbase+'getObjetos.php', requestInfo)
        .then(response => response.json())
        .then(objetosLs => {
            if(objetosLs !==null){
                this.setState({ objetosLs });
            }else{
                this.setState({ objetosLs:[] });
            } 
        })
        .catch(e=>console.log(e)); 
    }

    nada =()=>{
            
    }
    
    componentDidMount(){
        this.getObjetos();
    } 
     
     
    render(){
        
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            tablet: {
              breakpoint: { max: 1023, min: 464 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              items: 1,
              slidesToSlide: 1 // optional, default to 1.
            }
        };

        const CustomDot = ({
        index,
        onClick,
        active
        }) => {
        return (
            <button style={{margin:4}}
            onClick={e => {
                onClick();
                e.preventDefault();
            }}
            className={classNames("swiper-pagination-bullet", {
                "swiper-pagination-bullet-active": active
            })}
            >
            <span>{index+1}</span>
            </button>
        );
        };

        

        return(
            <div>
                <div  className={"section-1"}>  
                    <div className="two-tone-background" >
                        <div className="tone-1" />
                        <div className="tone-2" />
                    </div>
                    {/* Inicio del contenido de la seccion border:'solid 5px green',border:'solid 3px red' */}
                    <div style={{  height:'100%', textAlign:'center', alignItems:'center', justifyContent:'center', display:'flex'}}>
                        <div style={{width:'90%'}}>
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={true}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={this.props.deviceType !== "mobile"? true : false}
                                autoPlaySpeed={4000}
                                keyBoardControl={true}
                                customTransition="all 1s linear"
                                transitionDuration={500}
                                containerClass="carousel-with-custom-dots"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                renderDotsOutside={false}
                                arrows={true}
                                deviceType={this.props.deviceType}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                                customDot={<CustomDot />}
                                >
                                {
                                    this.state.objetosLs.length>0? this.state.objetosLs.map((objetos,index) =>(   
                                        <Card  key={index}  style={{backgroundColor:'transparent',margin:0, border:'none'}}>
                                            {<div >
                                                <img onClick={objetos.url_click!==null?this.props.cambiaSeccion.bind(this,objetos.url_click,objetos.codigo,''):this.nada()} width='80%' src={this.props.servurlassets+objetos.nombre+objetos.extension} style={{cursor:objetos.url_click!==null&&objetos.url_click!==""?'pointer':'none'}} alt={'img'+objetos.index}/>
                                            </div>} 
                                        </Card>
                                    )):[]
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>   
            </div>
        )
    }

}
export default Secc_1